import { PlusOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';

import Modal from '../../uiKitComponents/modal';
import Upload from '../../uiKitComponents/upload';
import api from '../../utils/appApi';
import getApiErrorMessages from '../../utils/getApiErrorMessages';
import CardinalDirectionIcon from '../CardinalDirectionIcon';
import PhotoUploadItem from './components/item';

export const UPLOAD_ACTION_REMOVE = 'upload-action-remove';
export const UPLOAD_ACTION_REPORT = 'upload-action-report';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
const uploadButton = (
  <div>
    <PlusOutlined />
    <div style={{ marginTop: 8 }}>Add More Photos</div>
  </div>
);

const PhotoUpload = (props) => {
  const {
    onRemove,
    onChange,
    fileList,
    setFileList,
    uploadLimit,
    ...rest
  } = props;

  const { uploads } = useSelector((state) => ({
    uploads: state.padQuickAccess.payload.pad.uploads,
  }));

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [direction, setDirection] = useState('');

  const filePreviewHandler = useCallback(
    (file) => async () => {
      setDirection(uploads?.find((item) => item.id === file.id)?.direction);
      const preview = file;

      if (!preview.url && !preview.preview) {
        preview.preview = await getBase64(file.originFileObj);
      }

      setPreviewImage(preview.url || preview.preview);
      setPreviewTitle(
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
      );
      setPreviewVisible(true);
    },
    [uploads],
  );

  const fileRemoveHandler = useCallback(
    (file) => () =>
      onRemove
        ? onRemove(file)
        : onChange({
            fileList: fileList.filter((itm) => itm.uid !== file.uid),
          }),
    [onRemove, onChange, fileList],
  );

  const fileReportHandler = useCallback(
    (file) => async () => {
      try {
        await api.upload.report(file.uid.replace('-', ''));
        notification.success({
          message: 'Success',
          description: 'File has been reported',
        });
        onChange({
          fileList: fileList.filter((itm) => itm.uid !== file.uid),
        });
      } catch (error) {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(error),
        });
      }
    },
    [fileList, onChange],
  );

  const moveFileHandler = useCallback(
    (dragIdx, hoverIdx) => {
      const dragFile = fileList[dragIdx];
      setFileList(
        update(fileList, {
          $splice: [
            [dragIdx, 1],
            [hoverIdx, 0, dragFile],
          ],
        }),
      );
    },
    [fileList, setFileList],
  );

  const handleFileRender = useCallback(
    (_, file) => (
      <PhotoUploadItem
        file={file}
        previewFile={filePreviewHandler}
        removeFile={fileRemoveHandler}
        moveFile={moveFileHandler}
        fileList={fileList}
        actionType={file?.actionType || UPLOAD_ACTION_REMOVE}
        reportFile={fileReportHandler}
      />
    ),
    [
      fileList,
      filePreviewHandler,
      fileRemoveHandler,
      moveFileHandler,
      fileReportHandler,
    ],
  );

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Upload
          accept="image/png, image/jpeg"
          {...rest}
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onPreview={filePreviewHandler}
          itemRender={handleFileRender}
          multiple={false}
        >
          {uploadLimit > fileList.length && uploadButton}
        </Upload>
      </DndProvider>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
        className='direction-relative'
      >
        <div className='image'>
          <img alt={previewTitle} style={{ width: '100%' }} src={previewImage} />
          {direction && (
              <CardinalDirectionIcon
                direction={direction}
                width={45}
              />
          )}
        </div>
      </Modal>
    </>
  );
};

PhotoUpload.defaultProps = {
  onRemove: undefined,
  onChange: () => null,
  fileList: [],
  setFileList: () => null,
  uploadLimit: Infinity,
};

PhotoUpload.propTypes = {
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
  fileList: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string, // Must be negative, ex: '-1'
      name: PropTypes.string,
      url: PropTypes.string,
      thumbUrl: PropTypes.string,
      status: PropTypes.string,
      originFileObj: PropTypes.object,
    }),
  ),
  setFileList: PropTypes.func,
  uploadLimit: PropTypes.number,
};

export default PhotoUpload;
