const Footer = () => (
  <footer className="footer-container">
    <div className="social-media-block d-flex align-items-center flex-column">
      <p>Stay Connected</p>
      <div className="d-flex">
        <a
          href="https://www.facebook.com/helipaddyapp/"
          rel="noopener noreferrer"
        >
          <i className="custom-icon-facebook" />
        </a>
        <a
          href="https://www.instagram.com/helipaddy/"
          rel="noopener noreferrer"
        >
          <i className="custom-icon-instagram" />
        </a>
      </div>
    </div>
    <div className="contact-block d-flex justify-content-around">
      <div className="text-center">
        <p>Copyright © 2021 Helipaddy Limited.</p>
        <p>Company Number 08866916</p>
        <div className="mt-md-3">
          <a
            href="https://helipaddy.com/terms-and-conditions/"
            rel="noopener noreferrer"
            className="mr-4"
          >
            Terms of Use
          </a>
        </div>
      </div>
      <div className="text-center">
        <p>Helipaddy, The POD Building, Bridges Court</p>
        <p>Road, Battersea, London,</p>
        <div className="mt-md-3">
          <span>
            SW11 3BE <a href="tel:+44 20 3637 7595">+44 20 3637 7595</a> /{' '}
            <a href="mailto:support@helipaddy.com">support@helipaddy.com</a>
          </span>
        </div>
      </div>
      <div className="text-center">
        <p>Copyright © 2021 Helipaddy Limited.</p>
        <div className="mt-md-3">
          <a href="https://helipaddy.com/api/" rel="noopener noreferrer">
            Helipaddy API access
          </a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
