import { Button, Checkbox, Divider, Form, notification } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMount } from 'react-use';

import { updateLocalUser } from '../../../../store/actions/userAction';
import api from '../../../../utils/appApi';
import getApiErrorMessages from '../../../../utils/getApiErrorMessages';
import RegistrationFlowContext from '../../RegistrationFlow.context';

export function TermsStep() {
  const { user } = useSelector((state) => ({
    user: state?.user?.payload,
  }));
  const {
    redirectToStep,
    updateFlowActiveIndex,
    registrationFlowState,
    updateRegistrationFlowState,
  } = React.useContext(RegistrationFlowContext);
  const [loading, setLoading] = React.useState(false);
  const [formSubmitEnabled, setFormSubmitEnabled] = React.useState(
    registrationFlowState?.is_tac_accepted,
  );
  const dispatch = useDispatch();
  const history = useHistory();

  useMount(() => {
    if (user.id) {
      api.user.storeRegistrationProgress(user.id, 3);
    }
    updateFlowActiveIndex('terms');
  });

  const onValuesChange = React.useCallback(
    (changedValues) => {
      if (changedValues.hasOwnProperty('is_tac_accepted')) {
        setFormSubmitEnabled(changedValues.is_tac_accepted);
      }
      updateRegistrationFlowState(changedValues);
    },
    [updateRegistrationFlowState],
  );

  const onSubmit = React.useCallback(
    async (values) => {
      setLoading(true);

      try {
        const response = await api.user.completeRegistration({
          ...values,
          email: user?.email,
          first_name: user?.first_name,
          last_name: user?.last_name,
          role: 3,
        });

        if (history.location.state?.id) {
          await api.pad.claim(history.location.state.id);
        }

        setLoading(false);
        dispatch(updateLocalUser(response.data));
        history.push('/my-sites');
      } catch (error) {
        setLoading(false);

        notification.error({
          message: 'Error',
          description: getApiErrorMessages(error),
        });
      }
    },
    [dispatch, history, user],
  );

  return (
    <div className="accept-terms">
      <Form
        onFinish={onSubmit}
        onValuesChange={onValuesChange}
        initialValues={{
          is_tac_accepted: registrationFlowState?.is_tac_accepted,
          is_newsletter_subscribed:
            registrationFlowState?.is_newsletter_subscribed,
        }}
      >
        <div className="ant-checkbox-group">
          <p>
            Helipaddy reserves the right to edit the general site and contact
            information and withhold distribution to pilots. Site owners may
            have a duty of care when providing landing facilities and you must
            take the appropriate responsibility for the operational information
            provided. All landings require prior permission from the owner, so
            it is the owner&apos;s responsibility to ensure that accurate
            contact details are provided. Helipaddy encourages considerate
            flying so please adjust approach information accordingly.
          </p>
          <Form.Item
            className="mb-0"
            name="is_tac_accepted"
            valuePropName="checked"
          >
            <Checkbox name="is_tac_accepted">
              I have read and agree to the Helipaddy Terms and Conditions
            </Checkbox>
          </Form.Item>
          <Form.Item
            className="mb-0"
            name="is_newsletter_subscribed"
            valuePropName="checked"
          >
            <Checkbox>
              Keep me updated with occasional emails including latest news and
              tips to improve exposure for my site.
            </Checkbox>
          </Form.Item>
        </div>
        <Divider />
        <Button.Group>
          <Button
            className="secondary-btn"
            onClick={() => redirectToStep('pad_details')}
          >
            Previous
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!formSubmitEnabled}
            loading={loading}
          >
            Finish
          </Button>
        </Button.Group>
      </Form>
    </div>
  );
}
