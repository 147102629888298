import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef,useState } from 'react';

import Button from '../../uiKitComponents/button';
import Carousel from '../../uiKitComponents/carousel';
import Modal from '../../uiKitComponents/modal';
import notification from '../../uiKitComponents/notification';
import Popconfirm from '../../uiKitComponents/popconfirm';
import api from '../../utils/appApi';
import getApiErrorMessages from '../../utils/getApiErrorMessages';

const SliderModal = (props) => {
  const { isModalVisible, slides, updatePadUploads, onCancelHandler, activeIndex } = props;
  const [currentSlide, setCurrentSlide] = useState(null);
  const [reportLoading, setReportLoading] = useState(false);
  const slider = useRef(null);

  useEffect(() => {
    if (isModalVisible && slider.current) {
      slider.current.goTo(activeIndex);
    }
  }, [isModalVisible, activeIndex]);

  useEffect(() => {
    if (slides.length) {
      setCurrentSlide(slides[activeIndex]);
    }
  }, [slides, activeIndex]);

  const transformSlidesToNodes = useCallback(() => {
    if (slides.length) {
      return slides.map((img) => (
        <div className="slider-slide direction-relative" key={img.id}>
          <div className="image">
            <img src={img.file_url} alt={img.title} />
          </div>
        </div>
      ));
    }

    return null;
  }, [slides]);

  const reportImageHandler = () => {
    setReportLoading(true);

    api.upload
      .report(currentSlide?.id)
      .then(() => {
        if (currentSlide) {
          setCurrentSlide((prevState) => ({
            ...prevState,
            is_upload_reported: true,
          }));
          updatePadUploads(currentSlide);
        }
        setReportLoading(false);
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(err),
        });
        setReportLoading(false);
      });
  };

  const onSlideChange = (_, newIdx) => setCurrentSlide(slides[newIdx]);

  return (
    <Modal
      className="image-slider_modal"
      closeIcon={null}
      visible={isModalVisible}
      onCancel={onCancelHandler}
      footer={[
        <div className="image-slider_modal-meta" key="slider_modal_name">
          <span className="user-name">
            by {currentSlide?.user.first_name},{' '}
          </span>
          <span className="user-date">
            {dayjs(currentSlide?.user.created_at).format('DD MMMM, YYYY')}
          </span>
        </div>,
        <Popconfirm
          title="Are you sure to report this image?"
          okText="Confirm"
          key="slider_modal_report"
          disabled={currentSlide?.is_reported}
          onConfirm={reportImageHandler}
          cancelButtonProps={{ className: 'secondary-btn' }}
        >
          <Button
            type="text"
            className="auto-size-btn text-content-color d-flex align-items-center ml-auto mt-auto"
            loading={reportLoading}
            icon={
              currentSlide?.is_upload_reported ? (
                <i className="mr-2 custom-icon-flag-primary" />
              ) : (
                <i className="mr-2 custom-icon-flag-grey" />
              )
            }
          >
            {currentSlide?.is_upload_reported ? 'Reported' : 'Report'}
          </Button>
        </Popconfirm>,
      ]}
    >
      <Carousel
        arrows="true"
        beforeChange={onSlideChange}
        accessibility
        focusOnSelect
        ref={slider}
        initialSlide={activeIndex}
      >
        {transformSlidesToNodes()}
      </Carousel>
    </Modal>
  );
};

SliderModal.defaultProps = {
  isModalVisible: false,
  slides: [],
  updatePadUploads: () => null,
  onCancelHandler: () => null,
  activeIndex: 0,
};

SliderModal.propTypes = {
  isModalVisible: PropTypes.bool,
  slides: PropTypes.array,
  updatePadUploads: PropTypes.func,
  onCancelHandler: PropTypes.func,
  activeIndex: PropTypes.number,
};

export default SliderModal;
