export default {
  confirm: `This should contain useful landing information, specifically for helicopters and include any 'inside knowledge' about helicopter approaches and departures, in particular highlighting noise abatement procedures and any hazards like powerlines or big trees. \n \n Give directions like 'North' or 'South East of the main building' instead of 'left' or 'behind the main building' as everything is different from the air. We can help you with this, if needed.`,
  landing_explainer: (
    <span>
      Please share as much useful information as you can, for example: whether
      pilots should approach from a specific direction; is the landing zone
      confined at all; is there space for multiple helicopters; is there a
      slope; are theres wires, poles or tall trees etc. If you’re unsure, get in
      touch with{' '}
      <a href="mailto:support@helipaddy.com">support@helipaddy.com</a>!
    </span>
  ),
  landing_disclaimer: [
    `*Pilots will be shown the following Landing Instructions. Please
      confirm that these are accurate and appropriate to share with the
      community.`,
    `*Please provide our pilots with some detailed landing instructions`,
  ],
};
