import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useMount } from 'react-use';

import useStepUpdater from '../../hooks/useStepUpdater';
import { SurveyContext } from '../../Survey';
import { LANDING_STEP, MAP_STEP, PAD_VIEW_STEP } from '../../Survey.context';
import { SurveyStepActions } from '../SurveyStepActions';

export function SurveyLandingStep() {
  const { surveyData, changeSurveyStep } = useContext(SurveyContext);

  const [isOtherSelected, setIsOtherSelected] = useState(null);

  const [onStepValuesChange] = useStepUpdater(LANDING_STEP);

  const [form] = Form.useForm();

  const stepValuesChangeHandler = useCallback(
    (changedValues) => {
      const formErrorsCount = form
        .getFieldsError()
        .filter(({ errors }) => errors.length).length;

      if (formErrorsCount === 0) {
        onStepValuesChange(changedValues);
      }
    },
    [onStepValuesChange, form],
  );

  useMount(() => {
    if (!surveyData.landing?.landing_size_measurement) {
      onStepValuesChange({ landing_size_measurement: 1 });
    }
  });

  // Check if Exact Landing Area is other on first page load
  useEffect(() => {
    if (
      surveyData.landing?.landing_surface === 99 &&
      isOtherSelected === null
    ) {
      setIsOtherSelected(99);
    }
  }, [isOtherSelected, surveyData]);

  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={stepValuesChangeHandler}
      initialValues={{
        ...surveyData.landing,
        landing_size_measurement:
          surveyData.landing?.landing_size_measurement || 1,
      }}
    >
      <Row gutter={32}>
        <Col 
          span={12}
          md={12}
          sm={24}
          xs={24}
        >
          <Row gutter={32}>
            <Col 
              span={12}
              md={12}
              sm={24}
              xs={24}
              align="bottom"
            >
              <Form.Item
                className="nowrap-label"
                label="What is the diameter of the clearing surrounding the landing area?"
                name="landing_size"
                required
              >
                <InputNumber placeholder="Diameter" className='mb-5px'/>
              </Form.Item>
            </Col>
            <Col 
              span={12}
              md={12}
              sm={24}
              xs={24}
            >
              <Form.Item
                label="Size Unit"
                className="transparent-label"
                name="landing_size_measurement"
              >
                <Select>
                  <Select.Option value={1}>Meters</Select.Option>
                  <Select.Option value={2}>Feet</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col 
          span={12}
          md={12}
          sm={24}
          xs={24}
        >
          <Form.Item
            label="Size Details"
            name="landing_size_comment"
            className="transparent-label"
            rules={[
              {
                min: 3,
                max: 200,
                message: 'Must be between 3 and 200 characters',
              },
            ]}
          >
            <Input.TextArea
              className="survey-textarea max-100 scrollbar-thin"
              autoSize
              showCount
              maxLength={200}
              placeholder="Please give details"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col 
          span={12}
          md={12}
          sm={24}
          xs={24}
        >
          <Form.Item
            className="nowrap-label"
            label="What is the surface of the exact landing area?"
            name="landing_surface"
            required
          >
            <Select
              placeholder="Choose"
              options={[
                {
                  value: 1,
                  label: 'Concrete/Tarmac',
                },
                {
                  value: 2,
                  label: 'Grass - Maintained',
                },
                {
                  value: 3,
                  label: 'Grass - Partially maintained',
                },
                {
                  value: 4,
                  label: 'Grass - Unmaintained',
                },
                { value: 5, label: 'Gravel' },
                { value: 6, label: 'Sand' },
                { value: 99, label: 'Other' },
              ]}
              onChange={(value) => setIsOtherSelected(value)}
            />
          </Form.Item>
        </Col>
        <Col 
          span={12}
          md={12}
          sm={24}
          xs={24}
        >
          <Form.Item
            label="Surface Details"
            className={isOtherSelected !== 99 ? 'transparent-label' : ''}
            name="landing_surface_comment"
            required={isOtherSelected === 99}
            rules={[
              {
                min: 3,
                max: 200,
                message: 'Must be between 3 and 200 characters',
              },
            ]}
          >
            <Input.TextArea
              className="survey-textarea max-100 scrollbar-thin"
              autoSize
              showCount
              maxLength={200}
              placeholder="Please give details"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col 
          span={12}
          md={12}
          sm={24}
          xs={24}
        >
          <Form.Item
            className="nowrap-label"
            label="What slope is the landing area on?"
            name="landing_slope"
            required
          >
            <Select
              placeholder="Choose"
              options={[
                { value: 1, label: 'Flat' },
                { value: 2, label: 'Gentle' },
                { value: 3, label: 'Steep (3%)' },
                { value: 4, label: 'Extreme (>7%)' },
              ]}
            />
          </Form.Item>
        </Col>
        <Col 
          span={12}
          md={12}
          sm={24}
          xs={24}
        >
          <Form.Item
            name="landing_slope_comment"
            label="Details"
            className='transparent-label'
            rules={[
              {
                min: 3,
                max: 200,
                message: 'Must be between 3 and 200 characters',
              },
            ]}
          >
            <Input.TextArea
              className="survey-textarea min-max-100 scrollbar-thin"
              autoSize
              showCount
              maxLength={200}
              placeholder="Please provide more details of the above slope and it's direction of fall, along with details of any surrounding sloped ground"
            />
          </Form.Item>
        </Col>
      </Row>
      <SurveyStepActions
        backClick={changeSurveyStep(MAP_STEP)}
        skipClick={changeSurveyStep(PAD_VIEW_STEP)}
        nextClick={changeSurveyStep(PAD_VIEW_STEP)}
      />
    </Form>
  );
}
