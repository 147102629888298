import {
  DeleteOutlined,
  FlagOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';

import Button from '../../../uiKitComponents/button';
import Popconfirm from '../../../uiKitComponents/popconfirm';
import Spin from '../../../uiKitComponents/spin';
import upload from '../../../uiKitComponents/upload';
import CardinalDirectionIcon from '../../CardinalDirectionIcon';
import { UPLOAD_ACTION_REMOVE, UPLOAD_ACTION_REPORT } from '../photoUpload';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const type = 'PhotoUploadItem';

const PhotoUploadItem = (props) => {
  const {
    file,
    previewFile,
    removeFile,
    moveFile,
    fileList,
    actionType,
    displayImage,
    reportFile,
  } = props;
  const preview = file.thumbUrl || file.url;

  const { uploads } = useSelector((state) => ({
    uploads: state.padQuickAccess.payload.pad.uploads,
  }));

  const ref = useRef(null);
  const index = fileList.indexOf(file);
  const [{ handlerID }, drop] = useDrop({
    accept: type,
    collect(monitor) {
      return {
        handlerID: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIdx = item.index;
      const hoverIdx = index;

      if (dragIdx === hoverIdx) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIdx < hoverIdx && hoverClientY < hoverMiddleY) return;
      if (dragIdx > hoverIdx && hoverClientY > hoverMiddleY) return;

      moveFile(dragIdx, hoverIdx);

      // eslint-disable-next-line no-param-reassign
      item.index = hoverIdx;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type,
    item: () => ({ id: file.id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const draggingClassName = isDragging ? 'is-dragging' : '';
  drag(drop(ref));

  if (file?.error)
    return (
      <div className="picture-image-preview justify-content-start p-2">
        <div title={`${file?.error}`} className="picture-image-preview__error">
          {file.error}
        </div>
      </div>
    );

  if (!preview)
    return (
      <div className="picture-image-preview">
        <Spin indicator={antIcon} />
      </div>
    );

  if (displayImage) {
    const direction = uploads?.find((item) => item.id === file.id)?.direction;
    return (
      <div
        className={`picture-image-container ${draggingClassName}`}
        data-handler-id={handlerID}
        ref={ref}
      >
        <div className="picture-image-preview direction-relative-box">
          <div
            className="picture-image-preview__btn"
            aria-hidden="true"
            onClick={previewFile(file)}
          >
            <div className='image'>
              <img src={preview} alt={file.name} />
              {direction && (
                  <CardinalDirectionIcon
                    direction={direction}
                    width={30}
                  />
              )}
            </div>
          </div>
        </div>
        {actionType === UPLOAD_ACTION_REMOVE && (
          <Popconfirm
            title="Are you sure to delete this image?"
            onConfirm={removeFile(file)}
            okText="Confirm"
            cancelButtonProps={{ className: 'secondary-btn' }}
          >
            <Button htmlType="button" type="text" icon={<DeleteOutlined />}>
              Remove
            </Button>
          </Popconfirm>
        )}

        {actionType === UPLOAD_ACTION_REPORT && (
          <Popconfirm
            title="Are you sure to report this image?"
            onConfirm={reportFile(file)}
            okText="Confirm"
            cancelButtonProps={{ className: 'secondary-btn' }}
          >
            <Button htmlType="button" type="text" icon={<FlagOutlined />}>
              Report
            </Button>
          </Popconfirm>
        )}
      </div>
    );
  }

  return null;
};

PhotoUploadItem.defaultProps = {
  file: {},
  previewFile: () => null,
  removeFile: () => null,
  moveFile: () => null,
  fileList: [],
  displayImage: true,
};
PhotoUploadItem.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.number,
    thumbUrl: PropTypes.string,
    url: PropTypes.string,
    name: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  previewFile: PropTypes.func,
  removeFile: PropTypes.func,
  moveFile: PropTypes.func,
  fileList: PropTypes.array,
  actionType: PropTypes.string,
  displayImage: PropTypes.bool,
  reportFile: PropTypes.func,
};

export default PhotoUploadItem;
