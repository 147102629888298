import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'react-use';

import { createNewSiteEventOpts } from '../../events';
import useGaEvent from '../../hooks/useGAEvent';
import Input from '../../uiKitComponents/input';
import api from '../../utils/appApi';
import getPadIcon from '../../utils/getPadIcon';
import getTextWidth from '../../utils/getTextWidth';

const calculateTextWidth = getTextWidth();

const SearchPads = (props) => {
  const {
    placeholder,
    name,
    label,
    value,
    action,
    createPadAction,
    ...rest
  } = props;
  const [padsList, setPadsList] = useState({
    items: [],
    len: 0,
  });
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState(null);
  const [warning, setWarning] = useState(true);
  const [padNewName, setPadNewName] = useState({
    inList: false,
    default: false,
  });
  const [wrapperClassName, setWrapperClassName] = useState([
    'live-search_input-wrapper',
  ]);
  const [inputW, setInputW] = useState('100%');
  const [listScrolled, setListScrolled] = useState(false);
  const inputNode = useRef();

  // GA events
  const createNewSiteEvent = useGaEvent(createNewSiteEventOpts);

  useEffect(() => {
    if (value?.name) setWarning(false);
  }, [value]);

  useEffect(() => {
    const classes = ['live-search_input-wrapper'];

    if (listScrolled) classes.push('scrolling');
    if (padsList.len) classes.push('filled');

    setWrapperClassName(classes);
  }, [padsList, listScrolled]);

  const handleSelectPad = (pad) => {
    action(pad);
    setQuery(null);
    setPadsList({
      items: [],
      len: 0,
    });
    setListScrolled(false);
    setPadNewName({
      inList: false,
      default: false,
    });
    setInputW('100%');
  };

  const tranformDataToElements = (list) => {
    if (query?.length && list === undefined) {
      action(0);
      return (
        <Button disabled>
          This pad doesn’t already exist within Helipaddy - click&nbsp;“
          <strong>Add New Pad</strong>”&nbsp;below
        </Button>
      );
    }

    return (
      <div
        className="list"
        onScroll={(e) => {
          if (e.target.scrollTop) setListScrolled(true);
          else setListScrolled(false);
        }}
      >
        {list.map((pad) => (
          <Button
            key={pad.id}
            icon={getPadIcon(parseInt(pad?.category_id, 10), pad?.name)}
            onClick={() => handleSelectPad(pad)}
          >
            <span className="pad_name">{pad.name}</span>
            {!!pad.post_code && (
              <span className="pad_postcode">, {pad.post_code}</span>
            )}
            {!!pad.address && (
              <span className="pad_address">, {pad.address}</span>
            )}
          </Button>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (!query)
      setPadNewName((prevState) => ({
        ...prevState,
        default: false,
      }));
    else
      setPadNewName((prevState) => ({
        ...prevState,
        default: true,
      }));
  }, [query]);

  const generateInputW = (input) => {
    if (!input) setInputW('100%');
    else setInputW(calculateTextWidth(input, inputNode.current.input, 10));
  };

  const searchForPads = (input) => {
    if (!input || input.length < 3) return;

    setLoading(true);

    api.pad
      .search(input)
      .then((res) => {
        setLoading(false);
        if (res?.data?.length) {
          setPadsList({
            items: tranformDataToElements(res.data),
            len: res.data.length,
          });

          setPadNewName((prevState) => ({
            ...prevState,
            inList: res.data.some(
              (pad) => pad.name.toLowerCase() === input.toLowerCase(),
            ),
          }));
        } else {
          setPadsList({
            items: tranformDataToElements(undefined),
            len: 0,
          });
        }
      })
      .catch(() => setLoading(false));
  };

  useDebounce(() => searchForPads(query), 300, [query]);

  const handleOnSearch = useCallback(
    (input) => {
      generateInputW(input.target.value);
      setQuery(input.target.value);

      if (!input.target.value.length) {
        setPadsList({
          items: [],
          len: 0,
        });
        action(null);
      }
    },
    [action],
  );

  return (
    <div className="live-search_container">
      <label htmlFor={name}>{label}</label>
      <div
        className={wrapperClassName.join(' ')}
        onClick={() => inputNode.current.focus()}
        aria-hidden="true"
      >
        <div className="live-search_input">
          <span className="live-search_prefix">
            {(loading && <LoadingOutlined />) || <SearchOutlined />}
          </span>
          <Input
            {...rest}
            ref={inputNode}
            placeholder={placeholder}
            name={name}
            value={query !== null ? query : value?.name}
            onChange={handleOnSearch}
            onFocus={() => setWarning(false)}
            style={{
              width: inputW,
            }}
          />
          <div className="live-search_tag-container">
            {padNewName.default && !padNewName.inList && (
              <span className="live-search_tag primary-tag">New</span>
            )}
          </div>
        </div>
        <Button
          type="primary"
          className={`live-search_button ${padsList.len ? 'active' : ''}`}
          onClick={createNewSiteEvent(createPadAction)}
        >
          Add New Pad
        </Button>
      </div>
      {warning && (
        <span style={{ fontSize: 14 }}>
          <i>*at least 3 characters</i>
        </span>
      )}
      <div className="live-search_list">{padsList.items}</div>
    </div>
  );
};

SearchPads.defaultProps = {
  placeholder: '',
  name: '',
  label: '',
  value: {},
  action: () => null,
  createPadAction: () => null,
};

SearchPads.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  action: PropTypes.func,
  createPadAction: PropTypes.func,
};

export default SearchPads;
