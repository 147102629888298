import { Col, Form, Select } from 'antd';
import { useSelector } from 'react-redux';

import { selectLandingFeeOptions } from '../../store/selectors/bootstrapSelector';
import { formItemVerticalLayout } from './padForm';

export const PadFormLandingFee = () => {
  const { fees } = useSelector((store) => ({
    fees: selectLandingFeeOptions(store),
  }));

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prev, curr) =>
        prev.warnings !== curr.warnings || prev.facilities !== curr.facilities
      }
    >
      {({ getFieldValue }) => {
        let warningSelected = false;

        if (getFieldValue('category_id') === 7) {
          if (getFieldValue('facilities')?.includes(13)) {
            warningSelected = true;
          }
        }

        if (getFieldValue('warnings')?.includes(6)) {
          warningSelected = true;
        }

        return (
          warningSelected && (
            <Col span={24} md={10}>
              <Form.Item
                {...formItemVerticalLayout}
                label="Landing fees"
                name="landing_fees"
                rules={[{ required: true }]}
              >
                <Select options={fees} placeholder="Landing fees" />
              </Form.Item>
            </Col>
          )
        );
      }}
    </Form.Item>
  );
};
