import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom';

const SurveyQRRedirect = () => {

    const { surveyId, token } = useParams();
    const history = useHistory();

    localStorage.setItem(`token`, token);
    localStorage.setItem(`isAuthorized`, true);

    useEffect(() => {
        if (localStorage.getItem(`token`).length > 0 && localStorage.getItem(`isAuthorized`) === 'true') {
            history.push(`/survey-360/survey/${surveyId}`);
            localStorage.setItem(`active-step`, "view");
            window.location.reload();
        } else {
            history.push(`/login`);
            window.location.reload();
        }
    }, [history, surveyId, token]);

    return (null);
};

export default SurveyQRRedirect;
