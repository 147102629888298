import './RegistrationFlow.styles.scss';

import { Spin } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';

import CustomSteps from '../../components/steps/steps';
import {
  ChooseRoleStep,
  PadDetailsStep,
  PersonalDetailsStep,
  TermsStep,
} from './components';
import RegistrationFlowContext from './RegistrationFlow.context';
import {
  activateEmail,
  getRegistrationFlowIntialURL,
  steps,
} from './RegistrationFlow.utils';

export function RegistrationFlow() {
  const { user } = useSelector((state) => ({
    user: state?.user?.payload,
  }));
  const [registrationFlowState, setRegistrationFlowState] = useState({});
  const [loading, setLoading] = React.useState(true);
  const [isFlowInitialURLSet, setIsFlowInitialURLSet] = React.useState(false);
  const [activeStepIndex, setActiveStepIndex] = React.useState(0);
  const history = useHistory();

  React.useEffect(() => {
    const initialize = async () => {
      await activateEmail();

      if (user?.id) {
        if (!isFlowInitialURLSet) {
          history.push(getRegistrationFlowIntialURL(user));
          setIsFlowInitialURLSet(true);
        }
      } else if (localStorage.getItem('isAuthorized') !== 'true') {
        history.replace('/');
      }

      setLoading(false);
    };

    initialize();
  }, [history, isFlowInitialURLSet, user]);

  const redirectToStep = React.useCallback(
    (stepKey, state = {}) => {
      history.push(steps[stepKey].url, state);
    },
    [history],
  );

  const updateFlowActiveIndex = React.useCallback((stepKey) => {
    setActiveStepIndex(steps[stepKey].index);
  }, []);

  const updateRegistrationFlowState = React.useCallback(
    (state = {}) => setRegistrationFlowState((prev) => ({ ...prev, ...state })),
    [setRegistrationFlowState],
  );

  if (loading) {
    return (
      <div className="small-container">
        <div className="registration-flow">
          <h1>Create an account</h1>
          <div className="personal-details d-flex flex-column align-item-center">
            <div className="personal-details_inf mb-3 mt-5 pt-5 d-flex justify-content-center align-items-center">
              <Spin spinning />
            </div>
            <h4 className="text-center pb-5 mt-1">
              Loading user information...
            </h4>
          </div>
        </div>
      </div>
    );
  }

  return (
    <RegistrationFlowContext.Provider
      value={{
        redirectToStep,
        updateFlowActiveIndex,
        updateRegistrationFlowState,
        registrationFlowState,
      }}
    >
      <div className="small-container">
        <div className="registration-flow">
          <h1>Create an account</h1>
          <div className="registration-stepts">
            <CustomSteps
              currentStep={activeStepIndex}
              steps={[
                'Personal Details',
                'Choose Role',
                'Pad Details',
                'Terms & Conditions',
              ]}
            />
          </div>
          <div className="registration-flow__step-content">
            <Switch>
              <Route exact path="/registration-flow">
                <PersonalDetailsStep />
              </Route>
              <Route path="/registration-flow/role">
                <ChooseRoleStep />
              </Route>
              <Route path="/registration-flow/pad-details">
                <PadDetailsStep />
              </Route>
              <Route path="/registration-flow/terms">
                <TermsStep />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </RegistrationFlowContext.Provider>
  );
}
