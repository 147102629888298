export const UKLocation = { lat: 54.4747043, lng: -4.9561606 };

export const dateFormats = ['DD MMM YYYY HH:mm', 'DD MMM YYYY'];

export const imageFormats = ['jpg', 'jpeg', 'png'];

export const menuItemsRegister = {
  // Main pages
  '/my-sites': 'my-sites',
  '/my-account': 'my-account',

  // Sub pages
  '/my-sites/site': 'my-sites',
};

// export const PAD_BOTH_RESTRICTION = 'NOT_APPROVED_AND_CLAIMED';
export const PAD_IN_MODERATION = 'IN_MODERATION';

export const PAD_AWAITING_APPROVAL = 'AWAITING_APPROVAL';
export const PAD_AWAITING_MODERATION = 'AWAITING_MODERATION';
export const PAD_CHECK_DETAILS = 'CHECK_DETAILS';
export const PAD_LANDING_NOT_POSSIBLE = 'LANDING_NOT_POSSIBLE';
export const PAD_VERIFIED_AND_AVAILABLE = 'VERIFIED_AND_AVAILABLE';
export const PAD_PENDING_MODERATION = 'PENDING_MODERATION';

export const regex = {
  simplePhone: /[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*/,
  breakLine: /\r\n|\r|\n/g,
  // password: at least one uppercase letter, one lowercase letter and one number
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]*$/,
};

export const surveyStepsIndexes = new Map([
  [-1, ''],
  [0, 'general'],
  [1, 'map'],
  [2, 'landing'],
  [3, 'view'],
  [4, 'hazards'],
  [5, 'approach'],
  [6, 'facilities'],
]);

export const cardinalDirections = ['north', 'south', 'east', 'west'];
