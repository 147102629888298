import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import jsonToFormData from 'json-form-data';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useDebounce, useMount } from 'react-use';

import { ReactComponent as ExclamationIcon } from '../../assets/icons/exclamation.svg';
import { ReactComponent as QuestionIcon } from '../../assets/icons/question.svg';
import GoogleMapsContext from '../../context/googleMapsContext';
import useApiErrorsWithAntd from '../../hooks/useApiErrorsWithAntd';
import {
  loadingBarDisable,
  loadingBarEnable,
} from '../../store/actions/loadingBarAction';
import {
  selectCategoryOptions,
  selectFacilityOptions,
  selectTagOptions,
  selectWarningOptions,
} from '../../store/selectors/bootstrapSelector';
import Button from '../../uiKitComponents/button';
import Checkbox from '../../uiKitComponents/checkbox';
import Col from '../../uiKitComponents/col';
import Divider from '../../uiKitComponents/divider';
import Form from '../../uiKitComponents/form';
import Input from '../../uiKitComponents/input';
import Modal from '../../uiKitComponents/modal';
import notification from '../../uiKitComponents/notification';
import Popover from '../../uiKitComponents/popover';
import Row from '../../uiKitComponents/row';
import Select from '../../uiKitComponents/select';
import api from '../../utils/appApi';
import { cardinalDirections } from '../../utils/constants';
import convertDMS from '../../utils/convertDMS';
import fieldsErrors from '../../utils/fieldsErrors';
import getAddressComponentByName from '../../utils/getAddressComponentByName';
import getApiErrorMessages from '../../utils/getApiErrorMessages';
import HeaderL2 from '../header-l2';
import IcaoInput from '../icaoInput';
import MapWithSinglePad from '../mapWithSinglePad';
import NumericInput from '../numericInput';
import PhotoUpload from '../photoUpload';
import {
  UPLOAD_ACTION_REMOVE,
  UPLOAD_ACTION_REPORT,
} from '../photoUpload/photoUpload';
import SearchLocation from '../searchLocation';
import { PadFormLandingFee } from './PadFormLandingFee';
import PrimaryContact from './PrimaryContact';
import STATIC from './static';

const latValidationRules = [
  {
    validator: (itm, value) =>
      (!isNaN(value) && parseFloat(value) < 91) || isNaN(value) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.max(90))),
  },
  {
    validator: (itm, value) =>
      (!isNaN(value) && parseFloat(value) > -91) || isNaN(value) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.min(-90))),
  },
];

const lngValidationRules = [
  {
    validator: (itm, value) =>
      (!isNaN(value) && parseFloat(value) < 181) || isNaN(value) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.max(180))),
  },
  {
    validator: (itm, value) =>
      (!isNaN(value) && parseFloat(value) > -181) || isNaN(value) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.min(-180))),
  },
];

const twitterUsernameHandle = [
  {
    validator: (itm, value) =>
      (value && !value.includes('@')) || !value
        ? Promise.resolve()
        : Promise.reject(new Error(fieldsErrors.withoutAt)),
  },
];

let geocoderService;

const getGeoCodeByLatLng = (lat, lng) =>
  new Promise((resolve, reject) =>
    geocoderService.geocode(
      {
        location: { lat: parseFloat(lat), lng: parseFloat(lng) },
      },
      (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK)
          return resolve(results);
        return reject();
      },
    ),
  );

export const formItemVerticalLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const PadForm = (props) => {
  const {
    pad: immutablPad,
    previousAction,
    nextAction,
    menu,
    saveButtonText,
    cancelButtonText,
    onboarding,
    backPath,
  } = props;
  const {
    categoriesOptions,
    tags,
    warnings,
    facilities,
    loadingBarEnabled,
  } = useSelector((store) => ({
    tags: selectTagOptions(store),
    categoriesOptions: selectCategoryOptions(store),
    warnings: selectWarningOptions(store),
    facilities: selectFacilityOptions(store),
    loadingBarEnabled: store.loadingBar.enabled,
  }));
  const { pad_id } = useParams();
  const [map, setMap] = useState();
  const [location, setLocation] = useState({});
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [currentPadCategory, setCurrentPadCategory] = useState();
  const [pad, setPad] = useState(immutablPad);
  const { googleMapsIsLoaded } = useContext(GoogleMapsContext);
  const uploadsLength = pad.uploads?.length;
  const { setApiErrorsToAntdForm } = useApiErrorsWithAntd(form);
  const [isLandingAdviceChanged, setIsLandingAdviceChanged] = useState(false);

  const [isModalDisplayed, setIsModalDisplayed] = useState(false);
  const [isChangedOnMap, setIsChangedOnMap] = useState(false);
  const [oldLocation, setOldLocation] = useState(null);
  const [
    displayedWarningAddressSplit,
    setDisplayedWarningAddressSplit,
  ] = useState(false);
  const [locationOnChanged, setLocationOnChanged] = useState({});
  const [clickInModal, setClickInModal] = useState(null);
  const [isAddreesForFirstTime, setIsAddreesForFirstTime] = useState(true);

  useEffect(() => setPad(immutablPad), [immutablPad]);

  useEffect(() => {
    setCurrentPadCategory(pad?.category_id);
  }, [pad]);

  useEffect(() => {
    if (pad.location) {
      setLocation({
        ...pad.location,
        address: pad.location.name,
      });
    }
  }, [pad.location]);

  useEffect(() => {
    form.setFields([
      { name: 'location#latitude', value: location.latitude },
      { name: 'location#longitude', value: location.longitude },
    ]);
  }, [location.latitude, location.longitude, form]);

  useEffect(() => {
    if ((!isChangedOnMap || !pad_id) && isAddreesForFirstTime) {
      form.setFields([
        {
          name: 'location#name',
          value: location,
        },
      ]);
    }
    // eslint-disable-next-line
  }, [form, location, isChangedOnMap, pad_id]);

  useEffect(() => {
    if (uploadsLength) {
      setFileList(
        pad?.uploads.map((itm) => ({
          id: itm.id,
          uid: `-${itm.id}`,
          name: itm.title,
          url: itm.file_url,
          status: 'done',
          displayImage: !itm.is_upload_reported,
          actionType:
            itm?.user?.role === 2 ? UPLOAD_ACTION_REPORT : UPLOAD_ACTION_REMOVE,
        })),
      );
    }
  }, [form, uploadsLength, pad.uploads]);

  const uploadFileHandle = useCallback(
    (uploadConfig) => {
      if (pad?.id) {
        const { file, onError, onProgress, onSuccess } = uploadConfig;
        const formData = new FormData();
        formData.append('upload', file);

        api.upload
          .store('pad', pad.id, formData, {
            onUploadProgress: ({ total, loaded }) => {
              onProgress(
                { percent: Math.round((loaded / total) * 100).toFixed(2) },
                file,
              );
            },
          })
          .then((res) => {
            onSuccess('Success', { ...file, id: res.data.id });
          })
          .catch((err) => {
            onError();

            setFileList((prevFileList) =>
              prevFileList.filter((itm) => itm.uid !== file.uid),
            );

            notification.error({
              message: 'Error',
              description: getApiErrorMessages(err),
            });
          });
      }
    },
    [pad.id],
  );

  const picturesWallChangeHandle = useCallback((e) => {
    setFileList(e.fileList);
  }, []);

  const picturesWallRemoveHandle = useCallback((file) => {
    const deleteFromState = () =>
      setFileList((prev) => prev.filter((itm) => itm.uid !== file.uid));

    const fileId = file.id || file?.xhr.id;

    if (fileId)
      api.upload
        .delete(fileId)
        .then(() => deleteFromState())
        .catch((err) =>
          notification.error({
            message: 'Error',
            description: getApiErrorMessages(err),
          }),
        );
    else deleteFromState();
  }, []);

  const photosGetValueFromEvent = useCallback((e) => e.fileList, []);

  const photosBeforeUpload = useCallback(
    (...args) => (pad.id ? Promise.resolve(...args) : false),
    [pad.id],
  );

  const mapOnReadyHandle = useCallback((mapProps, _map) => setMap(_map), []);

  const newPadLocation = useMemo(
    () =>
      !isNaN(location.latitude) && !isNaN(location.longitude)
        ? {
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude),
            withoutZoom: !!location.withoutZoom,
          }
        : null,
    [location.latitude, location.longitude, location.withoutZoom],
  );

  const locationOnDragendHandle = useCallback(
    (data, marker) => {
      const latitude = marker.position.lat();
      const longitude = marker.position.lng();

      setIsChangedOnMap(true);
      // setIsModalDisplayed(true);

      setLocation((prevState) => ({
        ...prevState,
        latitude: parseFloat(latitude).toFixed(7),
        longitude: parseFloat(longitude).toFixed(7),
        getGeoCode: true,
        withoutZoom: true,
      }));

      if (
        oldLocation.latitude === latitude ||
        oldLocation.longitude === longitude
      ) {
        setDisplayedWarningAddressSplit(oldLocation?.is_address_split);
      } else {
        setDisplayedWarningAddressSplit(true);
      }

      form.setFields([
        { name: 'location#latitude', value: parseFloat(latitude).toFixed(7) },
        { name: 'location#longitude', value: parseFloat(longitude).toFixed(7) },
      ]);

      if (!pad_id && isAddreesForFirstTime) {
        form.setFields([{ name: 'location#name', value: location.address }]);
      }
    },
    [form, oldLocation, location, pad_id, isAddreesForFirstTime],
  );

  // Initialize Google Maps Geocoder service
  useEffect(() => {
    if (googleMapsIsLoaded) {
      // https://developers.google.com/maps/documentation/javascript/geocoding#place-id
      geocoderService = new window.google.maps.Geocoder();
    }
  }, [googleMapsIsLoaded]);

  // Getting geocode when lat and lng changes and getGeoCode: true
  useDebounce(
    () => {
      if (
        location.getGeoCode &&
        !isNaN(location.latitude) &&
        location.latitude > -91 &&
        location.latitude < 91 &&
        !isNaN(location.longitude) &&
        location.longitude > -181 &&
        location.longitude < 181
      )
        getGeoCodeByLatLng(location.latitude, location.longitude).then(
          (results) => {
            if (results.length) {
              const value = {
                address: results[0].formatted_address,
                latitude: location.latitude,
                longitude: location.longitude,
                country: getAddressComponentByName(
                  results[0].address_components,
                  'country',
                ).long_name,
                country_short: getAddressComponentByName(
                  results[0].address_components,
                  'country',
                ).short_name,
                county: getAddressComponentByName(
                  results[0].address_components,
                  'administrative_area_level_2',
                ).long_name,
              };
              setLocation({
                ...value,
                getGeoCode: false,
                withoutZoom: true,
              });

              if (!pad_id) {
                setIsAddreesForFirstTime(false);
                if (isAddreesForFirstTime)
                  setDisplayedWarningAddressSplit(false);
              }
            }
          },
        );
    },
    300,
    [location.latitude, location.longitude, location.getGeoCode],
  );

  const editOneOfLocationCoord = (key, value, getGeoCode = true) =>
    setLocation((prevState) => ({
      ...prevState,
      [key]: value,
      getGeoCode,
    }));

  const debouncedLocationInput = debounce((key, value, limit) => {
    if (value >= -limit && value <= limit) editOneOfLocationCoord(key, value);
  }, 300);

  const onFormChange = useCallback(
    (changedValue) => {
      const {
        'location#name': locationName,
        'location#latitude': latitude,
        'location#longitude': longitude,
      } = changedValue;
      if (locationName) {
        setLocationOnChanged((prev) => ({
          ...prev,
          ...locationName,
        }));
        // form.setFields([
        //   { name: 'location#latitude', value: locationName.latitude },
        //   { name: 'location#longitude', value: locationName.longitude },
        // ]);
      }
      if (latitude) debouncedLocationInput('latitude', latitude, 90);
      if (longitude) debouncedLocationInput('longitude', longitude, 180);
    },
    [debouncedLocationInput],
  );

  const getCheckboxes = (arr) =>
    arr?.map((item) => (
      <Checkbox value={item.value} key={item.value}>
        {item.label}
      </Checkbox>
    ));

  const getSelectedCheckboxes = (arr, relation) =>
    pad[arr]?.map((item) => item[relation]);

  // PAD UPLOADS ORDER
  const setOrderedUploads = useCallback(() => {
    if (pad?.id) {
      const orderedUploads = fileList.map((file, index) => ({
        id: file.id || file?.xhr?.id,
        order: index + 1,
      }));

      api.pad.uploadsOrder({ upload: orderedUploads }).catch((err) =>
        notification.error({
          message: 'Uploads Order Error!',
          description: getApiErrorMessages(err),
        }),
      );
    }
  }, [fileList, pad]);

  // FORM SUBMIT HANDLER
  const padSubmitHandler = useCallback(
    (values) => {
      const data = {
        ...values,
        'location#name': values['location#name'].address,
        'location#country': values['location#name'].country_short,
        'location#county': values['location#name'].county,
        'location#is_address_split': displayedWarningAddressSplit,
        is_private: false,
        uploads: values?.uploads?.map((itm) => itm.originFileObj),
      };

      if (values['pad_social#trip_advisor_rating']) {
        data[
          'pad_social#trip_advisor_rating'
        ] = `${values['pad_social#trip_advisor_rating']}`;
      }
      if (values['pad_social#twitter_handle']) {
        data[
          'pad_social#twitter_handle'
        ] = `@${values['pad_social#twitter_handle']}`;
      }

      if (clickInModal === 'yes') {
        data['location#name'] = locationOnChanged.address;
        data['location#latitude'] = locationOnChanged.latitude;
        data['location#longitude'] = locationOnChanged.longitude;
        data['location#country'] = locationOnChanged.country_short;
        data['location#county'] = locationOnChanged.county;
      }

      data.contacts = [
        {
          ...values.primary_contact,
          is_primary: true,
          contact_type_id: values.primary_contact.contact_type_id || 1,
        },
      ];

      if (onboarding) {
        data.is_onboarding_update = true;
      }

      const fd = jsonToFormData(data);

      loadingBarEnable()(dispatch);

      if (pad?.id) {
        if (
          form.isFieldsTouched() ||
          isLandingAdviceChanged ||
          isChangedOnMap
        ) {
          api.pad
            .update(pad?.id, fd)
            .then((res) => {
              loadingBarDisable()(dispatch);
              form.resetFields();
              setLocation({});
              setIsLandingAdviceChanged(false);
              map.panTo({ lat: 54.4747043, lng: -4.9561606 });
              map.setZoom(5);
              nextAction(pad?.id, res.data?.data);
            })
            .catch((err) => {
              loadingBarDisable()(dispatch);
              setApiErrorsToAntdForm(err);
              notification.error({
                message: 'Error',
                description: getApiErrorMessages(err),
              });
            })
            .finally(() => {
              setClickInModal(null);
            });
        } else {
          api.pad
            .displayLandingAdvice(pad?.id, true)
            .then((res) => {
              nextAction(pad?.id, null, false);
            })
            .catch((err) => {
              setApiErrorsToAntdForm(err);
              notification.error({
                message: 'Error',
                description: getApiErrorMessages(err),
              });
            })
            .finally(() => {
              loadingBarDisable()(dispatch);
              setClickInModal(null);
            });
        }
      } else {
        api.pad
          .store(fd)
          .then((res) => {
            loadingBarDisable()(dispatch);
            form.resetFields();
            setLocation({});
            map.panTo({ lat: 54.4747043, lng: -4.9561606 });
            map.setZoom(5);
            nextAction(res?.data?.data);
          })
          .catch((err) => {
            loadingBarDisable()(dispatch);
            setApiErrorsToAntdForm(err);
            notification.error({
              message: 'Error',
              description: getApiErrorMessages(err),
            });
          })
          .finally(() => {
            setClickInModal(null);
          });
      }
    },
    [
      dispatch,
      form,
      map,
      nextAction,
      onboarding,
      pad,
      setApiErrorsToAntdForm,
      isLandingAdviceChanged,
      displayedWarningAddressSplit,
      isChangedOnMap,
      locationOnChanged,
      clickInModal,
    ],
  );

  const confirmPadSubmit = useCallback(async () => {
    try {
      await form.validateFields();

      const landingAdvice = form.getFieldValue('landing_advice');

      const changeLandingAdviceHandler = (e) => {
        form.setFields([{ name: 'landing_advice', value: e.target.value }]);
        setIsLandingAdviceChanged(true);
      };

      const modalContent = () => (
        <div className="pad-confirm">
          <h3 className="mb-3 pad-confirm_title">
            {(landingAdvice && STATIC.landing_disclaimer[0]) ||
              STATIC.landing_disclaimer[1]}
          </h3>
          <div className="pad-confirm_textarea">
            <Input.TextArea
              defaultValue={landingAdvice}
              autoSize
              showCount
              onChange={changeLandingAdviceHandler}
              placeholder={STATIC.confirm}
            />
          </div>
        </div>
      );

      return Modal.confirm({
        className: 'pad-confirm_modal',
        width: 720,
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: modalContent(),
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk: form.submit,
      });
    } catch (err) {
      notification.error({
        message: 'Error',
        description: 'Please fill in all required fields!',
      });

      const [fieldErrorName] = err?.errorFields[0]?.name;

      form.scrollToField(fieldErrorName);
    }

    return undefined;
  }, [form]);

  const saveButtonHandler = useCallback(() => {
    if (currentPadCategory !== 1) {
      setOrderedUploads();
      confirmPadSubmit();
    } else {
      setOrderedUploads();
      form.submit();
    }
  }, [currentPadCategory, setOrderedUploads, confirmPadSubmit, form]);

  const searchLocationAndGetLocationByName = useCallback((name) => {
    if (name) {
      return new Promise((resolve, reject) =>
        geocoderService.geocode(
          {
            address: name,
          },
          (results, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK)
              return resolve({
                address: results[0].formatted_address,
                latitude: results[0].geometry.location.lat(),
                longitude: results[0].geometry.location.lng(),
                country: getAddressComponentByName(
                  results[0].address_components,
                  'country',
                ).long_name,
                country_short: getAddressComponentByName(
                  results[0].address_components,
                  'country',
                ).short_name,
                county: getAddressComponentByName(
                  results[0].address_components,
                  'administrative_area_level_2',
                ).long_name,
              });
            return reject();
          },
        ),
      );
    }

    return null;
  }, []);

  const onClickModalCancel = useCallback(() => {
    setClickInModal('no');
    setIsChangedOnMap(true);
    saveButtonHandler();
    setIsModalDisplayed(false);
  }, [saveButtonHandler]);

  const onClickModalOk = useCallback(() => {
    setClickInModal('yes');
    setIsModalDisplayed(false);

    searchLocationAndGetLocationByName(
      form.getFieldValue('location#name').address,
    ).then((res) => {
      setLocation((prev) => ({
        ...prev,
        ...res,
      }));
    });

    setIsChangedOnMap(false);
    setDisplayedWarningAddressSplit(false);
    saveButtonHandler();
  }, [form, searchLocationAndGetLocationByName, saveButtonHandler]);

  const onClickSave = useCallback(() => {
    if (
      (locationOnChanged.latitude !== location.latitude ||
        locationOnChanged.longitude !== location.longitude) &&
      pad_id
    ) {
      setIsModalDisplayed(true);
    } else if (
      !pad_id &&
      !isAddreesForFirstTime &&
      displayedWarningAddressSplit
    ) {
      setIsModalDisplayed(true);
    } else {
      saveButtonHandler();
    }
  }, [
    saveButtonHandler,
    location,
    setIsModalDisplayed,
    locationOnChanged,
    pad_id,
    isAddreesForFirstTime,
    displayedWarningAddressSplit,
  ]);

  useMount(() => {
    setOldLocation({
      latitude: location?.lat || pad?.location?.latitude,
      longitude: location?.lng || pad?.location?.longitude,
      is_address_split: pad?.location?.is_address_split,
    });
    setLocationOnChanged({
      latitude: location?.lat || pad?.location?.latitude,
      longitude: location?.lng || pad?.location?.longitude,
      name: location?.address || pad?.location?.name,
    });
    setDisplayedWarningAddressSplit(pad?.location?.is_address_split);
  });

  // change locationOnChanged if search input is changed
  const onSelectValue = (e) => {
    if (e?.target?.value?.latitude && e?.target?.value?.longitude && pad_id) {
      setLocationOnChanged((prev) => ({
        ...prev,
        ...e.target.value,
      }));
      setDisplayedWarningAddressSplit(true);
    } else if (
      !pad_id &&
      e?.target?.value?.latitude &&
      e?.target?.value?.longitude &&
      isAddreesForFirstTime
    ) {
      setLocation((prev) => ({
        ...prev,
        ...e.target.value,
      }));
      setDisplayedWarningAddressSplit(false);
    } else {
      setLocationOnChanged((prev) => ({
        ...prev,
        name: e.target.value.address,
      }));
      setDisplayedWarningAddressSplit(true);
    }
  };

  const editContactIndex = useCallback(() => {
    if (pad?.contacts?.length) {
      const primaryIndex = pad?.contacts.findIndex((user) =>
        Boolean(user?.is_primary),
      );

      if (primaryIndex !== -1) {
        return [pad?.contacts[primaryIndex]];
      }
    }

    return [];
  }, [pad]);

  useEffect(() => {
    form.setFields([{ name: 'edited_contacts', value: editContactIndex() }]);
  }, [editContactIndex, form]);

  const maxUploadCount = useMemo(() => {
    if (pad?.uploads) {
      const ownerUploads = pad.uploads.filter(
        (u) => u?.user?.role === 3 && !cardinalDirections.includes(u.direction),
      );

      return 4 - ownerUploads.length + pad.uploads.length;
    }

    return 4;
  }, [pad]);

  return (
    <div className="pad-form-container">
      {pad?.id && menu && (
        <HeaderL2 backPath={backPath}>
          <div className="header-nav">{menu}</div>
          <div className="header-actions">
            <Button type="link" onClick={previousAction}>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={onClickSave}
              disabled={loadingBarEnabled}
            >
              Save Changes
            </Button>
          </div>
        </HeaderL2>
      )}
      <div className="small-container">
        <Form
          className="pad-form"
          form={form}
          onValuesChange={onFormChange}
          initialValues={{
            ...pad,
            warnings: getSelectedCheckboxes('warnings', 'id'),
            facilities: getSelectedCheckboxes('facilities', 'id'),
            tags: getSelectedCheckboxes('tags', 'name'),
            'pad_social#website': pad?.pad_social?.website,
            'pad_social#twitter_handle': pad?.pad_social?.twitter_handle?.replace(
              '@',
              '',
            ),
            landing_fees: ['0', '1'].includes(pad?.landing_fees?.toString())
              ? null
              : pad?.landing_fees?.toString(),
          }}
          onFinish={padSubmitHandler}
        >
          <h2 className="v1-h1">General Info</h2>
          <Divider />
          <Form.Item
            {...formItemVerticalLayout}
            name="name"
            label="Name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Pad name" />
          </Form.Item>
          <Form.Item
            {...formItemVerticalLayout}
            style={{ maxWidth: 370 }}
            name="category_id"
            label="Category"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Choose"
              options={categoriesOptions}
              onChange={(id) => setCurrentPadCategory(id)}
            />
          </Form.Item>
          <div>
            <div className="ant-col ant-col-24 ant-form-item-label">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="site_information" title="Site Description">
                Site Description
              </label>
            </div>
            <div className="sub-label-text">
              e.g. Private house within the grounds of Tattingstone Place
            </div>
            <Form.Item {...formItemVerticalLayout} name="site_information">
              <Input.TextArea
                placeholder="Do NOT include technical landing information here. This is only for interesting or offering information."
                autoSize={{ minRows: 4 }}
                showCount
              />
            </Form.Item>
            <div className="ant-col ant-col-24 ant-form-item-label">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="site_information" title="Site Description">
                Photos
              </label>
              <div className="sub-label-text mb-2">
                You can only upload 4 photos
              </div>
            </div>
            {pad.id ? (
              <PhotoUpload
                fileList={fileList}
                onChange={picturesWallChangeHandle}
                onRemove={picturesWallRemoveHandle}
                customRequest={uploadFileHandle}
                setFileList={setFileList}
                multiple
                uploadLimit={maxUploadCount}
              />
            ) : (
              <Form.Item
                {...formItemVerticalLayout}
                name="uploads"
                valuePropName="fileList"
                getValueFromEvent={photosGetValueFromEvent}
              >
                <PhotoUpload
                  multiple
                  beforeUpload={photosBeforeUpload}
                  uploadLimit={maxUploadCount}
                />
              </Form.Item>
            )}
            <h2 className="v1-h1 mb-3 mt-3">Contact Details</h2>

            <Divider />

            <PrimaryContact pad={pad} updateFormValues={form.setFields} />

            <Row justify="space-between">
              <Col span={24} md={10}>
                <Form.Item
                  {...formItemVerticalLayout}
                  label="Website"
                  name="pad_social#website"
                >
                  <Input type="url" placeholder="Website" />
                </Form.Item>
              </Col>
              <Col span={24} md={10}>
                <Form.Item
                  {...formItemVerticalLayout}
                  label="Twitter handle"
                  name="pad_social#twitter_handle"
                  rules={twitterUsernameHandle}
                >
                  <Input prefix="@" placeholder="Twitter handle" />
                </Form.Item>
              </Col>
            </Row>

            <div className="ant-col ant-col-24 ant-form-item-label">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                htmlFor="site_information"
                className="mb-1"
                title="Site Description"
              >
                Tags
              </label>
              <div className="sub-label-text mb-2">
                e.g. camping, fishing, breakfast
              </div>
            </div>
            <Form.Item {...formItemVerticalLayout} name="tags">
              <Select
                mode="multiple"
                options={tags}
                placeholder="Select existing"
              />
            </Form.Item>
            <div className="ant-col ant-col-24 ant-form-item-label">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                htmlFor="site_information"
                className="mb-1"
                title="Site Description"
              >
                Map & Coordinates
              </label>
              <div className="sub-label-text mb-2">
                Drag the pin to the exact landing location
              </div>
            </div>
            {displayedWarningAddressSplit && (
              <div className="survey-step__text-info start">
                <ExclamationIcon className="icon" />
                <Typography.Text>
                  The address does not match the coordinates
                </Typography.Text>
              </div>
            )}
            <MapWithSinglePad
              containerStyle={{ marginBottom: 24 }}
              onReady={mapOnReadyHandle}
              map={map}
              location={newPadLocation}
              locationOnDragend={locationOnDragendHandle}
            />
            <Row justify="space-between" gutter={[24, 24]}>
              <Col span={24}>
                <Form.Item
                  {...formItemVerticalLayout}
                  name="location#name"
                  label="Address"
                  trigger="onSelect"
                  validateTrigger="onSelect"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the address from the list',
                    },
                  ]}
                >
                  <SearchLocation
                    autoComplete="new-password"
                    onSelect={onSelectValue}
                  >
                    <Input.TextArea autoSize />
                  </SearchLocation>
                </Form.Item>
              </Col>
              <Col span={24} md={6}>
                <Form.Item
                  {...formItemVerticalLayout}
                  label="Latitude"
                  name="location#latitude"
                  rules={[...latValidationRules, { required: true }]}
                >
                  <NumericInput placeholder="Latitude" />
                </Form.Item>
              </Col>
              <Col span={24} md={6}>
                <Form.Item
                  {...formItemVerticalLayout}
                  label="Longitude"
                  name="location#longitude"
                  rules={[...lngValidationRules, { required: true }]}
                >
                  <NumericInput placeholder="Longitude" />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues['location#latitude'] !==
                      curValues['location#latitude'] ||
                    prevValues['location#longitude'] !==
                      curValues['location#longitude']
                  }
                >
                  {({ getFieldValue }) => (
                    <Form.Item {...formItemVerticalLayout} label="DMS Format">
                      <Input
                        readOnly
                        placeholder="Read only"
                        className="dms-input font-weight-semi-bold"
                        value={convertDMS(
                          getFieldValue('location#latitude'),
                          getFieldValue('location#longitude'),
                        )}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
            </Row>
            {currentPadCategory !== 1 && (
              <div className="ant-col ant-col-24 ant-form-item-label">
                <div className="d-flex align-items-center title-with-action">
                  <h2 className="v1-h1 mb-1">Landing Instructions</h2>
                  <div className="action ml-2">
                    <Popover content={STATIC.landing_explainer}>
                      <Button type="link" className="ant-btn-reseted">
                        <span className="icon bigger">
                          <QuestionIcon />
                        </span>
                      </Button>
                    </Popover>
                  </div>
                </div>
                <div className="sub-label-text mb-2">
                  e.g. Fairly high trees but approach for the clear area to S of
                  helipad
                </div>
              </div>
            )}
            {currentPadCategory !== 1 && (
              <Form.Item {...formItemVerticalLayout} name="landing_advice">
                <Input.TextArea
                  autoSize={{ minRows: 7 }}
                  showCount
                  placeholder={STATIC.confirm}
                />
              </Form.Item>
            )}
            {currentPadCategory !== 7 && currentPadCategory !== 1 && (
              <Form.Item
                name="warnings"
                label="Establishment Warnings"
                {...formItemVerticalLayout}
              >
                <Checkbox.Group>{getCheckboxes(warnings)}</Checkbox.Group>
              </Form.Item>
            )}
            {currentPadCategory === 7 && currentPadCategory !== 1 && (
              <Form.Item
                name="facilities"
                label="For Establishments Only"
                {...formItemVerticalLayout}
              >
                <Checkbox.Group>{getCheckboxes(facilities)}</Checkbox.Group>
              </Form.Item>
            )}
            <Row justify="space-between">
              <PadFormLandingFee />
              {[3, 7].includes(currentPadCategory) && (
                <Col span={24} md={10}>
                  <Form.Item
                    {...formItemVerticalLayout}
                    label="ICAO"
                    name="icao"
                  >
                    <IcaoInput />
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Divider />
            <Button.Group>
              <Button className="secondary-btn" onClick={previousAction}>
                {cancelButtonText}
              </Button>
              <Button
                type="primary"
                onClick={onClickSave}
                disabled={loadingBarEnabled}
              >
                {saveButtonText}
              </Button>
            </Button.Group>
          </div>
        </Form>

        <Modal
          closeIcon={false}
          maskClosable={false}
          centered
          visible={isModalDisplayed}
          onOk={onClickModalOk}
          onCancel={onClickModalCancel}
          className="survey-confirmation-modal default-footer"
          cancelText="No"
          okText="Yes"
          cancelButtonProps={{
            className: 'outline-btn survey-outline',
          }}
        >
          <div className="survey-confirmation-content font-size-small">
            <h3>
              The calculated address of the pin is slightly different to the
              address you entered. Do you want us to move the pin? The pin
              position should always be the exact landing location so choose No
              if you are happy with it.
            </h3>
          </div>
        </Modal>
      </div>
    </div>
  );
};

PadForm.defaultProps = {
  pad: {},
  setStepIndex: () => null,
  setCurrentState: () => null,
  createdPad: null,
  previousAction: () => null,
  nextAction: () => null,
  menu: null,
  cancelButtonText: 'Cancel',
  saveButtonText: 'Save',
  onboarding: false,
  showPrimary: true,
};

PadForm.propTypes = {
  pad: PropTypes.shape({
    data: PropTypes.object,
    id: PropTypes.number,
    location: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      name: PropTypes.string,
      region: PropTypes.string,
      county: PropTypes.string,
      is_address_split: PropTypes.bool,
    }),
    uploads: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        file_url: PropTypes.string,
      }),
    ),
    warnings: PropTypes.array,
    facilities: PropTypes.array,
    landing_fees: PropTypes.number,
    tags: PropTypes.array,
    contacts: PropTypes.arrayOf(PropTypes.object),
    pad_social: PropTypes.shape({
      twitter_handle: PropTypes.string,
      website: PropTypes.string,
    }),
    category_id: PropTypes.number,
  }),
  setStepIndex: PropTypes.func,
  setCurrentState: PropTypes.func,
  createdPad: PropTypes.string,
  previousAction: PropTypes.func,
  nextAction: PropTypes.func,
  menu: PropTypes.object,
  saveButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  onboarding: PropTypes.bool,
  showPrimary: PropTypes.bool,
  backPath: PropTypes.string,
};

export default PadForm;
