import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import SearchPads from '../../../../components/searchPads';
import Modal from '../../../../uiKitComponents/modal';
import RegistrationFlowContext from '../../RegistrationFlow.context';

export default function PadDetailsSearch(props) {
  const { redirectToStep, updateRegistrationFlowState } = React.useContext(
    RegistrationFlowContext,
  );

  const { confirm } = Modal;

  const { padToClaim, setPadToClaim, onCallCreate, nextActionHandler } = props;

  const onPadSelectHandler = React.useCallback(
    (pad) => {
      setPadToClaim(pad);
      updateRegistrationFlowState({ pad_to_claim: pad });
    },
    [setPadToClaim, updateRegistrationFlowState],
  );

  const showModal = useCallback(() => {
    confirm({
      title: `${padToClaim.name} will now be visible to all of our Pilots`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirm',
      onOk: () => nextActionHandler(),
      width: 500,
      className: 'ant-modal-confirm-body-centered',
    });
  }, [padToClaim, nextActionHandler, confirm]);

  const NextButton = React.useMemo(() => {
    if (padToClaim === 0) {
      return (
        <Button type="primary" onClick={onCallCreate}>
          Add New Pad
        </Button>
      );
    }

    if (
      (padToClaim && !padToClaim.is_landing_permitted) ||
      padToClaim?.is_unmoderated
    )
      return (
        <Button type="primary" disabled={!padToClaim?.id} onClick={showModal}>
          Next
        </Button>
      );

    return (
      <Button
        type="primary"
        disabled={!padToClaim?.id}
        onClick={() => nextActionHandler()}
      >
        Next
      </Button>
    );
  }, [nextActionHandler, onCallCreate, padToClaim, showModal]);

  return (
    <div className="search-pad">
      <SearchPads
        label="Enter Pad Name"
        action={onPadSelectHandler}
        createPadAction={onCallCreate}
        value={padToClaim || {}}
      />
      <div className="search-pad_actions">
        <Button.Group>
          <Button
            className="secondary-btn"
            onClick={() => redirectToStep('role')}
          >
            Previous
          </Button>
          <Button type="text" onClick={() => redirectToStep('terms')}>
            Skip
          </Button>
          {NextButton}
        </Button.Group>
      </div>
    </div>
  );
}

PadDetailsSearch.propTypes = {
  padToClaim: PropTypes.any,
  setPadToClaim: PropTypes.func,
  onCallCreate: PropTypes.func,
  nextActionHandler: PropTypes.func,
};
