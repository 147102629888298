import { notification, Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import api from '../../../utils/appApi';
import getApiErrorMessages from '../../../utils/getApiErrorMessages';
import {
  getAvailablePrimaryContacts,
  getPrimaryContact,
} from './PrimaryContact.utils';
import PrimaryContactFields from './PrimaryContactFields';

export default function PrimaryContact(props) {
  const { pad, updateFormValues } = props;
  const [
    isUpdatingPrimaryContact,
    setIsUpdatingPrimaryContact,
  ] = React.useState(false);

  const [primaryContact, setPrimaryContact] = React.useState(
    getPrimaryContact(pad?.contacts),
  );

  const loadingBarEnabled = useSelector((store) => store.loadingBar.enabled);

  const options = React.useMemo(
    () => getAvailablePrimaryContacts(pad?.contacts),
    [pad],
  );

  const onSelectPrimary = React.useCallback(
    async (id) => {
      const selectedPrimaryContact = pad?.contacts.find((c) => c.id === id);

      try {
        setIsUpdatingPrimaryContact(true);
        await api.pad.changePrimaryContact(
          pad.id,
          selectedPrimaryContact.id,
          selectedPrimaryContact.contact_type_id,
        );
        setPrimaryContact(selectedPrimaryContact);
      } catch (error) {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(error),
        });
      } finally {
        setIsUpdatingPrimaryContact(false);
      }
    },
    [pad],
  );

  return (
    <div
      className="ant-col ant-col-24 ant-form-item-label"
      style={{ overflow: 'visible' }}
    >
      {!!options.length && (
        <Select
          size="small"
          style={{ minWidth: "100%", width: "100%" }}
          placeholder="Select Primary Contact"
          options={options}
          loading={isUpdatingPrimaryContact}
          className="mb-3"
          value={primaryContact?.id || null}
          onChange={onSelectPrimary}
          disabled={loadingBarEnabled || !options.length}
        />
      )}
      <PrimaryContactFields
        updateFormValues={updateFormValues}
        primaryContact={primaryContact}
      />
    </div>
  );
}

PrimaryContact.propTypes = {
  pad: PropTypes.object,
  onChange: PropTypes.func,
  updateFormValues: PropTypes.func,
};

PrimaryContact.defaultProps = {};
