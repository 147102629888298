import { Badge, Menu } from 'antd';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';

import { logout } from '../../store/actions/userAction';
import Button from '../../uiKitComponents/button';
// import { menuItemsRegister } from '../../utils/constants';

const HeaderL1 = () => {
  const { isAuthorized, userData, completedAt, surveyNew, surveyNewBanner } = useSelector((store) => ({
    isAuthorized: store.user.isAuthorized,
    userData: store.user.payload,
    completedAt: store.user?.payload?.completed_at,
    surveyNew: store.survey?.counter,
    surveyNewBanner: store.banner?.survey_count,
  }));
  const routeMatch = useRouteMatch();

  const dispatch = useDispatch();

  const logoutHandle = useCallback(() => logout()(dispatch), [dispatch]);

  return (
    <div
      className={`header-container hasBorder header-container-l1 d-flex align-items-center justify-content-center large-container ${isAuthorized && completedAt ? 'logged' : ''
        }`}
    >
      <div
        className="d-flex justify-content-between w-100"
        style={{ position: 'relative' }}
      >
        <Link to="/">
          <i className="custom-icon-dark-logo" />
          <i className="custom-icon-dark-logo-small" />
        </Link>
        {isAuthorized && completedAt && (
          <Menu mode="horizontal" selectedKeys={[routeMatch.params.part1]}>
            <Menu.Item key="my-sites">
              <Link to="/my-sites">My Sites</Link>
            </Menu.Item>
            <Menu.Item key="survey-360">
              <Badge
                count={surveyNew || surveyNewBanner}
                offset={[5, -5]}
              >
                <Link to="/survey-360">360 Survey</Link>
              </Badge>
            </Menu.Item>
            <Menu.Item key="my-account">
              <Link to="/my-account">Account</Link>
            </Menu.Item>
          </Menu>
        )}
        {isAuthorized && completedAt && (
          <div className="d-flex align-items-center">
            <div className="users-name">
              Welcome back, {userData.first_name}
            </div>
            <Button type="text" className="outline-btn" onClick={logoutHandle}>
              Logout
            </Button>
          </div>
        )}
        {isAuthorized && !completedAt && (
          <Button type="text" className="outline-btn" onClick={logoutHandle}>
            Logout
          </Button>
        )}
      </div>
    </div>
  );
};

export default HeaderL1;
