import './progressLine.scss';

import { Progress } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const progressPercent = (step, maxSteps) => step * 100 / maxSteps; 
const progressPercentToString = (step, maxSteps) => `${(step * 100 / maxSteps).toFixed(0)} % Completed`;

const ProgressLine = (props) => {

    const { step, maxSteps } = props;

    return (
        <>
        <div className='progress-box'>
            <div className='progress-box__showInfo'>{progressPercentToString(step, maxSteps)}</div>
            <div className='width-full d-flex mt-2'>
                <Progress 
                    steps={maxSteps}
                    percent={progressPercent(step, maxSteps)}
                    strokeColor={"#b51452"}
                    showInfo={false}
                    strokeWidth={8}
                />
            </div>
            
        </div>
        </>
    );
};

ProgressLine.propTypes = {
    step: PropTypes.number,
    maxSteps: PropTypes.number,
};

export default ProgressLine;