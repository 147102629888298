import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import HeaderL2 from '../../components/header-l2';
import ReviewElement from '../../components/reviewElement';
import SectionTitle from '../../components/sectionTitle';
import Button from '../../uiKitComponents/button';
import Col from '../../uiKitComponents/col';
import Divider from '../../uiKitComponents/divider';
import Empty from '../../uiKitComponents/empty';
import Menu from '../../uiKitComponents/menu';
import notification from '../../uiKitComponents/notification';
import Rate from '../../uiKitComponents/rate';
import Row from '../../uiKitComponents/row';
import Spin from '../../uiKitComponents/spin';
import Switch from '../../uiKitComponents/switch';
import api from '../../utils/appApi';
import getApiErrorMessages from '../../utils/getApiErrorMessages';
import removeDuplicates from '../../utils/removeDuplicates';
import QuickActions from '../SinglePadPage/quickActions';
import { quickAccessSetPad, quickAccessSetReviews } from '../../store/actions/padQuickAccessAction';
import { setIsUpdateData, setPadLandingsCounter, setPadLandingsToggle } from '../../store/actions/padLandingsActions';

const SiteReviewsPage = () => {
  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState({ reviews: [], stars: {} });
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDir, setCurrentDir] = useState('desc');
  const [reviewsSortingLoading, setReviewsSortingLoading] = useState(false);
  const [showMoreBtnStatus, setShowMoreBtnStatus] = useState(true);
  const [showMoreLoading, setShowMoreLoading] = useState(false);
  const history = useHistory();
  const { pad_id } = useParams();
  const dispatch = useDispatch();
  const [pad, setPad] = useState(null);

  const {
    padQuickAccess,
  } = useSelector((store) => ({
    padQuickAccess: store.padQuickAccess?.payload,
  }));

  useEffect(() => {
    if (
      reviews?.reviews &&
      reviews?.reviews.length >= 10 &&
      currentPage === 1
    ) {
      setShowMoreBtnStatus(true);
    } else {
      setShowMoreBtnStatus(false);
    }
  }, [reviews, currentPage]);

  useEffect(() => {
    api.reviews
      .get(pad_id, { direction: currentDir, page: currentPage })
      .then((res) => {
        if (res.data.reviews && res.data.reviews.length >= 10)
          setShowMoreBtnStatus(true);
        else setShowMoreBtnStatus(false);

        setReviews((prevState) => ({
          stars: { ...res.data.stars, ...prevState.stars },
          reviews: removeDuplicates([
            ...res.data.reviews,
            ...prevState.reviews,
          ]),
        }));

        setLoading(false);
        setShowMoreLoading(false);
        setReviewsSortingLoading(false);
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(err),
        });

        setTimeout(() => {
          try {
            history.goBack();
          } catch (e) {
            history.push('/my-sites');
          }
        }, 500);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDir, currentPage, history, pad_id]);

  const reviewsSortingHandler = () => {
    setReviewsSortingLoading(true);
    setCurrentDir((prevState) => (prevState === 'desc' ? 'asc' : 'desc'));
    setCurrentPage(1);
  };

  const reviewsShowMoreHandler = () => {
    setShowMoreLoading(true);
    setCurrentPage((prevState) => prevState + 1);
  };

  const displayPadReviewsList = () => {
    if (loading) {
      return (
        <div className="site-view d-flex align-items-center justify-content-center">
          <div className="d-flex align-items-center justify-content-center">
            <Spin />
          </div>
        </div>
      );
    }
    if (!reviews?.reviews?.length) {
      return <Empty />;
    }

    return reviews?.reviews?.map((review) => (
      <ReviewElement key={review?.id} review={review} />
    ));
  };

  const fetchErrorsHandler = (data) => {
    notification.error({
      message: 'Error',
      description: getApiErrorMessages(data.reason),
    });

    setTimeout(() => {
      history.push('/my-sites');
    }, 500);
  };

  const fetchPad = () => {
    Promise.allSettled([
      api.pad.get(pad_id),
      api.reviews.get(pad_id, { direction: 'desc', page: 1 }),
    ]).then(([fetchedPad, fetchedReviews]) => {
      if (fetchedPad.status === 'fulfilled') {
        setPad(fetchedPad.value.data.data);
        quickAccessSetPad(fetchedPad.value.data.data)(dispatch);
        setPadLandingsCounter(fetchedPad.value.data.data.landings_count)(dispatch);
        setPadLandingsToggle(fetchedPad.value.data.data.enabled_landings)(dispatch);
        setIsUpdateData(true)(dispatch);
      } else {
        fetchErrorsHandler(fetchedPad);
      }

      if (fetchedReviews.status === 'fulfilled') {
        quickAccessSetReviews(fetchedReviews.value.data)(dispatch);
      }
    });
  };

  useMount(() => {
    if (pad_id) {
      if (
        parseInt(pad_id, 10) === padQuickAccess.pad?.id &&
        padQuickAccess.reviews?.stars
      ) {
        setPad(padQuickAccess.pad);
      } else {
        fetchPad();
      }
    } else {
      history.push('/my-sites');
    }
  });

  return (
    <div className="site-view">
      <HeaderL2 backPath="/my-sites">
        <div className="site-view_header-nav">
          <Menu mode="horizontal" defaultSelectedKeys={['single-site_reviews']}>
            <Menu.Item key="single-site_view">
              <Link to={`/my-sites/site/${pad_id}`}>Site View</Link>
            </Menu.Item>
            <Menu.Item key="single-site_reviews">Reviews & Ratings</Menu.Item>
            <Menu.Item key="single-site_landing">
              <Link to={`/my-sites/landing/${pad_id}`}>Landings</Link>
            </Menu.Item>
          </Menu>
        </div>
        <div className="site-view_header-actions" />
      </HeaderL2>
      <div className="site-reviews">
        <div className="small-container">
          {!pad?.is_owned_by_current_user && <QuickActions pad={pad} fetchPad />}
          <div className="site-reviews_overall">
            <h1 className="v1-h1">Overall Rating</h1>
            <Row align="bottom" justify="space-between">
              <Col
                span={19}
                md={19}
                sm={24}
                xs={24}

              >
                <div className="rate-and-title-element mb-4">
                  <div className="rate-label">Ease of landings</div>
                  <div className="d-flex align-items-center">
                    <Rate value={reviews?.stars.landing} disabled allowHalf />
                    <div className="rate-value">
                      {reviews?.stars.landing} / 5
                    </div>
                  </div>
                </div>
                <div className="rate-inline-container d-flex">
                  <div className="rate-and-title-element">
                    <div className="rate-label">Helifriendliness</div>
                    <div className="d-flex align-items-center">
                      <Rate
                        value={reviews?.stars?.helifriendliness}
                        disabled
                        allowHalf
                      />
                      <div className="rate-value">
                        {reviews?.stars?.helifriendliness} / 5
                      </div>
                    </div>
                  </div>
                  <div className="rate-and-title-element">
                    <div className="rate-label">Food</div>
                    <div className="d-flex align-items-center">
                      <Rate value={reviews?.stars.food} disabled allowHalf />
                      <div className="rate-value">
                        {reviews?.stars.food} / 5
                      </div>
                    </div>
                  </div>
                  <div className="rate-and-title-element">
                    <div className="rate-label">Service</div>
                    <div className="d-flex align-items-center">
                      <Rate value={reviews?.stars.service} disabled allowHalf />
                      <div className="rate-value">
                        {reviews?.stars.service} / 5
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                span={5}
                md={5}
                sm={24}
                xs={24}
              >
                <div className="site-reviews_interest">
                  <h3 className="v1-h3">Would visit again</h3>
                  <span style={{ fontSize: '1.2em' }}>
                    {reviews?.stars?.total_ratings} of{' '}
                    {reviews?.stars?.total_is_visited_again} would visit again
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          <Divider />
          <div className="site-reviews_list">
            <SectionTitle>
              <h1 className="v1-h1 mb-0">
                Ratings & Reviews ({reviews?.reviews?.length})
              </h1>
              <div className="site-reviews_sort">
                Most Recent
                <Switch
                  checked={currentDir === 'desc'}
                  onClick={reviewsSortingHandler}
                  loading={reviewsSortingLoading}
                />
              </div>
            </SectionTitle>
            <div className="list-container">{displayPadReviewsList()}</div>
          </div>
        </div>
      </div>
      {showMoreBtnStatus && (
        <div className="site-reviews_pagination d-flex align-items-center justify-content-center">
          <Button
            className="outline-btn"
            loading={showMoreLoading}
            onClick={reviewsShowMoreHandler}
          >
            Show More
          </Button>
        </div>
      )}
    </div>
  );
};

export default SiteReviewsPage;
