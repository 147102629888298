import { Tooltip } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as InfoCircleInfo } from '../../assets/icons/info-circle.svg';
import { ReactComponent as SurveyLogo } from '../../assets/icons/survey/logo.svg';

const SurveysTooltip = () => {

    const { banner } = useSelector((state) => ({
        banner: state.banner,
    }));

    return (
        (!banner.is_banner_displayed || JSON.parse(localStorage.getItem('carousel')) === null) && (
            <Tooltip
                title={(
                    <div className="carousel-banner__content">
                        <div className="text-center">
                            <SurveyLogo
                                style={{ width: "45px", height: '45px', margin: '10px 0' }}
                            />
                            <h2>Helipaddy 360 Survey</h2>
                        </div>
                        <div>
                            <p>
                                The idea here is to give you as the site owner, the ability to pass
                                on more in depth details about the landing area directly to pilots.
                                It&#39;s a feature that&#39;s been highly requested, so pilots can
                                feel as though they&#39;ve visited a site before landing there.
                            </p>
                            <p>
                                We suggest putting aside <b>15 minutes</b> to complete the survey.
                                You don&#39;t have to be a pilot, a surveyor, or know any flying
                                lingo to complete it. We&#39;ve broken it down into 7 simple
                                sections for you and we&#39;ll guide you through each one. A
                                completed survey will make your site far more appealing to pilots,
                                as well as enabling a much safer operation from both sides.
                            </p>
                            <p>
                                Any Questions, please don&#39;t hesitate to
                                <a href="mailto:support@helipaddy.com" className="mr-4 contact-us">
                                    contact us.
                                </a>
                            </p>
                        </div>
                    </div>
                )}>
                <InfoCircleInfo className="ml-2" />
            </Tooltip>
        )
    );
};

export default SurveysTooltip;
