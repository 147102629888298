import './services/smooch';

import { useEffect } from 'react';
import ReactGA from 'react-ga';
import ReactGoogleMapLoader from 'react-google-maps-loader';
import { useDispatch, useSelector } from 'react-redux';

import styles from './App.module.scss';
import GoogleMapsContext from './context/googleMapsContext';
import Router from './router';
import routes from './router/routes';
import {
  setCountriesData,
  setGlobalRequisites,
} from './store/actions/bootstrapAction';
import { getUser } from './store/actions/userAction';

if (window.location.hostname === process.env.REACT_APP_GA_HOSTNAME) {
  ReactGA.initialize(process.env.REACT_APP_GA_ID, { debug: false });
}

function App() {
  const dispatch = useDispatch();
  const { isAuthorized } = useSelector((state) => ({
    isAuthorized: state.user.isAuthorized,
  }));

  useEffect(() => {
    if (isAuthorized) {
      getUser()(dispatch);
      setGlobalRequisites()(dispatch);
    }

    setCountriesData()(dispatch);
  }, [dispatch, isAuthorized]);

  useEffect(() => {
    if (window.location.hostname === process.env.REACT_APP_GA_HOSTNAME) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  return (
    <div className={styles.root}>
      <ReactGoogleMapLoader
        params={{
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
          libraries: 'places',
          language: 'en',
        }}
        render={(googleMaps, googleMapsError) => (
          <GoogleMapsContext.Provider
            value={{ googleMapsIsLoaded: !!googleMaps, googleMapsError }}
          >
            {googleMapsError}
            <Router routes={routes} />
          </GoogleMapsContext.Provider>
        )}
      />
    </div>
  );
}

export default App;
