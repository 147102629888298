import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useMount } from 'react-use';

import PadForm from '../../components/padForm';
import { createdPadEventOpts } from '../../events';
import useGaEvent from '../../hooks/useGAEvent';
import { pushPadToUserPads } from '../../store/actions/padsAction';
import notification from '../../uiKitComponents/notification';
import api from '../../utils/appApi';

const CreatePadPage = () => {
  const { pad_id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [pad, setPad] = useState({ isLoaded: !pad_id, data: {} });

  // GA events
  const createdPadEvent = useGaEvent(createdPadEventOpts);

  const padFormPrevHandler = () => history.push(`/my-sites`);

  const padFormNextHandler = async (createdPad) => {
    try {
      await Promise.allSettled([
        pushPadToUserPads(createdPad)(dispatch),
        api.pad.claim(createdPad.id),
      ]);
      notification.success({
        message: 'Site has been successfully created!',
      });
    } catch (errors) {
      console.error(errors);
    }

    history.push(`/my-sites`);
  };

  useMount(() => {
    if (pad_id)
      api.pad.get(pad_id).then((res) =>
        setPad((prevState) => ({
          ...prevState,
          isLoaded: true,
          data: res.data,
        })),
      );
  });

  return (
    <div className="create-pad">
      <div className="small-container">
        <PadForm
          pad={pad}
          previousAction={padFormPrevHandler}
          nextAction={createdPadEvent(padFormNextHandler)}
          saveButtonText={'Create'}
          showPrimary={false}
        />
      </div>
    </div>
  );
};

export default CreatePadPage;
