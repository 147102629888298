import {
    SURVEY_CLEAN,
    SURVEY_COUNTER_NEW,
    SURVEY_SET,
    SURVEY_SUBMIT_STEP,
    SURVEY_UPDATE,
    SURVEY_UPDATE_STEP,
  } from '../actionTypes';
  
  const initialState = {
    counter: 0,
  };
  
  const survey = (state = initialState, action = {}) => {
    switch (action.type) {
      case SURVEY_COUNTER_NEW:
        return {
            ...state,
            ...action.payload,        
        };
      default:
        return state;
    }
  };
  
  export default survey;