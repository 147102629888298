import { Button, Input } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useDebounce } from 'react-use';

import { ReactComponent as TrashIcon } from '../../../../../assets/icons/trash.svg';
import condStrings from '../../../../../utils/condStrings';
import useStepUpdater from '../../../hooks/useStepUpdater';
import { PAD_VIEW_STEP } from '../../../Survey.context';
import UploadCardBody from './UploadCardBody';

export default function UploadCard(props) {
  const {
    sideKey,
    title,
    comment,
    upload,
    removeFile,
    uploadFile,
    isUploading,
    isRemoving,
  } = props;
  const [onStepValuesChange] = useStepUpdater(PAD_VIEW_STEP);

  const [error, setError] = React.useState(null);
  const [commentValue, setCommentValue] = React.useState(comment);

  const updatedPadViewComments = React.useCallback(() => {
    if (comment !== commentValue && !error) {
      onStepValuesChange(
        {
          [`${sideKey}_comment`]: commentValue,
        },
        { immediately: true },
      );
    }
  }, [comment, commentValue, error, onStepValuesChange, sideKey]);

  useDebounce(updatedPadViewComments, 500, [commentValue]);

  const onCommentChange = React.useCallback((e) => {
    const value = e.target.value ?? '';

    setCommentValue(value);

    if (value.length > 100) {
      setError('Comment should be less than 100 characters');
    } else {
      setError(null);
    }
  }, []);

  return (
    <div className="survey-pad__view__upload-card">
      <div className="upload-card__header">
        <h4 className="upload-card__title">{title}</h4>
        <Input.TextArea
          autoSize={{ minRows: 2 }}
          className="survey-textarea max-100 scrollbar-thin"
          style={{
            minHeight: '78px !important',
            borderColor: error ? '#ff4d4f' : '#e7edf3',
          }}
          showCount
          maxLength={100}
          placeholder="Please describe any obstacles in the image"
          value={commentValue}
          onChange={onCommentChange}
        />
        {error && (
          <div className="ant-form-item-explain ant-form-item-explain-error">
            <div role="alert">{error}</div>
          </div>
        )}
      </div>
      <UploadCardBody
        isLoading={isUploading}
        onClick={uploadFile}
        upload={upload}
      />
      <div className={condStrings('upload-card__footer', (!upload || upload?.length === 0) && 'hidden')}>
        <Button
          type="link"
          size="small"
          loading={isRemoving}
          icon={<TrashIcon />}
          onClick={removeFile}
        >
          {isRemoving ? 'Removing...' : 'Remove'}
        </Button>
      </div>
    </div>
  );
}

UploadCard.propTypes = {
  sideKey: PropTypes.string,
  title: PropTypes.string,
  comment: PropTypes.string,
  upload: PropTypes.object,
  uploadFile: PropTypes.func,
  removeFile: PropTypes.func,
  isUploading: PropTypes.bool,
  isRemoving: PropTypes.bool,
};
