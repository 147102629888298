export const hazards = [
  {
    id: 1,
    name: 'Animals',
    categories: [2, 3, 4, 5, 6],
  },
  {
    id: 2,
    name: 'Avoid certain times',
    categories: [2, 3, 4, 5, 6],
  },
  {
    id: 3,
    name: 'Noise sensitivities',
    categories: [2, 3, 4, 5, 6, 7],
  },
  {
    id: 4,
    name: 'Drone activity',
    categories: [2, 3, 4, 5, 6],
  },
  {
    id: 5,
    name: 'Advanced pilots only',
    categories: [2, 3, 4, 5, 6],
  },
  {
    id: 6,
    name: 'Power lines',
    categories: [2, 3, 4, 5, 6, 7],
  },
  {
    id: 7,
    name: 'Near a public footpath',
    categories: [2, 3, 4, 5, 6],
  },
  {
    id: 8,
    name: 'Difficult landing',
    categories: [2, 3, 4, 5, 6, 7],
  },
  {
    id: 99,
    name: 'Other',
    categories: [2, 3, 4, 5, 6, 7],
  },
];
