import { PhoneOutlined } from '@ant-design/icons';
import { Button, Form, Input, notification } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';

import CountrySelect from '../../../../components/CountrySelect';
import PhoneInput from '../../../../components/phoneInput';
import { updateUser } from '../../../../store/actions/userAction';
import api from '../../../../utils/appApi';
import getApiErrorMessages from '../../../../utils/getApiErrorMessages';
import phoneWithPlus from '../../../../utils/phoneWithPlus';
import RegistrationFlowContext from '../../RegistrationFlow.context';
import EmailVerification from './EmailVerification';

export function PersonalDetailsStep() {
  const { redirectToStep, updateFlowActiveIndex } = React.useContext(
    RegistrationFlowContext,
  );
  const { user } = useSelector((state) => ({
    user: state?.user?.payload,
  }));
  const [selectedCountry, setSelectedCountry] = React.useState('gb');
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  useMount(() => {
    if (user.id) {
      api.user.storeRegistrationProgress(user.id, 0);
    }
    updateFlowActiveIndex('personal_details');
  });

  const onSubmit = React.useCallback(
    async (values) => {
      setLoading(true);

      try {
        await dispatch(
          updateUser({ ...values, phone: phoneWithPlus(values.phone) }),
        );
        setLoading(false);
        redirectToStep('role');
      } catch (error) {
        setLoading(false);
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(error),
        });
      }
    },
    [dispatch, redirectToStep],
  );

  if (!user?.email_verified_at) {
    return <EmailVerification />;
  }

  return (
    <div className="personal-details">
      <div className="personal-details_inf">
        <Form onFinish={onSubmit} initialValues={user}>
          <Form.Item
            name="first_name"
            rules={[
              {
                required: true,
                message: 'Name is required',
              },
              { min: 3, message: 'Min 3 characters' },
            ]}
          >
            <Input
              size="large"
              placeholder="First name"
              autoComplete="given-name"
            />
          </Form.Item>
          <Form.Item
            name="last_name"
            rules={[
              {
                required: true,
                message: 'Surname is required',
              },
              { min: 3, message: 'Min 3 characters' },
            ]}
          >
            <Input
              size="large"
              placeholder="Last name"
              autoComplete="family-name"
            />
          </Form.Item>

          <CountrySelect onChange={setSelectedCountry} />

          <Form.Item name="phone" fieldKey="phone">
            <PhoneInput
              placeholder="Phone number"
              country={selectedCountry}
              icon={<PhoneOutlined />}
            />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            style={{ maxWidth: 'unset' }}
            size="large"
            loading={loading}
          >
            Next
          </Button>
        </Form>
      </div>
    </div>
  );
}
