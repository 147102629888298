import {
  IS_UPDATE_DATA,
  PAD_LANDINGS_COUNTER,
  PAD_LANDINGS_LIST,
  PAD_LANDINGS_TOGGLE,
  UPLOAD_PAD_LANDINGS_LIST,
  UPDATE_PAD_ELEMENT_LIST,
  SURVEY_STATUS,
} from '../actionTypes';

export const setPadLandingsCounter = (data) => (dispatch) =>
  dispatch({ type: PAD_LANDINGS_COUNTER, payload: data });

export const setPadLandingsToggle = (data) => (dispatch) =>
  dispatch({ type: PAD_LANDINGS_TOGGLE, payload: data });

export const setSurveyStatus = (data) => (dispatch) =>
  dispatch({ type: SURVEY_STATUS, payload: data });

export const setIsUpdateData = (data) => (dispatch) =>
  dispatch({ type: IS_UPDATE_DATA, payload: data });

export const setPadLandingsList = (data) => (dispatch) =>
  dispatch({ type: PAD_LANDINGS_LIST, payload: data });

export const uploadPadLandings = (data) => (dispatch) =>
  dispatch({ type: UPLOAD_PAD_LANDINGS_LIST, payload: data });

export const updatePadElementList = (data) => (dispatch) =>
  dispatch({ type: UPDATE_PAD_ELEMENT_LIST, payload: data });