export const manageSiteEventOpts = {
  category: 'Sites',
  action: 'Manage a new site',
  label: 'My Sites page',
};

export const claimSiteEventOpts = {
  category: 'Sites',
  action: 'Site Claimed',
  Label: 'Existing Pad Search',
};

export const createNewSiteEventOpts = {
  category: 'Sites',
  action: 'Add New Pad',
  label: 'New site to Helipaddy',
};

export const createdPadEventOpts = {
  category: 'Sites',
  action: 'Saved New Site',
  label: 'New site is submitted',
};

export const subscribeEventOpts = {
  category: 'Subscription',
  action: 'Stripe - Subscribe',
  label: 'My Account',
};

export const editPadEventOpts = {
  category: 'Sites',
  action: 'Edit Pad',
  label: 'Pad View',
};

export const saveEditedPadEventOpts = {
  category: 'Sites',
  action: 'Save Changes',
  label: 'Pad View',
};

export const stopManagingEventOpts = {
  category: 'Sites',
  action: 'Stop managing this site',
  label: 'Pad View',
};

export const removeSiteEventOpts = {
  category: 'Sites',
  action: 'Remove this site from Helipaddy',
  label: 'Pad View',
};

export const subscribeCompletedEventOpts = {
  category: "Subscription",
  action: "Subscribed",
  label: "Submitted from Stripe",
}