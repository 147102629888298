import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ApproachIcon } from '../../../../assets/icons/survey/approach.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/survey/check-simple.svg';
import { ReactComponent as FacilitiesIcon } from '../../../../assets/icons/survey/facilities.svg';
import { ReactComponent as GeneralIcon } from '../../../../assets/icons/survey/general.svg';
import { ReactComponent as HazardsIcon } from '../../../../assets/icons/survey/hazards.svg';
import { ReactComponent as LandingIcon } from '../../../../assets/icons/survey/landing.svg';
import { ReactComponent as MapIcon } from '../../../../assets/icons/survey/map.svg';
import { ReactComponent as PadViewIcon } from '../../../../assets/icons/survey/pad-view.svg';
import condStrings from '../../../../utils/condStrings';
import { SurveyContext } from '../../Survey';
import {
  APPROACH_STEP,
  FACILITIES_STEP,
  GENERAL_STEP,
  HAZARDS_STEP,
  LANDING_STEP,
  MAP_STEP,
  PAD_VIEW_STEP,
} from '../../Survey.context';

export function SurveyStepsBar(props) {
  const { type, onNavItemClick } = props;
  const { surveyData, activeSurveyStep } = React.useContext(SurveyContext);

  const getStepStatus = (key) => {
    const classes = [];

    if (key === activeSurveyStep) classes.push('active');
    if (surveyData[key]?.is_completed) classes.push('passed');

    return classes;
  };

  return (
    <div className={condStrings('survey-steps-bar', type)}>
      {type === 'navigation' && (
        <div
          tabIndex="-1"
          aria-hidden="true"
        >
          <Link
            to="/survey-360"
            className='survey-step__btn-back'
          >
            <Button
              type='text'
              icon={<ArrowLeftOutlined />}
            >
              Back
            </Button>
          </Link>
        </div>
      )}
      <div
        tabIndex="-1"
        aria-hidden="true"
        onClick={onNavItemClick(GENERAL_STEP)}
        className={condStrings('survey-bar-step', getStepStatus(GENERAL_STEP))}
      >
        <span className="bar-step-icon">
          <GeneralIcon />
        </span>
        <span className="bar-step-title">General</span>
        <span className="bar-step-status">
          <CheckIcon />
        </span>
      </div>
      <div
        tabIndex="-1"
        aria-hidden="true"
        onClick={onNavItemClick(MAP_STEP)}
        className={condStrings('survey-bar-step', getStepStatus(MAP_STEP))}
      >
        <span className="bar-step-icon">
          <MapIcon />
        </span>
        <span className="bar-step-title">Map</span>
        <span className="bar-step-status">
          <CheckIcon />
        </span>
      </div>
      <div
        tabIndex="-1"
        aria-hidden="true"
        onClick={onNavItemClick(LANDING_STEP)}
        className={condStrings('survey-bar-step', getStepStatus(LANDING_STEP))}
      >
        <span className="bar-step-icon">
          <LandingIcon />
        </span>
        <span className="bar-step-title">Landing</span>
        <span className="bar-step-status">
          <CheckIcon />
        </span>
      </div>
      <div
        tabIndex="-1"
        aria-hidden="true"
        onClick={onNavItemClick(PAD_VIEW_STEP)}
        className={condStrings('survey-bar-step', getStepStatus(PAD_VIEW_STEP))}
      >
        <span className="bar-step-icon pad-view">
          <PadViewIcon />
        </span>
        <span className="bar-step-title">360 Pad View</span>
        <span className="bar-step-status">
          <CheckIcon />
        </span>
      </div>
      <div
        tabIndex="-1"
        aria-hidden="true"
        onClick={onNavItemClick(HAZARDS_STEP)}
        className={condStrings('survey-bar-step', getStepStatus(HAZARDS_STEP))}
      >
        <span className="bar-step-icon">
          <HazardsIcon />
        </span>
        <span className="bar-step-title">Hazards</span>
        <span className="bar-step-status">
          <CheckIcon />
        </span>
      </div>
      <div
        tabIndex="-1"
        aria-hidden="true"
        onClick={onNavItemClick(APPROACH_STEP)}
        className={condStrings('survey-bar-step', getStepStatus(APPROACH_STEP))}
      >
        <span className="bar-step-icon">
          <ApproachIcon />
        </span>
        <span className="bar-step-title">Procedures</span>
        <span className="bar-step-status">
          <CheckIcon />
        </span>
      </div>
      <div
        tabIndex="-1"
        aria-hidden="true"
        onClick={onNavItemClick(FACILITIES_STEP)}
        className={condStrings(
          'survey-bar-step',
          getStepStatus(FACILITIES_STEP),
        )}
      >
        <span className="bar-step-icon">
          <FacilitiesIcon />
        </span>
        <span className="bar-step-title">Features</span>
        <span className="bar-step-status">
          <CheckIcon />
        </span>
      </div>
    </div>
  );
}

SurveyStepsBar.propTypes = {
  activeKey: PropTypes.string,
  type: PropTypes.oneOf(['greeting', 'navigation']),
  onNavItemClick: PropTypes.func,
  activeSurveyStep: PropTypes.string,
};

SurveyStepsBar.defaultProps = {
  activeKey: '',
  type: 'navigation',
  onNavItemClick: () => () => null,
};
