import Smooch from 'smooch';

Smooch.init({
  integrationId: '610945f4d6666900d39856c8',
  customColors: {
    brandColor: 'b51452',
    actionColor: 'b51452',
    conversationColor: 'b51452',
  },
  prechatCapture: {
    avatarUrl: 'https://app.helipaddy.com/android-chrome-192x192.png',
    enabled: true,
    enableEmailLinking: true,
  },
  buttonIconUrl: `${process.env.PUBLIC_URL}/static/question-mark.svg`,
}).then(() => {
  const button = document
    .getElementById('web-messenger-container')
    .contentWindow.document.getElementById('messenger-button');
  const buttonIcon = button.querySelector('.messenger-button-icon');
  const buttonIconImage = buttonIcon.querySelector('img');

  button.style.backgroundColor = '#b51452';

  buttonIcon.style.display = 'flex';
  buttonIcon.style.alignItems = 'center';
  buttonIcon.style.justifyContent = 'center';

  buttonIconImage.style.width = '40px';
  buttonIconImage.style.height = '40px';
});
