import PropTypes from 'prop-types';
import { memo } from 'react';

import condStrings from '../../utils/condStrings';
import { formatTextToHtmlString } from '../../utils/formatText';
import styles from './formattedText.module.sass';

const FormattedText = (props) => {
  const { className, text, ...rest } = props;

  return (
    <div
      {...rest}
      className={condStrings(styles.root, className)}
      dangerouslySetInnerHTML={{
        __html: formatTextToHtmlString(text, { replaceBreakLines: false }),
      }}
    />
  );
};

FormattedText.defaultProps = {
  className: '',
  text: '',
};

FormattedText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

export default memo(FormattedText);
