/**
 * Remove Duplicates - function which return clean array
 * without duplicates.
 * 
 * *Verifcation works only by id.
 * @param {array} mergedArr
 * @returns {array} Clean array
 */
function removeDuplicates(mergedArr) {
  const arr = mergedArr.concat();
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < arr.length; ++i) {
    // eslint-disable-next-line no-plusplus
    for (let j = i + 1; j < arr.length; ++j) {
      if (arr[i]?.id === arr[j]?.id) {
        arr.splice(j, 1);
      }
    }
  }
  return arr;
}

export default removeDuplicates;
