import ReactGA from 'react-ga';

/**
 * @name useGaEvent
 *
 * @param {Object} event
 * @param {String} event.category
 * @param {String} event.action
 * @param {String} event.label
 *
 * @returns {Function}
 *
 */
export default function useGaEvent(event = {}) {
  /**
   * @description Send feedback and call callback if needed
   * @param {Function} clbk
   *
   * @returns {Function}
   */
  return (clbk = null) => (...args) => {
    if (window.location.hostname === process.env.REACT_APP_GA_HOSTNAME) {
      ReactGA.event(event);
    }

    if (clbk) clbk(...args);
  };
}
