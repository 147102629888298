/**
 * This is from Google Maps Geocode result
 * @param {[{types: [string]}]} address_components
 * @param {string} name
 * @returns {*|{}}
 */
const getAddressComponentByName = (address_components, name) => {
  const matchedObj = address_components.find((itm) => itm.types.includes(name));
  return matchedObj || {};
};
export default getAddressComponentByName;
