import './padElements.scss';

import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Check from '../../assets/icons/check-circle.svg';
import Comment from '../../assets/icons/comment.svg';
import Fee from '../../assets/icons/fee.svg';
import Reload from '../../assets/icons/reload.svg';
import ReloadWarn from '../../assets/icons/reload-warn.svg';
import Star from '../../assets/icons/star.svg';
import Warning from '../../assets/icons/warning.svg';
import Placeholder from '../../assets/images/placeholder-image.png';
import notification from '../../uiKitComponents/notification';
import {
  PAD_AWAITING_APPROVAL,
  PAD_AWAITING_MODERATION,
  PAD_CHECK_DETAILS,
  PAD_LANDING_NOT_POSSIBLE,
  PAD_PENDING_MODERATION,
  PAD_VERIFIED_AND_AVAILABLE,
} from '../../utils/constants';
import getPadIcon from '../../utils/getPadIcon';
import PadElementSurvey from './padElementSurvey';

const PadElement = (props) => {
  const { pad } = props;
  const [padCategoryName, setPadCategoryName] = useState(undefined);
  const [padStatus, setPadStatus] = useState(null);
  const [padClassName, setPadClassName] = useState('pad');
  const [padIcon, setPadIcon] = useState(null);
  const { categories } = useSelector((store) => ({
    categories: store.bootstrap?.payload?.categories,
  }));

  const initializePadStatusAndIcon = (status) => {
    switch (status) {
      case PAD_AWAITING_APPROVAL:
        setPadIcon(
          <div className="status not-verified">
            <span className="icon">
              <img alt="SAwaiting approval" src={Reload} />
            </span>
            <span className="text">Awaiting approval</span>
          </div>,
        );
        setPadClassName('pad not-verified');
        setPadStatus(PAD_AWAITING_APPROVAL);
        break;
      case PAD_AWAITING_MODERATION:
        setPadIcon(
          <div className="status in-moderation">
            <span className="icon">
              <img alt="Awaiting moderation" src={ReloadWarn} />
            </span>
            <span className="text">Awaiting moderation</span>
          </div>,
        );
        setPadClassName('pad in-moderation');
        setPadStatus(PAD_AWAITING_MODERATION);
        break;
      case PAD_CHECK_DETAILS:
        setPadIcon(
          <div className="status loading-not-possible">
            <span className="icon">
              <img alt="Check details" src={Warning} />
            </span>
            <span className="text">Check details - site not available</span>
          </div>,
        );
        setPadClassName('pad loading-not-possible');
        setPadStatus(PAD_CHECK_DETAILS);
        break;
      case PAD_LANDING_NOT_POSSIBLE:
        setPadIcon(
          <div className="status loading-not-possible">
            <span className="icon">
              <img alt="Landing not possible" src={Warning} />
            </span>
            <span className="text">Landing not possible - site hidden</span>
          </div>,
        );
        setPadClassName('pad loading-not-possible');
        setPadStatus(PAD_LANDING_NOT_POSSIBLE);
        break;
      case PAD_VERIFIED_AND_AVAILABLE:
        setPadIcon(
          <div className="status verified">
            <span className="icon">
              <img alt="Verified Pad" src={Check} />
            </span>
            <span className="text">Verified and available</span>
          </div>,
        );
        setPadClassName('pad verified');
        setPadStatus(PAD_VERIFIED_AND_AVAILABLE);
        break;

      case PAD_PENDING_MODERATION:
        setPadIcon(
          <div className="status in-moderation">
            <span className="icon">
              <img alt="Site Pending Moderation" src={ReloadWarn} />
            </span>
            <span className="text">Pending Moderation</span>
          </div>,
        );
        setPadClassName('pad in-moderation');
        setPadStatus(PAD_PENDING_MODERATION);
        break;
      default:
        setPadIcon(
          <div className="status verified">
            <span className="icon">
              <img alt="Verified Pad" src={Check} />
            </span>
            <span className="text">Verified Pad</span>
          </div>,
        );
        setPadClassName('pad verified');
        break;
    }
  };

  useEffect(() => {
    if (categories) {
      const categoryName = categories.find(
        (item) => item.id === pad?.category_id,
      );
      if (categoryName?.display_name === 'Hotel') {
        setPadCategoryName(categoryName?.category_type[1]?.display_name);
      } else {
        setPadCategoryName(categoryName?.category_type[0]?.display_name);
      }
    }
  }, [categories, pad]);

  useEffect(() => {
    if (!pad.is_claim_approved) {
      initializePadStatusAndIcon(PAD_AWAITING_APPROVAL);
    } else if (pad?.status_id === 1 && pad?.is_claim_approved) {
      initializePadStatusAndIcon(PAD_AWAITING_MODERATION);
    } else if (pad?.status_id === 3 && !pad?.is_valid && pad?.is_landing_permitted) {
      initializePadStatusAndIcon(PAD_CHECK_DETAILS);
    } else if (pad?.status_id === 3 && !pad?.is_landing_permitted) {
      initializePadStatusAndIcon(PAD_LANDING_NOT_POSSIBLE);
    } else if (pad?.status_id === 3 && pad?.is_valid) {
      initializePadStatusAndIcon(PAD_VERIFIED_AND_AVAILABLE)
    } else if (pad?.status_id === 2) {
      initializePadStatusAndIcon(PAD_PENDING_MODERATION)
    } else {
      initializePadStatusAndIcon()
    }



    // if (pad?.status_id === 1 && !pad.is_claim_approved) {
    //   initializePadStatusAndIcon(PAD_AWAITING_APPROVAL);
    // } else if (pad?.status_id === 1 && pad.is_claim_approved) {
    //   initializePadStatusAndIcon(PAD_AWAITING_MODERATION)
    // } else if (pad?.status_id === 3 && !pad?.is_valid && pad?.is_landing_permitted) {
    //   initializePadStatusAndIcon(PAD_CHECK_DETAILS);
    // } else if (pad?.status_id === 3 && !pad?.is_landing_permitted) {
    //   initializePadStatusAndIcon(PAD_LANDING_NOT_POSSIBLE);
    // } else if (pad?.status_id === 3 && pad?.is_valid) {
    //   initializePadStatusAndIcon(PAD_VERIFIED_AND_AVAILABLE)
    // } else if (pad?.status_id === 2) {
    //   initializePadStatusAndIcon(PAD_PENDING_MODERATION);
    // } else {
    //   initializePadStatusAndIcon();
    // }
  }, [pad]);

  const getStringRedaction = (str) =>
    (str && str.length > 190 && str.slice(0, 190).concat('...')) || str;

  const getProcessedPad = () => (
    <div className={padClassName}>
      <div className="pad-status">{padIcon}</div>
      <Row
        justify='space-between'
        align='middle'
        className='surveys-item'
      >
        <Col
          span={9}
          sm={9}
          xs={24}
        >
          <div className="pad-image">
            <img
              src={
                (pad?.uploads?.length && pad?.uploads[0].file_url) || Placeholder
              }
              alt={pad.name}
            />
          </div>
        </Col>
        <Col
          span={15}
          sm={15}
          xs={24}
        >
          <div className="pad-main">
            <h2 className="pad-main_title v1-h">{pad.name}</h2>
            <div className="pad-main_meta">
              <Row
                justify="start"
                className="surveys-item__info-meta"
              >
                <Col>
                  <span className="pad-main_meta-category">
                    {getPadIcon(pad.category_id, padCategoryName)}
                    <span className="text ml-1">{padCategoryName}</span>
                  </span>
                </Col>
                <Col>
                  <span className="pad-main_meta-raiting">
                    <span className="icon mr-2">
                      <img src={Star} alt={pad.rating} />
                    </span>
                    <span className="text">{pad.rating}</span>
                  </span>
                </Col>
                <Col>
                  <span className="pad-main_meta-reviews">
                    <span className="icon mr-2">
                      <img src={Comment} alt={pad.comments_count} />
                    </span>
                    <span className="text">{pad.comments_count}</span>
                  </span>
                </Col>
                <Col>
                  <span className="pad-main_meta-fee">
                    <span className="icon mr-2">
                      <img src={Fee} alt={pad.landing_fees} />
                    </span>
                    <span className="text">
                      $ - {'$'.repeat(pad.landing_fees) || '$'}
                    </span>
                  </span>
                </Col>
              </Row>
            </div>
            <p className="pad-main_description">
              {getStringRedaction(pad?.site_information)}
            </p>
            {/* survey button on mYSites */}
            {/* <PadElementSurvey pad={pad} /> */}
          </div>
        </Col>
      </Row>
    </div>
  );

  const padNotification = (e) => {
    // const setNotification = (content) =>
    //   notification.error({
    //     duration: 10,
    //     message: 'Error',
    //     description: content,
    //   });

    // // TO DO REMOVE THIS
    // if (padStatus === PAD_BOTH_RESTRICTION) {
    //   e.preventDefault();

    //   return setNotification(
    //     'You cannot view this pad currently as your ownership claim is being verified',
    //   );
    // }

    return null;
  };

  return (
    <div className='pad-list__item'>
      <Link
        to={`/my-sites/site/${pad?.id}`}
        onClick={padNotification}
      >
        {getProcessedPad()}
      </Link>
    </div>
  );
};

PadElement.defaultProps = {
  pad: {},
};

PadElement.propTypes = {
  pad: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    site_information: PropTypes.string,
    status_id: PropTypes.number,
    category_id: PropTypes.number,
    rating: PropTypes.number,
    landing_fees: PropTypes.number,
    comments_count: PropTypes.number,
    uploads: PropTypes.array,
    is_claim_approved: PropTypes.bool,
    is_valid: PropTypes.bool,
    is_landing_permitted: PropTypes.bool,
  }),
};

export default PadElement;