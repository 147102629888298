import { UserOutlined } from '@ant-design/icons';
import { useCallback, useState } from 'react';

import Check from '../../assets/icons/check-circle-white.svg';
import Button from '../../uiKitComponents/button';
import Form from '../../uiKitComponents/form';
import Input from '../../uiKitComponents/input';
import notification from '../../uiKitComponents/notification';
import api from '../../utils/appApi';
import getApiErrorMessages from '../../utils/getApiErrorMessages';

const ForgotPassPage = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const sendEmailHandler = useCallback((values) => {
    setLoading(true);
    api.user
      .forgotPassword(encodeURIComponent(values?.email))
      .then(() => {
        setSuccess(true);
        localStorage.removeItem('token');
        localStorage.removeItem('isAuthorized');
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (success) {
    return (
      <div className="forgot-pass-page">
        <div className="forgot-pass-content success">
          <div className="success-image">
            <img src={Check} alt="Success" />
          </div>
          <h1 className="v1-h1 success-title">Reset code emailed.</h1>
          <p className="success-descrp">
            Please check your email for instructions on how to reset your
            password.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="forgot-pass-page">
      <div className="forgot-pass-content">
        <div className="forgot-blurb">
          Forgot your password? Enter your email address and we&apos;ll email
          you a link you can use to reset your password.
        </div>
        <Form onFinish={sendEmailHandler} layout="vertical">
          <Form.Item
            name="email"
            rules={[{ required: true }, { type: 'email' }]}
          >
            <Input
              size="large"
              prefix={<UserOutlined />}
              placeholder="Enter your email"
              autoComplete="email"
            />
          </Form.Item>
          <Button.Group>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Email Reset Code
            </Button>
            <Button type="text" href="/login" style={{ color: '#ffffff' }}>
              Or, try logging in again.
            </Button>
          </Button.Group>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassPage;
