export const contactTypes = {
  1: 'Pad Contact',
  4: 'Owner',
};

export const getAvailablePrimaryContacts = (contacts = []) => {
  if (contacts.length) {
    const availableList = contacts
      .filter((user) => [1, 4].includes(user.contact_type_id))
      .filter((user) => user.email || user.phone);

    return availableList.map((user) => ({
      value: user.id,
      label: `${user.name || user.email || user.phone} (${contactTypes[user.contact_type_id]}) ${user?.email ? `- ${user?.email}` : ``}`,
    }));
  }

  return [];
};

export const getPrimaryContact = (contacts = []) => {
  return (
    contacts
      .filter((user) => [1, 4].includes(user.contact_type_id))
      .find((c) => Boolean(c.is_primary)) || {}
  );
};
