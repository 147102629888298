import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import rootReducer from './reducers/rootReducer';

const storeCreation = () => {
  const configureStore = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk)),
  );

  // Configure redux for hot-reload development
  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducers/rootReducer', () => {
        configureStore.replaceReducer(rootReducer);
      });
    }
  }
  return configureStore;
};

const store = storeCreation();

export default store;
