import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Banner from '../../components/Banner';
import Footer from '../../components/footer/footer';
import HeaderL1 from '../../components/header-l1';
import LoadingBar from '../../components/loadingBar';
import Router from '../../router';
import ScrollToTop from '../../utils/scrollToTop';

const MainLayout = (props) => {
  const { routes } = props;
  const { part1, part2 } = useParams();
  const [isSticky, setIsSticky] = useState(false);

  const storageBanner = JSON.parse(localStorage.getItem("banner"));
  const [showBanner, setShowBanner] = useState(storageBanner);

  useScrollPosition(
    ({ currPos }) => {
      setIsSticky(currPos.y < -10);
    },
    [isSticky],
  );

  const { loadingBarEnabled } = useSelector((store) => ({
    loadingBarEnabled: store.loadingBar.enabled,
  }));

  const getFooter = () => {
    if (!['registration-flow', 'my-account', ''].includes(part1)) {
      if (!['survey', ''].includes(part2)) {
        return <Footer />;
      }
    }

    return null;
  };

  return (
    <div>
      <ScrollToTop />

      {loadingBarEnabled && <LoadingBar className="loadingBar" />}

      <header>
        <HeaderL1 />
        <Banner showBanner={showBanner} setShowBanner={setShowBanner} />
      </header>

      <article>{<Router routes={routes} />}</article>

      {getFooter()}
    </div>
  );
};

MainLayout.defaultProps = {
  routes: [],
};

MainLayout.propTypes = {
  routes: PropTypes.array,
};

export default MainLayout;
