import { combineReducers } from 'redux';

import banner from './bannerReducer';
import bootstrap from './bootstrapReducer';
import loadingBar from './loadingBarReducer';
import padLandings from './padLandingsReducer';
import padQuickAccess from './padQuickAccessReducer';
import padList from './padsReducer';
import registrationFlow from './registrationFlowReducer';
import survey from './surveyReducer';
import user from './userReducer';

const rootReducer = combineReducers({
  bootstrap,
  user,
  registrationFlow,
  loadingBar,
  padList,
  padQuickAccess,
  survey,
  banner,
  padLandings,
});

export default rootReducer;
