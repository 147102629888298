import {
  BANNER_SHOW,
  GET_USER,
  LOGIN,
  LOGOUT,
  UPDATE_USER,
  UPDATE_USER_LOCAL,
} from '../actionTypes';

const initialState = {
  isAuthorized: false,
  token: '',
  banner: false,
  payload: {},
};

const user = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuthorized: true,
        token: action.payload.token || '',
        banner: JSON.parse(localStorage.getItem('banner')),
        carousel: JSON.parse(localStorage.getItem('carousel')),
        payload: {
          ...state.payload,
          ...action.payload.user,
        },
      };
    case LOGOUT:
      return initialState;
    case GET_USER:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.payload,
        },
      };
    case UPDATE_USER:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.payload,
        },
      };
    case UPDATE_USER_LOCAL:
      return {
        ...state,
        payload: {
          ...state.payload,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default user;
