import { Spin } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';

import PadForm from '../../../../components/padForm';
import { updateLocalUser } from '../../../../store/actions/userAction';
import api from '../../../../utils/appApi';
import RegistrationFlowContext from '../../RegistrationFlow.context';
import PadDetailsSearch from './PadDetailsSearch';

const states = {
  create: 'create/update',
  search: 'search',
};

export function PadDetailsStep() {
  const {
    redirectToStep,
    updateFlowActiveIndex,
    registrationFlowState,
  } = React.useContext(RegistrationFlowContext);
  const { user } = useSelector((state) => ({
    user: state?.user?.payload,
  }));
  const [stepState, setStepState] = React.useState(null);
  const [padToClaim, setPadToClaim] = React.useState(
    registrationFlowState.pad_to_claim || {},
  );
  const [padToUpdate, setPadToUpdate] = React.useState({});
  const dispatch = useDispatch();

  useMount(() => {
    if (user.id) {
      api.user.storeRegistrationProgress(user.id, 2);
    }
    updateFlowActiveIndex('pad_details');
  });

  const previousActionHandler = React.useCallback(() => {
    if (padToUpdate?.id) redirectToStep('role');
    else setStepState(states.search);
  }, [padToUpdate, redirectToStep]);

  const nextActionHandler = React.useCallback(
    async (pad = null) => {
      let termsStepState = {};

      if (padToClaim?.id) termsStepState = padToClaim;

      if (pad?.id) {
        api.pad.claim(pad?.id);
      }

      await dispatch(updateLocalUser({ owned_pad_id: pad?.id || pad }));

      redirectToStep('terms', termsStepState);
    },
    [dispatch, padToClaim, redirectToStep],
  );

  React.useEffect(() => {
    if (user?.owned_pad_id) {
      api.pad
        .get(user?.owned_pad_id)
        .then((res) => {
          setPadToUpdate(res.data.data);
          setStepState(states.create);
        })
        .catch(() => setStepState(states.search));
    } else {
      setStepState(states.search);
    }
  }, [user]);

  if (stepState === states.search) {
    return (
      <PadDetailsSearch
        padToClaim={padToClaim}
        setPadToClaim={setPadToClaim}
        onCallCreate={() => setStepState(states.create)}
        nextActionHandler={nextActionHandler}
      />
    );
  }

  if (stepState === states.create) {
    return (
      <PadForm
        pad={padToUpdate}
        nextAction={nextActionHandler}
        previousAction={previousActionHandler}
        saveButtonText={padToUpdate?.id ? 'Save Changes' : 'Create'}
        cancelButtonText={padToUpdate?.id ? 'Previous' : 'Cancel'}
        onboarding
      />
    );
  }

  return (
    <div className="personal-details d-flex flex-column align-item-center">
      <div className="personal-details_inf mb-3 pt-4 d-flex justify-content-center align-items-center">
        <Spin spinning />
      </div>
      <h4 className="text-center pb-3 mt-1">Loading...</h4>
    </div>
  );
}
