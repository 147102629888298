import {
  IS_UPDATE_DATA,
  PAD_LANDINGS_COUNTER,
  PAD_LANDINGS_LIST,
  PAD_LANDINGS_TOGGLE,
  UPLOAD_PAD_LANDINGS_LIST,
  UPDATE_PAD_ELEMENT_LIST,
  SURVEY_STATUS,
} from '../actionTypes';

const initialState = {
  payload: {
    counter: 0,
    toggle: false,
    isUpdateData: false,
    landingList: [],
    surveyStatus: null,
  },
};

const padLandings = (state = initialState, action = {}) => {
  switch (action.type) {
    case PAD_LANDINGS_COUNTER:
      return {
        ...state,
        payload: { ...state.payload, counter: action.payload },
      };
    case PAD_LANDINGS_TOGGLE:
      return {
        ...state,
        payload: { ...state.payload, toggle: action.payload },
      };
    case SURVEY_STATUS:
      return {
        ...state,
        payload: { ...state.payload, surveyStatus: action.payload },
      };
    case IS_UPDATE_DATA:
      return {
        ...state,
        payload: { ...state.payload, isUpdateData: action.payload },
      };
    case PAD_LANDINGS_LIST:
      return {
        ...state,
        payload: { ...state.payload, landingList: action.payload },
      };
    case UPLOAD_PAD_LANDINGS_LIST:
      return {
        ...state,
        payload: {
          ...state.payload,
          landingList: [...state.payload.landingList, ...action.payload],
        },
      };
    case UPDATE_PAD_ELEMENT_LIST:
      return {
        ...state,
        payload: {
          ...state.payload,
          landingList: [
            ...state.payload.landingList.map((item) => {
              if (item.id === action.payload.id) {
                return action.payload;
              }
              return item;
            }),
          ],
        },
      };
    default:
      return state;
  }
};

export default padLandings;
