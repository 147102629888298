import { LockOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Check from '../../assets/icons/check-circle-white.svg';
import Close from '../../assets/icons/close.svg';
import useQuery from '../../hooks/useQuery';
import { logoutWithoutRedirect } from '../../store/actions/userAction';
import Button from '../../uiKitComponents/button';
import Form from '../../uiKitComponents/form';
import Input from '../../uiKitComponents/input';
import notification from '../../uiKitComponents/notification';
import api from '../../utils/appApi';
import getApiErrorMessages from '../../utils/getApiErrorMessages';

const ResetPassPage = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const queryParams = useQuery();
  const [userRole, setUserRole] = useState(3);
  const { isAuthorized } = useSelector((store) => ({
    isAuthorized: store.user?.isAuthorized,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthorized) {
      logoutWithoutRedirect()(dispatch);
    }
  }, [isAuthorized, dispatch]);

  useEffect(() => {
    if (queryParams.has('role')) {
      setUserRole(parseInt(queryParams.get('role'), 10));
    }
  }, [queryParams]);

  const resetPassHandler = (values) => {
    setLoading(true);
    const data = {
      ...values,
      email: queryParams.get('email').replace(' ', '+'),
      token: queryParams.get('token'),
    };

    api.user
      .resetPassword(data)
      .then((res) => {
        setSuccess(true);
      })
      .catch((err) => {
        setError(true);
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(err),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!queryParams.get('token') || error) {
    return (
      <div className="forgot-pass-page">
        <div className="forgot-pass-content success">
          <div className="content-image error">
            <img src={Close} alt="Error" />
          </div>
          <h1 className="v1-h1 form-title">Invalid password reset link!</h1>
          <p className="success-descrp">
            This link is no longer valid. Please click the reset password link
            on a device with the <span className="mark">Helipaddy App</span>{' '}
            installed!
          </p>
          {userRole !== 2 && (
            <Button size="large" type="primary" className="mt-5">
              <Link to="/forgot-password">Reset Password</Link>
            </Button>
          )}
        </div>
      </div>
    );
  }

  if (success) {
    return (
      <div className="forgot-pass-page">
        <div className="forgot-pass-content success">
          <div className="success-image">
            <img src={Check} alt="Success" />
          </div>
          <h1 className="v1-h1 success-title">Successful password reset!</h1>
          <p className="success-descrp">
            You can now use your new password to log in in to your account!
          </p>
          {userRole !== 2 && (
            <Button size="large" type="primary" className="mt-5">
              <Link to="/login">Login</Link>
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="forgot-pass-page">
      <div className="forgot-pass-content">
        <h1 className="v1-h1 form-title">Create new password.</h1>
        <Form onFinish={resetPassHandler} layout="vertical">
          <Form.Item
            name="password"
            rules={[
              { required: true },
              {
                min: 8,
                message: 'Min 8 characters',
              },
            ]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder="Password"
              autoComplete="new-password"
            />
          </Form.Item>

          <Form.Item
            name="password_confirmation"
            dependencies={['password']}
            rules={[
              {
                required: true,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!',
                    ),
                  );
                },
              }),
            ]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder="Confirm Password"
              autoComplete="new-password"
            />
          </Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Reset Password
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassPage;
