import LandmarkIcon from '../assets/icons/categories/aerial_landmark.svg';
import Aerodrome from '../assets/icons/categories/aerodrome.svg';
import EatIcon from '../assets/icons/categories/eat.svg';
import LandingIcon from '../assets/icons/categories/landing_sites.svg';
import StayIcon from '../assets/icons/categories/stay.svg';

const getPadIcon = (cat_id, pad_name) => {
  switch (cat_id) {
    case 1:
      return (
        <span role="img" className="icon">
          <img src={LandmarkIcon} alt={pad_name} />
        </span>
      );
    case 2:
      return (
        <span role="img" className="icon">
          <img src={StayIcon} alt={pad_name} />
        </span>
      );
    case 3:
      return (
        <span role="img" className="icon">
          <img src={LandingIcon} alt={pad_name} />
        </span>
      );
    case 4:
      return (
        <span role="img" className="icon">
          <img src={StayIcon} alt={pad_name} />
        </span>
      );
    case 5:
      return (
        <span role="img" className="icon">
          <img src={EatIcon} alt={pad_name} />
        </span>
      );
    case 6:
      return (
        <span role="img" className="icon">
          <img src={EatIcon} alt={pad_name} />
        </span>
      );
    case 7:
      return (
        <span role="img" className="icon">
          <img src={Aerodrome} alt={pad_name} />
        </span>
      );
    default:
      return false;
  }
}

export default getPadIcon;