import api from '../../utils/appApi';
import {
    BANNER_SHOW,
} from '../actionTypes';

export const getBanner = () => (dispatch) =>
    api.surveyBanner
        .getBanner()
        .then((res) => {
            dispatch({
                type: BANNER_SHOW,
                payload: res.data,
            });
            return Promise.resolve(res);
        })
        .catch((err) => Promise.reject(err));

export const setBanner = (data) => (dispatch) =>
    new Promise((resolve, reject) => {
        try {
            dispatch({ 
                type: BANNER_SHOW, 
                payload: { ...data } 
            });
            return resolve();
        } catch (err) {
            return reject(err);
        }
    });