import { Button, Col, Row, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import CarouselBanner from '../../components/CarouselBanner';
import MySurveys from '../../components/MySurveys';
import SectionTitle from '../../components/sectionTitle';
import SurveysTooltip from '../../components/SurveysTooltip';
import { getBanner } from '../../store/actions/bannerAction';
import { surveyCounterNew } from '../../store/actions/surveyAction';
import api from '../../utils/appApi';
import history from '../../utils/history';

const SurveysPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [surveyList, setSurveyList] = useState([]);
  const [showCarousel, setShowCarousel] = useState(
    () => JSON.parse(localStorage.getItem('carousel')) || '',
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [showMoreBtnStatus, setShowMoreBtnStatus] = useState(true);
  const [showMoreLoading, setShowMoreLoading] = useState(false);

  const reviewsShowMoreHandler = () => {
    setCurrentPage((prevState) => prevState + 1);
    setShowMoreLoading(true);
  };

  useEffect(() => {
    api.survey
      .getAll({ page: currentPage })
      .then((res) => {
        surveyCounterNew(res.data.survey_count)(dispatch);
        if (currentPage === 1) {
          setSurveyList(res.data.data);
        } else if (currentPage > 1) {
          setSurveyList((prevState) => [...prevState, ...res.data.data]);
        }
        if (res.data.data.length < res.data.meta.per_page)
          setShowMoreBtnStatus(false);
        if (res.data.data.length >= res.data.meta.per_page)
          setShowMoreBtnStatus(true);
        if (res.data.meta.last_page <= 1)
          setShowMoreBtnStatus(false);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [dispatch, currentPage, showMoreBtnStatus]);

  useEffect(() => {
    getBanner()(dispatch);
  }, [dispatch]);

  return (
    <div className="surveys-page">
      <div className="large-container">
        {loading && (
          <Space size="large" className="mySurveys-space">
            <Spin size="large" />
          </Space>
        )}
        {!loading && surveyList.length === 0 && (
          <>
            <SectionTitle>
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="v1-h mb-0">My Surveys</h1>
                <SurveysTooltip />
              </div>
              <Button
                className="responsive-main-btn"
                type="primary"
                onClick={() => history.push('/my-sites/manage-new-pad')}
              >
                Manage a New Site
              </Button>
            </SectionTitle>
            <CarouselBanner />
          </>
        )}
        {!loading && surveyList.length > 0 && (
          <>
            {showCarousel && (
              <CarouselBanner
                type="carousel"
                showCarousel={showCarousel}
                setShowCarousel={setShowCarousel}
              />
            )}
            <SectionTitle>
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="v1-h mb-0">My Surveys</h1>
                <SurveysTooltip />
              </div>
            </SectionTitle>
            <div>
              <MySurveys
                surveyList={surveyList}
                setSurveyList={setSurveyList}
                loading={loading}
                setLoading={setLoading}
              />
              {showMoreBtnStatus && (
                <Row>
                  <Col span={24} className="text-center">
                    <Button
                      className="outline-btn"
                      onClick={reviewsShowMoreHandler}
                      loading={showMoreLoading}
                    >
                      Show More
                    </Button>
                  </Col>
                </Row>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SurveysPage;
