import {
  QUICK_ACCESS_IS_SURVEY_SUBMIT,
  QUICK_ACCESS_SET_PAD,
  QUICK_ACCESS_SET_REVIEWS,
  QUICK_ACCESS_TRIGGER_SURVEY_UPDATE,
} from '../actionTypes';

const initialState = {
  payload: {
    pad: {},
    reviews: {},
    isUpdate: false,
  },
};

const padQuickAccess = (state = initialState, action = {}) => {
  switch (action.type) {
    case QUICK_ACCESS_SET_PAD:
      return {
        ...state,
        payload: { ...state.payload, pad: action.payload || {} },
      };
    case QUICK_ACCESS_SET_REVIEWS:
      return {
        ...state,
        payload: { ...state.payload, reviews: action.payload || {} },
      };
    case QUICK_ACCESS_IS_SURVEY_SUBMIT:
      return {
        ...state,
        payload: { ...state.payload, isSurveyUpdate: action.payload || {} },
      };
    case QUICK_ACCESS_TRIGGER_SURVEY_UPDATE:
      return {
        ...state,
        payload: { ...state.payload, isUpdate: action.payload || false },
      };
    default:
      return state;
  }
};

export default padQuickAccess;
