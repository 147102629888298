import PropTypes from 'prop-types';
import React from 'react';

import condStrings from '../../../../utils/condStrings';
import { SurveyContext } from '../../Survey';
import { SurveyStepsBar } from '../SurveyStepsBar';

export function SurveyStepLayout({ children }) {
  const { changeSurveyStep, surveyData, activeSurveyStep } = React.useContext(
    SurveyContext,
  );

  return (
    <div className="survey-step">
      <div className="survey-step__header">
        <h5 className="survey-step__header-title">
          Helipaddy 360 Survey | <strong>{surveyData.general.name}</strong>
        </h5>
        <SurveyStepsBar type="navigation" onNavItemClick={changeSurveyStep} />
      </div>
      <div className={condStrings('survey-step__content', activeSurveyStep)}>
        {children}
      </div>
    </div>
  );
}

SurveyStepLayout.propTypes = {
  children: PropTypes.any,
};
