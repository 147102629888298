import axiosInstance from './apiConfig';

const api = {
  user: {
    login: (data) => axiosInstance.post('/auth/login', data),
    loginViaDriver: (token) =>
      axiosInstance.get(`/auth/callback?token=${token}&driver=google`),
    logout: () => axiosInstance.post('/auth/logout'),
    register: (data) => axiosInstance.post('/auth/register', data),
    get: () => axiosInstance.get('/auth/user'),
    activateEmail: (config) =>
      axiosInstance.get(`/users/verify-email?token=${config?.token}`),
    notify: () => axiosInstance.get('/users/notify-user'),
    completeRegistration: (data) =>
      axiosInstance.post('/auth/complete-registration', data),
    getPads: (page) => axiosInstance.get(`/users/get-pads?page=${page}`),
    update: (data) => axiosInstance.put('/users', data),
    updatePassword: (data) => axiosInstance.put('/users/update-password', data),
    forgotPassword: (email) =>
      axiosInstance.post(`/password/email?email=${email}`),
    resetPassword: (data) => axiosInstance.post('/password/reset', data),
    storeRegistrationProgress: (userId, step) =>
      axiosInstance.put(
        `/auth/store-registration-progress/${userId}?device=web&step=${step}`,
      ),
    addFreePremium: (email) =>
      axiosInstance.post(`/users/add-free-premium?email=${email}`),
  },
  pad: {
    get: (padId, config) => axiosInstance.get(`/pads/${padId}`, config),
    search: (keyword) =>
      axiosInstance.get(`/pads/search-pad?keyword=${keyword}`),
    getLandingFees: (config) => axiosInstance.get('/pads/landing-fees', config),
    store: (data) => axiosInstance.post('/pads', data),
    update: (padId, data) => axiosInstance.post(`/pads/${padId}`, data),
    displayLandingAdvice: (padId, data) =>
      axiosInstance.put(`/pads/display-landing-advice/${padId}`, {
        is_landing_advice_displayed: data,
      }),
    claim: (padId) => axiosInstance.get(`/pads/claim/${padId}`),
    uploadsOrder: (data) =>
      axiosInstance.post('/uploads/add-upload-order', data),
    removeOwner: (padId) =>
      axiosInstance.delete(`/pads/destroy-owner-contact/${padId}`),
    delete: (padId, reason) =>
      axiosInstance.get(`/pads/remove-pad-email/${padId}?reason=${reason}`),
    changePrimaryContact: (padId, contactId, contactTypeId) =>
      axiosInstance.put(
        `/pads/set-primary-contact/${padId}/${contactId}/${contactTypeId}`,
      ),
    getRrequestHiscCode: (padId, hisc) =>
      axiosInstance.get(`/pads/request-hisc-code/${padId}?hisc=${hisc}`),
  },
  upload: {
    store: (entity, id, data, config) =>
      axiosInstance.post(`/uploads/${entity}/${id}`, data, config),
    delete: (fileId) => axiosInstance.delete(`/uploads/${fileId}`),
    report: (fileId) => axiosInstance.post(`/upload-report/${fileId}`),
  },
  category: {
    getList: (config) => axiosInstance.get('/categories', config),
  },
  warning: {
    getList: (config) => axiosInstance.get('/warnings', config),
  },
  facility: {
    getList: (config) => axiosInstance.get('/facilities', config),
  },
  tag: {
    getList: (config) => axiosInstance.get('/tags', config),
  },
  reviews: {
    get: (padId, { direction, page }) =>
      axiosInstance.get(
        `/reviews/${padId}?sort_direction=${direction}&page=${page}`,
      ),
    report: (reviewId, data) =>
      axiosInstance.post(`/review-reports/add-report-review/${reviewId}`, data),
  },
  stripe: {
    subscription: () => axiosInstance.get('/stripe/is-subscribed'),
    checkoutSession: () => axiosInstance.get('/stripe/checkout-session'),
    billingPortal: () => axiosInstance.get('/stripe/billing-portal'),
  },
  survey: {
    get: (surveyId) => axiosInstance.get(`/surveys/${surveyId}`),
    getAll: ({ page }) => axiosInstance.get(`/surveys?page=${page}`),
    update: (surveyId, data) => axiosInstance.put(`/surveys/${surveyId}`, data),
    forcedSave: (padId, steps) =>
      axiosInstance.post(`/surveys/${padId}`, steps),
    updateSurveyStatus: (padId, status) =>
      axiosInstance.post(`/surveys/${padId}`, { status }),
    downloadPDFData: (surveyId) =>
      axiosInstance.get(`/surveys/download-pdf-data/${surveyId}`),
  },
  region: {
    getCountries: (config) => axiosInstance.get('/regions/countries', config),
    getUSStates: (config) => axiosInstance.get('/regions/us-states', config),
  },
  surveyBanner: {
    getBanner: (config) => axiosInstance.get('/survey-banner', config),
  },
  qrCode: {
    getQRCode: (id) => axiosInstance.get(`/surveys/get-access-token/${id}`),
    getQRCodeReset: (id) =>
      axiosInstance.get(`/surveys/get-access-token/${id}?reset`),
  },
  landings: {
    get: (padId, { page }) =>
      axiosInstance.get(`/pads/user-landings/${padId}?page=${page}`),
    setToggle: (padId) => axiosInstance.put(`/pads/enable-landings/${padId}`),
    landingsAprove: ({ landing, type, data }) =>
      axiosInstance.put(`/pads/landings/${landing}/${type}`, data),
  },
};

export default api;
