import React from 'react';
import { useDebounce, useUnmount } from 'react-use';

import { SurveyContext } from '../Survey';

/**
 * @param {string} stepName
 * @returns {[onStepValuesChange]}
 */
export default function useStepUpdater(stepName) {
  const { updateSurveyData, updateSurveyDataLocally } = React.useContext(
    SurveyContext,
  );

  const [payload, setPayload] = React.useState({});

  const onStepValuesChange = React.useCallback(
    /**
     * @param {object} changedValues
     * @param {{immediately?: boolean}} [args]
     */
    (changedValues, args = {}) => {
      const { immediately = false } = args;

      const generatedPayload = {
        step: stepName,
        values: changedValues,
      };

      if (immediately) {
        updateSurveyData(generatedPayload);
        updateSurveyDataLocally(generatedPayload);
      } else {
        setPayload(generatedPayload);
      }
    },
    [stepName, updateSurveyData, updateSurveyDataLocally],
  );

  const syncPayloadChanges = () => {
    if (payload.step) {
      updateSurveyData(payload);
      updateSurveyDataLocally(payload);
      setPayload({});
    }
  };

  useDebounce(syncPayloadChanges, 500, [payload]);

  useUnmount(syncPayloadChanges);

  return [onStepValuesChange];
}
