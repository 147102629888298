import { notification } from 'antd';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import QRCodeRedirect from '../../../../components/QRCodeRedirect';
import validateFile from '../../../../utils/validateFile';
import useStepUpdater from '../../hooks/useStepUpdater';
import { SurveyContext } from '../../Survey';
import {
  HAZARDS_STEP,
  LANDING_STEP,
  PAD_VIEW_STEP,
} from '../../Survey.context';
import { SurveyStepActions } from '../SurveyStepActions';
import {
  checkPadViewCompleted,
  removeHandler,
  uploadHandler,
} from './SurveyPadView.utils';
import UploadCard from './UploadCard';

const acceptedFiles = '.jpg,.png,.jpeg';

export function SurveyPadViewStep() {
  const { surveyId } = useParams();

  const { uploadCounter } = useSelector((state) => ({
    uploadCounter: state.banner.upload_count,
  }));

  const { surveyData, changeSurveyStep } = React.useContext(SurveyContext);

  const [isUploading, setIsUplaoding] = React.useState(false);
  const [uploadingSideKey, setUploadingSideKey] = React.useState('');
  const [deletingSideKey, setDeletingSideKey] = React.useState('');

  const [onStepValuesChange] = useStepUpdater(PAD_VIEW_STEP);

  const inputFileRef = React.useRef();

  const openSelectFilesDialog = (sideKey) => () => {
    setUploadingSideKey(sideKey);
    inputFileRef.current.click();
  };

  const onFileInputChange = async (event) => {
    const file = event.target.files[0];

    if (file && surveyId) {
      const isFileInvalid = validateFile(file, {
        maxSize: null,
        accept: acceptedFiles,
      });

      if (!isFileInvalid) {
        setIsUplaoding(true);
        try {
          const upload = await uploadHandler(
            surveyId,
            file,
            uploadingSideKey.replace('view_', ''),
          );

          const view_is_completed = checkPadViewCompleted({
            ...surveyData.view,
            [uploadingSideKey]: {
              upload,
            },
          });

          onStepValuesChange(
            {
              view_is_completed,
              [uploadingSideKey]: {
                upload,
              },
            },
            { immediately: true },
          );

          if (uploadCounter === 3 && isMobile) {
            notification.warning({
              message: 'Warning',
              description: "Thanks for adding your photos! If you're completing the Survey via web, you'll need to refresh the tab before you'll see them",
            });
          };

        } catch (error) {
          //
        } finally {
          setIsUplaoding(false);
        }
      } else if (isFileInvalid.error_code.ext_not_allowed) {
        notification.error({
          message: 'Error',
          description: 'This extension is not supported',
        });
      }
    }

    inputFileRef.current.value = '';
  };

  const removeFileHandler = (sideKey) => async () => {
    setDeletingSideKey(sideKey);
    try {
      await removeHandler(surveyData.view[sideKey]?.upload?.id);
      onStepValuesChange(
        {
          view_is_completed: false,
          [sideKey]: {
            upload: null,
          },
        },
        { immediately: true },
      );
    } catch (error) {
      //
    }
    setDeletingSideKey(null);
  };

  return (
    <>
      <div className="survey-step__description">
        <p className="description-primary">
          This highly requested feature is gold dust for pilots, giving them a
          ground view of your landing site and it&apos;s obstacles. Please
          capture four images whilst standing in the centre of the site, one in
          the direction of each cardinal point.
        </p>
        {/* <p className="description-primary">
          Don&apos;t know your North? Ask your phone! (e.g.Compass app)
        </p> */}
        <p className="description-secondary">
          You can now do this part directly from your smartphone! Follow the QR code below or sign in at app.helipaddy.com
        </p>
        <p className="description-secondary">
          <QRCodeRedirect
            id={surveyId}
          />
        </p>
      </div>
      <div className="survey-pad-view__uploads">
        <UploadCard
          sideKey="view_north"
          title="North"
          comment={surveyData.view.view_north.comment}
          upload={surveyData.view.view_north.upload}
          uploadFile={openSelectFilesDialog('view_north')}
          removeFile={removeFileHandler('view_north')}
          isUploading={isUploading && uploadingSideKey === 'view_north'}
          isRemoving={deletingSideKey === 'view_north'}
        />
        <UploadCard
          sideKey="view_east"
          title="East"
          comment={surveyData.view.view_east.comment}
          upload={surveyData.view.view_east.upload}
          uploadFile={openSelectFilesDialog('view_east')}
          removeFile={removeFileHandler('view_east')}
          isUploading={isUploading && uploadingSideKey === 'view_east'}
          isRemoving={deletingSideKey === 'view_east'}
        />

        <UploadCard
          sideKey="view_south"
          title="South"
          comment={surveyData.view.view_south.comment}
          upload={surveyData.view.view_south.upload}
          uploadFile={openSelectFilesDialog('view_south')}
          removeFile={removeFileHandler('view_south')}
          isUploading={isUploading && uploadingSideKey === 'view_south'}
          isRemoving={deletingSideKey === 'view_south'}
        />

        <UploadCard
          sideKey="view_west"
          title="West"
          comment={surveyData.view.view_west.comment}
          upload={surveyData.view.view_west.upload}
          uploadFile={openSelectFilesDialog('view_west')}
          removeFile={removeFileHandler('view_west')}
          isUploading={isUploading && uploadingSideKey === 'view_west'}
          isRemoving={deletingSideKey === 'view_west'}
        />
      </div>
      <SurveyStepActions
        backClick={changeSurveyStep(LANDING_STEP)}
        skipClick={changeSurveyStep(HAZARDS_STEP)}
        nextClick={changeSurveyStep(HAZARDS_STEP)}
      />
      <input
        ref={inputFileRef}
        type="file"
        accept={acceptedFiles}
        className="d-none"
        onChange={onFileInputChange}
      />
    </>
  );
}
