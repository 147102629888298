import {
  GlobalOutlined,
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import iconGoogle from '../../assets/icons/Google_G_Logo.svg';
import PhoneInput from '../../components/phoneInput';
import SectionTitle from '../../components/sectionTitle';
import useApiErrorsWithAntd from '../../hooks/useApiErrorsWithAntd';
import { updateUser } from '../../store/actions/userAction';
import {
  selectCountriesOptions,
  selectUSStatesOptions,
} from '../../store/selectors/bootstrapSelector';
import Button from '../../uiKitComponents/button';
import Checkbox from '../../uiKitComponents/checkbox';
import Col from '../../uiKitComponents/col';
import Divider from '../../uiKitComponents/divider';
import Form from '../../uiKitComponents/form';
import Input from '../../uiKitComponents/input';
import notification from '../../uiKitComponents/notification';
import Select from '../../uiKitComponents/select';
import api from '../../utils/appApi';
import phoneWithPlus from '../../utils/phoneWithPlus';

const MyAccountPage = () => {
  const [personalDetailsStatus, setPersonalDetailsStatus] = useState(false);
  const [changePassStatus, setChangePassStatus] = useState(false);
  const [saveButtonStatus, setSaveButtonStatus] = useState(false);
  const [isNewsletterSubscribed, setIsNewsletterSubscribed] = useState(false);
  const [isReviewEmailSubscribed, setIsReviewEmailSubscribed] = useState(false);
  const [isSavingLoad, setIsSavingLoad] = useState(false);
  const [isUSSelected, setIsUSSelected] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { user, countries, USStates } = useSelector((store) => ({
    user: store?.user?.payload,
    countries: selectCountriesOptions(store),
    USStates: selectUSStatesOptions(store),
  }));
  const { setApiErrorsToAntdForm } = useApiErrorsWithAntd(form);

  useEffect(() => {
    form.resetFields(['old_password', 'password', 'password_confirmation']);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePassStatus]);

  useEffect(() => {
    form.resetFields([
      'email',
      'last_name',
      'first_name',
      'phone',
      ['location', 'country_code'],
      ['location', 'state_code'],
    ]);
    if (user.location?.country !== 'US') setIsUSSelected(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personalDetailsStatus]);

  useEffect(() => {
    if (user?.location?.country === 'US') setIsUSSelected(true);
  }, [user]);

  useEffect(() => {
    if (
      personalDetailsStatus ||
      changePassStatus ||
      isNewsletterSubscribed !== user?.settings?.is_newsletter_subscribed ||
      isReviewEmailSubscribed !== user?.settings?.is_review_email_subscribed
    ) {
      setSaveButtonStatus(false);
    } else {
      setSaveButtonStatus(true);
    }
  }, [
    personalDetailsStatus,
    changePassStatus,
    isNewsletterSubscribed,
    isReviewEmailSubscribed,
    user.settings,
  ]);

  useEffect(() => {
    setTimeout(() => {
      setIsNewsletterSubscribed(user.settings.is_newsletter_subscribed);
      setIsReviewEmailSubscribed(user.settings?.is_review_email_subscribed);
    });
  }, [user]);

  const cancelSavingHandler = () => {
    setChangePassStatus(false);
    setPersonalDetailsStatus(false);
    setIsNewsletterSubscribed(user.settings.is_newsletter_subscribed);
    setIsReviewEmailSubscribed(user.settings?.is_review_email_subscribed);
  };

  const fetchErrorsHandler = (data) => {
    setApiErrorsToAntdForm(data?.reason);
  };

  const formSubmitHandler = (values) => {
    setIsSavingLoad(true);

    const data = {
      ...values,
      settings: {
        is_newsletter_subscribed: isNewsletterSubscribed,
        is_review_email_subscribed: isReviewEmailSubscribed,
      },
    };
    const {
      email,
      first_name,
      last_name,
      phone,
      settings,
      old_password,
      password,
      password_confirmation,
      location,
    } = data;

    if (personalDetailsStatus && changePassStatus) {
      Promise.allSettled([
        updateUser({
          email,
          first_name,
          last_name,
          phone: phoneWithPlus(phone),
          settings,
          location,
        })(dispatch),
        api.user.updatePassword({
          old_password,
          password,
          password_confirmation,
        }),
      ]).then(([updatedPersonaData, updatedPass]) => {
        if (updatedPersonaData.status === 'fulfilled') {
          notification.success({
            message: 'Success',
            description: 'Your personal data has been successfully updated!',
          });
        } else {
          fetchErrorsHandler(updatedPersonaData);
        }

        if (updatedPass.status === 'fulfilled') {
          notification.success({
            message: 'Success',
            description: 'Your password has been successfully updated!',
          });
        } else {
          fetchErrorsHandler(updatedPass);
        }

        setIsSavingLoad(false);
        cancelSavingHandler();
      });
    } else if (personalDetailsStatus) {
      updateUser({
        email,
        first_name,
        last_name,
        phone: phoneWithPlus(phone),
        settings,
        location,
      })(dispatch)
        .then(() => {
          notification.success({
            message: 'Success',
            description: 'Your personal data has been successfully updated!',
          });

          cancelSavingHandler();
        })
        .catch((err) => {
          setApiErrorsToAntdForm(err);
        })
        .finally(() => setIsSavingLoad(false));
    } else if (changePassStatus) {
      api.user
        .updatePassword({
          old_password,
          password,
          password_confirmation,
        })
        .then(() => {
          notification.success({
            message: 'Success',
            description: 'Your password has been successfully updated!',
          });

          cancelSavingHandler();
        })
        .catch((err) => {
          setApiErrorsToAntdForm(err);
        })
        .finally(() => setIsSavingLoad(false));
    } else {
      updateUser({
        email,
        first_name,
        last_name,
        phone: phoneWithPlus(phone),
        settings,
        location,
      })(dispatch)
        .then(() => {
          notification.success({
            message: 'Success',
            description: 'Your personal data has been successfully updated!',
          });

          cancelSavingHandler();
        })
        .catch((err) => {
          setApiErrorsToAntdForm(err);
        })
        .finally(() => setIsSavingLoad(false));
    }
  };

  const onCoutryChange = (code) => {
    if (code === 'US') setIsUSSelected(true);
    else setIsUSSelected(false);
  };

  const changePassCotent = useCallback(() => {
    if (user?.logged_in_via === 'email' && changePassStatus) {
      return (
        <div className="my-account_change-pass_inner">
          <Form.Item
            name="old_password"
            rules={[{ required: true, message: 'Required field' }]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder="Old Password"
              autoComplete="new-password"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Required field' },
              {
                min: 8,
                message: 'Min 8 characters',
              },
            ]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder="Type New Password"
              autoComplete="new-password"
            />
          </Form.Item>
          <Form.Item
            name="password_confirmation"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Required field',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!',
                    ),
                  );
                },
              }),
            ]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder="Re-Type New Password"
              autoComplete="new-password"
            />
          </Form.Item>
        </div>
      );
    }

    if (
      !user.logged_in_via ||
      (user?.logged_in_via === 'email' && !changePassStatus)
    ) {
      return (
        <div className="my-account_change-pass_inner">
          <div className="my-account_user-info">
            <span className="icon">
              <LockOutlined />
            </span>
            <span className="text">Type New Password</span>
          </div>
          <div className="my-account_user-info">
            <span className="icon">
              <LockOutlined />
            </span>
            <span className="text">Re-Type New Password</span>
          </div>
        </div>
      );
    }

    return (
      <div className="my-account_change-pass_inner">
        <Button
          type="primary"
          className="my-accout-google"
          disabled
          icon={<img className="mr-3" src={iconGoogle} alt="google" />}
        >
          Logged in with Google
        </Button>
      </div>
    );
  }, [changePassStatus, user]);

  return (
    <div className="my-account">
      <div className="large-container">
        <SectionTitle>
          <h1 className="v1-h1">My account</h1>
        </SectionTitle>
        <div className="my-account_content">
          <Form form={form} onFinish={formSubmitHandler}>
            <Row gutter={10}>
              <Col 
                span={12}
                md={12}
                sm={24}
                xs={24}
              >
                <div className="my-account_personal-details">
                  <div className="my-account_row-head">
                    <h2 className="v1-h2">Personal Details</h2>
                    <Button
                      type="link"
                      onClick={() =>
                        setPersonalDetailsStatus((prevState) => !prevState)
                      }
                    >
                      {personalDetailsStatus ? 'Cancel' : 'Edit'}
                    </Button>
                  </div>
                  {personalDetailsStatus ? (
                    <Form.Item
                      name="first_name"
                      initialValue={user?.first_name}
                      rules={[{ required: true, message: 'Name is required' }]}
                    >
                      <Input
                        size="large"
                        prefix={<UserOutlined />}
                        placeholder="John"
                      />
                    </Form.Item>
                  ) : (
                    <div className="my-account_user-info">
                      <span className="icon">
                        <UserOutlined />
                      </span>
                      <span className="text">{user.first_name}</span>
                    </div>
                  )}
                  {personalDetailsStatus ? (
                    <Form.Item
                      name="last_name"
                      rules={[
                        { required: true, message: 'Surname is required' },
                      ]}
                      initialValue={user?.last_name}
                    >
                      <Input
                        size="large"
                        prefix={<UserOutlined />}
                        placeholder="Smith"
                      />
                    </Form.Item>
                  ) : (
                    <div className="my-account_user-info">
                      <span className="icon">
                        <UserOutlined />
                      </span>
                      <span className="text">{user.last_name}</span>
                    </div>
                  )}
                  {personalDetailsStatus ? (
                    <Form.Item
                      name="email"
                      initialValue={user?.email}
                      rules={[
                        { required: true, message: 'Email is required' },
                        { type: 'email', message: 'Invalid email' },
                      ]}
                    >
                      <Input
                        size="large"
                        prefix={<MailOutlined />}
                        placeholder="joshsmith@example.com"
                      />
                    </Form.Item>
                  ) : (
                    <div className="my-account_user-info">
                      <span className="icon">
                        <MailOutlined />
                      </span>
                      <span className="text">{user.email}</span>
                    </div>
                  )}
                  {personalDetailsStatus ? (
                    <Form.Item
                      name="phone"
                      fieldKey="phone"
                      initialValue={user?.phone}
                      rules={[{ required: true, message: 'Phone is required' }]}
                    >
                      <PhoneInput
                        placeholder="Phone number"
                        icon={<PhoneOutlined />}
                      />
                    </Form.Item>
                  ) : (
                    <div className="my-account_user-info">
                      <span className="icon">
                        <PhoneOutlined />
                      </span>
                      <span className="text">
                        {(user?.phone && `${user.phone}`) || '-'}
                      </span>
                    </div>
                  )}
                  {personalDetailsStatus ? (
                    <>
                      <Form.Item
                        name={['location', 'country_code']}
                        initialValue={user?.location?.country_code || null}
                        rules={[
                          { required: true, message: 'Please select country' },
                        ]}
                      >
                        <Select
                          placeholder="Country"
                          onSelect={onCoutryChange}
                          optionFilterProp="label"
                          options={countries}
                          showSearch
                        />
                      </Form.Item>
                      {isUSSelected && (
                        <Form.Item
                          name={['location', 'state_code']}
                          initialValue={user?.location?.county || null}
                          rules={[
                            { required: true, message: 'Please select state' },
                          ]}
                        >
                          <Select
                            placeholder="State"
                            options={USStates}
                            optionFilterProp="label"
                          />
                        </Form.Item>
                      )}
                    </>
                  ) : (
                    <div className="my-account_user-info">
                      <span className="icon">
                        <GlobalOutlined />
                      </span>
                      <span className="text">
                        {user?.location?.name || '-'}
                      </span>
                    </div>
                  )}
                </div>
              </Col>

              <Col 
                span={12}
                md={12}
                sm={24}
                xs={24}
              >
                <div className="my-account_change-pass">
                  <div className="my-account_row-head">
                    <h2 className="v1-h2">Change Password</h2>
                    {user?.logged_in_via === 'email' && (
                      <Button
                        type="link"
                        onClick={() =>
                          setChangePassStatus((prevState) => !prevState)
                        }
                      >
                        {changePassStatus ? 'Cancel' : 'Edit'}
                      </Button>
                    )}
                  </div>
                  {changePassCotent()}
                </div>
                <div className="my-account_notification">
                  <div className="my-account_row-head">
                    <h2 className="v1-h2">Email Preferencess</h2>
                  </div>
                  <div className="my-account_user-info">
                    <Checkbox
                      checked={isReviewEmailSubscribed}
                      onChange={(e) =>
                        setIsReviewEmailSubscribed(e.target.checked)
                      }
                    >
                      Email me when someone comments on one of my pads
                    </Checkbox>
                  </div>
                  <div className="my-account_user-info">
                    <Checkbox
                      checked={isNewsletterSubscribed}
                      onChange={(e) =>
                        setIsNewsletterSubscribed(e.target.checked)
                      }
                    >
                      Email me Helipaddy news for site owners
                    </Checkbox>
                  </div>
                </div>
              </Col>
              {/*<Col span={8}>*/}
              {/*  <Subscription*/}
              {/*    created_at={user?.created_at}*/}
              {/*    renewal_date={user?.renewal_date}*/}
              {/*    last_action_at={user?.last_action_at}*/}
              {/*  />*/}
              {/*</Col>*/}
            </Row>
          </Form>

          <Divider />
          <Button
            type="primary"
            onClick={() => form.submit()}
            disabled={saveButtonStatus}
            loading={isSavingLoad}
          >
            Save Changes
          </Button>
          <Button type="link" className="custom" onClick={cancelSavingHandler}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MyAccountPage;
