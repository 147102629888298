import ActivationEmailPage from '../containers/ActivationEmailPage';
import PadCreatePage from '../containers/CreatePadPage';
import ExternalAuthorization from '../containers/ExternalAuthorization';
import ForgotPassPage from '../containers/ForgotPassPage';
import Free3Premium from '../containers/Free3Premium';
import LandingPadPage from '../containers/LandingPadPage';
import LoginPage from '../containers/LoginPage';
import MyAccountPage from '../containers/MyAccountPage';
import MySitesPage from '../containers/MySitesPage';
import PremiumSoSorryPage from '../containers/PremiumSoSorryPage/premiumSoSorryPage';
import RegistrationPage from '../containers/RegistrationPage';
import ResetPassPage from '../containers/ResetPassPage';
import SinglePadPage from '../containers/SinglePadPage';
import SiteEditPage from '../containers/SiteEditPage';
import SiteReviewsPage from '../containers/SiteReviewsPage';
import SoSorryPage from '../containers/SoSorryPage/soSorryPage';
import SurveyQRRedirect from '../containers/SurveyQRRedirect';
import SurveysPage from '../containers/SurveysPage';
import MainLayout from '../layouts/mainLayout';
import { RegistrationFlow, Survey } from '../modules';

const routes = [
  {
    path: '/:part1?/:part2?/:part3?',
    component: MainLayout,
    public: true,
    routes: [
      {
        path: '/login',
        component: LoginPage,
        exact: true,
        private: false,
      },
      {
        path: '/auth',
        component: ExternalAuthorization,
        exact: true,
        private: false,
      },
      {
        path: '/registration',
        component: RegistrationPage,
        exact: true,
        public: true,
      },
      {
        path: '/forgot-password',
        component: ForgotPassPage,
        exact: true,
        public: true,
      },
      {
        path: '/reset-password',
        component: ResetPassPage,
        exact: true,
        public: true,
      },
      {
        path: '/registration-flow/:pad_id?',
        component: RegistrationFlow,
        exact: true,
        public: true,
      },
      {
        path: '/account/activation',
        component: ActivationEmailPage,
        exact: true,
        public: true,
      },
      {
        path: '/so-sorry/:pad_id?',
        component: SoSorryPage,
        exact: true,
        public: true,
      },
      {
        path: '/free-3-premium',
        component: Free3Premium,
        exact: true,
        public: true,
      },
      {
        path: '/ooops',
        component: PremiumSoSorryPage,
        exact: true,
        public: true,
      },
      {
        path: '/my-sites',
        component: MySitesPage,
        exact: true,
        private: true,
      },
      {
        path: '/my-sites/:type',
        component: MySitesPage,
        exact: true,
        private: true,
      },
      {
        path: '/my-sites/site/:pad_id?',
        component: SinglePadPage,
        exact: true,
        private: true,
      },
      {
        path: '/my-sites/landing/:pad_id?',
        component: LandingPadPage,
        exact: true,
        private: true,
      },
      {
        path: '/my-sites/edit/:pad_id?',
        component: SiteEditPage,
        exact: true,
        private: true,
      },
      {
        path: '/my-sites/reviews/:pad_id?',
        component: SiteReviewsPage,
        exact: true,
        private: true,
      },
      {
        path: '/create-pad',
        component: PadCreatePage,
        exact: true,
        private: true,
      },
      {
        path: '/create-pad/:pad_id?',
        component: PadCreatePage,
        exact: true,
        private: true,
      },
      {
        path: '/survey-360',
        component: SurveysPage,
        exact: true,
        private: true,
      },
      {
        path: '/survey-360/survey/:surveyId?',
        component: Survey,
        exact: true,
        private: true,
      },
      {
        path: '/survey-360/survey/:surveyId?/:token?',
        component: SurveyQRRedirect,
        exact: true,
        private: false,
      },
      {
        path: '/my-account',
        component: MyAccountPage,
        exact: true,
        private: true,
      },
      {
        redirectTo: '/my-sites',
      },
    ],
  },
  {
    redirectTo: '/my-sites',
  },
];

export default routes;
