import { ArrowLeftOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../../uiKitComponents/button';

const HeaderL2 = ({ children, backPath }) => {
  const history = useHistory();

  const onBackHandle = useCallback(() => {
    if (backPath) {
      history.push(backPath);
    } else if (history.action !== 'POP') {
      history.goBack();
    }
  }, [history, backPath]);

  return (
    <div className="header-container header-container-l2">
      <div className="large-container">
        <div className="content">
          <div className="default-link">
            <Button
              type="link"
              icon={<ArrowLeftOutlined />}
              onClick={onBackHandle}
              disabled={history.action === 'POP' && !backPath}
            >
              Back
            </Button>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

HeaderL2.defaultProps = {
  children: [],
};

HeaderL2.propTypes = {
  children: PropTypes.array,
  backPath: PropTypes.string,
};

export default HeaderL2;
