import { Checkbox, Col, Form, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectFacilityOptions,
  selectWarningOptions,
} from '../../../../store/selectors/bootstrapSelector';
import { SurveyContext } from '../../Survey';
import { separatedArray } from '../../utils';

export default function FacilitiesStepCategories() {
  const { warnings, facilities } = useSelector((store) => ({
    warnings: selectWarningOptions(store),
    facilities: selectFacilityOptions(store),
  }));
  const { surveyData } = React.useContext(SurveyContext);

  const categories = React.useMemo(() => {
    if (surveyData.general.category_id === 7) {
      return separatedArray(facilities);
    }

    return separatedArray(warnings);
  }, [facilities, surveyData.general.category_id, warnings]);

  return (
    <Form.Item name={['facilities_categories']}>
      <Checkbox.Group className="survey-checkbox-list w-100">
        <h3 className="survey-checkbox-title">
          <span style={{ color: '#ff4d4f', marginRight: 4 }}>*</span>Please
          select all relevant categories
        </h3>
        <Row className="d-flex justify-content-between responsive">
          {categories.map((side) => (
            <Col
              span={12}
              md={12}
              sm={24}
              xs={24}
              key={side.name}
              className="d-flex flex-column"
            >
              {side.values.map((el) => (
                <Checkbox key={el.value} value={el.value}>
                  {el.label}
                </Checkbox>
              ))}
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </Form.Item>
  );
}
