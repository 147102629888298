import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useMount } from 'react-use';

import PadForm from '../../components/padForm';
import { saveEditedPadEventOpts } from '../../events';
import useGaEvent from '../../hooks/useGAEvent';
import { quickAccessSetPad, quickAccessTriggerSurveySubmit } from '../../store/actions/padQuickAccessAction';
import { cleanUserPads, updateSinglePad } from '../../store/actions/padsAction';
import Menu from '../../uiKitComponents/menu';
import notification from '../../uiKitComponents/notification';
import Spin from '../../uiKitComponents/spin';
import api from '../../utils/appApi';

const SiteEditPage = () => {
  const { pad_id } = useParams();
  const [pad, setPad] = useState({ isLoaded: !pad_id, data: {} });
  const history = useHistory();
  const dispatch = useDispatch();
  const { padQuickAccess } = useSelector((store) => ({
    padQuickAccess: store.padQuickAccess?.payload,
  }));

  // GA events
  const saveEditedPadEvent = useGaEvent(saveEditedPadEventOpts);

  const padFormPrevHandler = () => {
    try {
      history.goBack();
    } catch (err) {
      history.push('/my-sites');
    }
  };

  const padFormNextHandler = (_, updatedPad, request = true) => {
    if (updatedPad) {
      updateSinglePad(updatedPad)(dispatch).catch(() =>
        cleanUserPads()(dispatch),
      );
      quickAccessSetPad(updatedPad)(dispatch);
      saveEditedPadEvent();
    }
    quickAccessTriggerSurveySubmit(true)(dispatch);

    history.push(`/my-sites`);

    if (request) {
      notification.success({
        message: 'Your request to update pad was sent successfully!',
      });
    } else {
      notification.success({
        message: 'Site has been successfully updated!',
      });
    }
  };

  useMount(() => {
    if (pad_id)
      if (parseInt(pad_id, 10) === padQuickAccess.pad?.id) {
        setPad((prevState) => ({
          ...prevState,
          isLoaded: true,
          data: padQuickAccess.pad,
        }));
      } else {
        api.pad.get(pad_id).then((res) => {
          setPad((prevState) => ({
            ...prevState,
            isLoaded: true,
            data: res.data.data,
          }));
          quickAccessSetPad(res.data.data)(dispatch);
        });
      }
  });

  const padHeaderMenu = (
    <Menu mode="horizontal" defaultSelectedKeys={['single-site_edit']}>
      <Menu.Item key="single-site_edit">Edit View</Menu.Item>
      <Menu.Item key="single-site_reviews">
        <Link to={`/my-sites/reviews/${pad_id}`}>Reviews & Ratings</Link>
      </Menu.Item>
      <Menu.Item key="single-site_landing">
        <Link to={`/my-sites/landing/${pad_id}`}>Landings</Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="pad-edit_container">
      <div className="create-pad">
        {pad?.data?.id ? (
          <PadForm
            pad={pad?.data}
            previousAction={padFormPrevHandler}
            nextAction={padFormNextHandler}
            menu={padHeaderMenu}
            saveButtonText={'Save Changes'}
            backPath="/my-sites"
          />
        ) : (
          <div
            style={{ height: 320, paddingTop: 220 }}
            className="d-flex align-items-center justify-content-center mb-5 pb-5"
          >
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
};

export default SiteEditPage;
