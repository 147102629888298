import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useUnmount } from 'react-use';

import Check from '../../assets/icons/check-green.svg';
import Button from '../../uiKitComponents/button';
import Form from '../../uiKitComponents/form';
import Input from '../../uiKitComponents/input';
import notification from '../../uiKitComponents/notification';
import Rate from '../../uiKitComponents/rate';
import Tooltip from '../../uiKitComponents/tooltip';
import api from '../../utils/appApi';
import getApiErrorMessages from '../../utils/getApiErrorMessages';
import FormattedText from '../formattedText';
import SliderModal from '../sliderModal';

const ReviewElement = (props) => {
  const { review } = props;
  const [uploads, setUploads] = useState([]);
  const [reportLoading, setReportLoading] = useState(false);
  const [sliderModalStatus, setSliderModalStatus] = useState({
    active: 0,
    status: false,
  });
  const [reviewReportStatus, setReviewReportStatus] = useState(false);
  const [reportTooltipVisibile, setReportTooltipVisibile] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (review) {
      setReviewReportStatus(review.is_reported);
      setUploads(review.uploads);
    }
  }, [review]);

  const hideModalSlider = () =>
    setSliderModalStatus((prevState) => ({ ...prevState, status: false }));

  const updateReviewUploads = (file) => {
    const fileIndex = uploads.indexOf(file);

    setUploads((prevState) =>
      prevState.map((padFile, idx) =>
        idx === fileIndex ? { ...padFile, is_upload_reported: true } : padFile,
      ),
    );

    setSliderModalStatus((prevState) => ({ ...prevState, active: fileIndex }));
  };

  const customDebounce = (fn, delay) => {
    let timer;

    const setTimer = () => {
      timer = setTimeout(fn, delay);
    };

    const clearTimer = () => clearTimeout(timer);

    return [clearTimer, setTimer];
  };

  const [
    clearDebouncedClosePopoverContent,
    debouncedClosePopoverContent,
  ] = customDebounce(() => setReportTooltipVisibile(false), 500);

  const reportReviewHandler = (values) => {
    setReportTooltipVisibile(false);
    clearDebouncedClosePopoverContent();
    form.resetFields();
    setReportLoading(true);

    api.reviews
      .report(review.id, { comment: values.report })
      .then(() => {
        setReportLoading(false);
        setReviewReportStatus(true);

        notification.success({
          message: 'The report was sent successfully!',
        });
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(err),
        });
        setReportLoading(false);
      });
  };

  useUnmount(clearDebouncedClosePopoverContent);

  const tooltipContent = (
    <div>
      <Form
        onFinish={reportReviewHandler}
        form={form}
        autoComplete="off"
        onMouseOver={clearDebouncedClosePopoverContent}
        onMouseLeave={debouncedClosePopoverContent}
      >
        <Form.Item name="report">
          <Input.TextArea placeholder="Please give us some context as to why you're reporting this review..." />
        </Form.Item>
        <Button type="text" htmlType="submit" className="auto-size-btn">
          Submit
        </Button>
      </Form>
    </div>
  );

  const getUserAvatar = () => {
    if (review.user.upload) {
      return <img src={review.user.upload.file_url} alt="" />;
    }

    return (
      <span>
        {review.user.first_name?.[0]}
        {review.user.last_name?.[0]}
      </span>
    );
  };

  const getReviewVisits = () => {
    if (review.is_visited_again) {
      return (
        <span className="status true">
          <img src={Check} alt="" /> Yes
        </span>
      );
    }

    return <span className="status false">No</span>;
  };

  const getReviewUploads = () => {
    if (review.uploads.length) {
      return uploads.map((upload, idx) => (
        <div
          className="attach-item"
          aria-hidden="true"
          onClick={() =>
            setSliderModalStatus((prevState) => ({
              ...prevState,
              status: true,
              active: idx,
            }))
          }
          key={upload?.id}
        >
          <img src={upload.file_url} alt={upload?.name} />
        </div>
      ));
    }

    return null;
  };

  const getUserReviewName = () => {
    if (review.user?.username) {
      return review.user.username;
    }

    return `${review.user?.first_name} ${review.user?.last_name}`;
  };

  const getUserLocation = () =>
    review.user.location?.county
      ? `${review.user.location?.county}, ${review.user.location?.country}`
      : review.user.location?.country;

  return (
    <div className="review">
      <div className="review-inner">
        <div className="review-inner_user">
          <div className="user-image">{getUserAvatar()}</div>
          <div className="user-meta">
            <h3 className="name">{getUserReviewName()}</h3>
            <span className="location">{getUserLocation()}</span>
            <span className="since-year">
              Member since {dayjs(review.user.created_at).format('YYYY')}
            </span>
            <span className="total-review">
              {review.user.total_reviews} reviews
            </span>
          </div>
        </div>
        <div className="review-inner_content">
          <div className="content-meta">
            <div className="created">
              {dayjs(review.created_at).format('DD MMM YYYY HH:mm')}
            </div>
            {review.is_visited_again !== null && (
              <div className="visits">
                Would visit again? {getReviewVisits()}
              </div>
            )}
          </div>
          <div className="content-comment">
            <p>
              <FormattedText text={review.comment ? review.comment : ''} />
            </p>
          </div>
          <div className="content-attachments">
            {review?.uploads && (
              <SliderModal
                isModalVisible={sliderModalStatus.status}
                onCancelHandler={hideModalSlider}
                slides={uploads}
                updatePadUploads={updateReviewUploads}
                activeIndex={sliderModalStatus.active}
              />
            )}
            {getReviewUploads()}
          </div>
          <div className="comment-stars">
            {!!review.landing && (
              <div className="rate-and-title-element mb-4">
                <div className="rate-label">Ease of landings</div>
                <div className="d-flex align-items-center">
                  <Rate value={review.landing} disabled allowHalf />
                </div>
              </div>
            )}
            <div className="rate-inline-container d-flex">
              {!!review.helifriendliness && (
                <div className="rate-and-title-element">
                  <div className="rate-label">Helifriendliness</div>
                  <div className="d-flex align-items-center">
                    <Rate value={review.helifriendliness} disabled allowHalf />
                  </div>
                </div>
              )}
              {!!review.food && (
                <div className="rate-and-title-element">
                  <div className="rate-label">Food</div>
                  <div className="d-flex align-items-center">
                    <Rate value={review.food} disabled allowHalf />
                  </div>
                </div>
              )}
              {!!review.service && (
                <div className="rate-and-title-element">
                  <div className="rate-label">Service</div>
                  <div className="d-flex align-items-center">
                    <Rate value={review.service} disabled allowHalf />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="review-inner_report">
            <Tooltip
              trigger="click"
              overlayClassName="custom-tooltip"
              placement="bottomRight"
              title={tooltipContent}
              visible={reportTooltipVisibile}
            >
              <Button
                type="text"
                className="auto-size-btn text-content-color d-flex align-items-center ml-auto mt-auto"
                onClick={() =>
                  !reviewReportStatus && setReportTooltipVisibile(true)
                }
                loading={reportLoading}
                icon={
                  reviewReportStatus ? (
                    <i className="mr-2 custom-icon-flag-primary" />
                  ) : (
                    <i className="mr-2 custom-icon-flag-grey" />
                  )
                }
              >
                {reviewReportStatus ? 'Reported' : 'Report'}
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

ReviewElement.defaultProps = {
  review: {},
};

ReviewElement.propTypes = {
  review: PropTypes.shape({
    id: PropTypes.number,
    user: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      location: PropTypes.shape({
        county: PropTypes.string,
        country: PropTypes.string,
        name: PropTypes.string,
        created_at: PropTypes.string,
      }),
      username: PropTypes.string,
      upload: PropTypes.shape({
        file_url: PropTypes.string,
      }),
      created_at: PropTypes.string,
      total_reviews: PropTypes.number,
    }),
    comment: PropTypes.string,
    created_at: PropTypes.string,
    is_visited_again: PropTypes.bool,
    is_review_approved: PropTypes.bool,
    is_reported: PropTypes.bool,
    landing: PropTypes.number,
    service: PropTypes.number,
    helifriendliness: PropTypes.number,
    food: PropTypes.number,
    rating: PropTypes.number,
    uploads: PropTypes.array,
  }),
};

export default ReviewElement;
