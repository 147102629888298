export const validateFormValues = (formValues) => {
  const {
    approach_procedures_comment,
    approach_reference_points_comment,
  } = formValues;

  if (approach_procedures_comment || approach_reference_points_comment) {
    return true;
  }

  return false;
};
