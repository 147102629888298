/**
 * condStrings - "Conditional Strings" -
 * filter a list of boolean expressions and return string.
 * 
 * @name classNames
 * @param  {...String} classes
 * @returns {String}
 */

const condStrings = (...classes) =>
  classes
    .map((cls) => (Array.isArray(cls) ? cls.join(' ') : cls))
    .filter((cls) => !!cls)
    .join(' ');

export default condStrings;
