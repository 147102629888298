import { Checkbox, Form, Input, Modal } from 'antd';
import { useCallback, useContext, useState } from 'react';

import { hazards } from '../../constatns';
import useStepUpdater from '../../hooks/useStepUpdater';
import { SurveyContext } from '../../Survey';
import {
  APPROACH_STEP,
  HAZARDS_STEP,
  PAD_VIEW_STEP,
} from '../../Survey.context';
import { separatedArray } from '../../utils';
import { SurveyStepActions } from '../SurveyStepActions';
import { validateFormValues } from './SurveyHazardStep.utils';

export function SurveyHazardStep() {
  const { surveyData, changeSurveyStep } = useContext(SurveyContext);

  const [confirmationModalVisible, setConfirmationModalVisible] = useState(
    false,
  );
  const [
    nextStepKeyAfterConfirmation,
    setNextStepKeyAfterConfirmation,
  ] = useState();
  const [isOtherSelected, setIsOtherSelected] = useState(
    surveyData.hazards?.hazards_relevant_categories?.includes(99),
  );

  const [onStepValuesChange] = useStepUpdater(HAZARDS_STEP);

  const onValuesChange = useCallback(
    (changedValues, formValues) => {
      const hazards_is_completed = validateFormValues(formValues);

      const areCategoriesUpdated = Object.prototype.hasOwnProperty.call(
        changedValues,
        'hazards_relevant_categories',
      );

      if (areCategoriesUpdated) {
        setIsOtherSelected(
          changedValues.hazards_relevant_categories.includes(99),
        );
      }

      onStepValuesChange({
        ...changedValues,
        hazards_is_completed,
      });
    },
    [onStepValuesChange],
  );

  const handleLeaveStep = (nextStepKey) => () => {
    const { hazards_relevant_categories, is_completed } = surveyData.hazards;

    if (!hazards_relevant_categories?.length && !is_completed) {
      setConfirmationModalVisible(true);
      setNextStepKeyAfterConfirmation(nextStepKey);
    } else {
      changeSurveyStep(nextStepKey)();
    }
  };

  const onClickModalConfirm = () => {
    onStepValuesChange({ hazards_is_completed: true }, { immediately: true });

    if (nextStepKeyAfterConfirmation) {
      changeSurveyStep(nextStepKeyAfterConfirmation)();
    }
  };

  const onClickModalCancel = () => {
    setConfirmationModalVisible(false);
  };

  return (
    <>
      <Form
        layout="vertical"
        onValuesChange={onValuesChange}
        initialValues={surveyData.hazards}
      >
        <Form.Item name="hazards_relevant_categories">
          <Checkbox.Group className="survey-checkbox-list w-100">
            <h3 className="survey-checkbox-title">
              Please select all relevant hazards
            </h3>
            <div className="d-flex justify-content-between responsive">
              {separatedArray(
                hazards.filter((h) =>
                  h.categories.includes(surveyData.general?.category_id),
                ),
              ).map((side) => (
                <div key={side.name} className="d-flex flex-column">
                  {side.values.map((v) => (
                    <Checkbox value={v.id} className="mb-2">
                      {v.name}
                    </Checkbox>
                  ))}
                </div>
              ))}
            </div>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item
          name="hazards_comment"
          label="Hazard details"
          required={isOtherSelected}
          className="mt-4"
          rules={[
            {
              min: 3,
              max: 500,
              message: 'Must be between 3 and 500 characters',
            },
          ]}
        >
          <Input.TextArea
            className="survey-textarea max-100 scrollbar-thin"
            autoSize
            showCount
            maxLength={500}
            placeholder="Please elaborate on each hazard, giving as specific detail as possible including bearing and distance from landing area"
          />
        </Form.Item>
        <SurveyStepActions
          backClick={changeSurveyStep(PAD_VIEW_STEP)}
          skipClick={changeSurveyStep(APPROACH_STEP)}
          nextClick={handleLeaveStep(APPROACH_STEP)}
        />
      </Form>
      <Modal
        closeIcon={false}
        maskClosable={false}
        centered
        visible={confirmationModalVisible}
        onOk={onClickModalConfirm}
        onCancel={onClickModalCancel}
        className="survey-confirmation-modal default-footer"
        cancelText="Cancel"
        okText="Continue"
        okButtonProps={{
          className: 'secondary-btn',
        }}
        cancelButtonProps={{
          className: 'outline-btn survey-outline',
        }}
      >
        <div className="survey-confirmation-content">
          <h3>Please confirm there are no hazards within 100m</h3>
        </div>
      </Modal>
    </>
  );
}
