import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';

const CustomSteps = (props) => {
  const { currentStep, steps } = props;
  const stepsRef = useRef();

  const getDotClassName = useCallback(
    (idx) => {
      if (idx === currentStep) return 'steps-dot progress';
      if (idx < currentStep) return 'steps-dot finished';
      return 'steps-dot waiting';
    },
    [currentStep],
  );

  const generateSteps = useCallback(
    () =>
      steps.map((step, idx) => (
        <div className={getDotClassName(idx)} key={idx} data-title={step} />
      )),
    [getDotClassName, steps],
  );

  const generateStepsMobileText = useCallback(
    () =>
      steps.map((step, idx) => (
        <div
          className={
            currentStep === idx
              ? 'steps-dot_mobile-text active'
              : 'steps-dot_mobile-text'
          }
          key={idx}
        >
          {step}
        </div>
      )),
    [currentStep, steps],
  );

  return (
    <div className="steps-custom-container" ref={stepsRef}>
      {generateSteps()} {generateStepsMobileText()}
    </div>
  );
};

CustomSteps.defaultProps = {
  currentStep: 0,
  steps: [],
};

CustomSteps.propTypes = {
  currentStep: PropTypes.number,
  steps: PropTypes.array,
};

export default CustomSteps;
