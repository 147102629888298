import './countrySelect.scss';

import { Form, Select } from 'antd';
import PropTypes from 'prop-types';
import { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import {
  selectCountriesOptions,
  selectUSStatesOptions,
} from '../../store/selectors/bootstrapSelector';
import condStrings from '../../utils/condStrings';

function CountrySelect(props) {
  const { defaultCountry, onChange } = props;
  const { countries, USStates } = useSelector((store) => ({
    countries: selectCountriesOptions(store),
    USStates: selectUSStatesOptions(store),
  }));
  const [isUSSelected, setIsUSSelected] = useState(false);
  const stateSelectRef = useRef();

  useEffect(() => {
    if (defaultCountry === 'US') setIsUSSelected(true);
  }, [defaultCountry]);

  const onCoutryChange = (code) => {
    if (onChange) onChange(code.toLowerCase());

    if (code === 'US') setIsUSSelected(true);
    else setIsUSSelected(false);
  };

  return (
    <div className={condStrings('country-selector', isUSSelected && 'is-us')}>
      <div className="select-country">
        <Form.Item
          name={['location', 'country_code']}
          rules={[{ required: true, message: 'Please select country' }]}
        >
          <Select
            placeholder="Country"
            onSelect={onCoutryChange}
            optionFilterProp="label"
            options={countries}
            showSearch
          />
        </Form.Item>
      </div>
      <CSSTransition
        in={isUSSelected}
        nodeRef={stateSelectRef}
        timeout={200}
        unmountOnExit
      >
        <div className="select-state" ref={stateSelectRef}>
          <Form.Item
            name={['location', 'state_code']}
            initialValue={defaultCountry}
            rules={[{ required: true, message: 'Please select state' }]}
          >
            <Select
              placeholder="State"
              options={USStates}
              optionFilterProp="label"
            />
          </Form.Item>
        </div>
      </CSSTransition>
    </div>
  );
}

CountrySelect.propTypes = {
  defaultCountry: PropTypes.string,
  onChange: PropTypes.func,
};

CountrySelect.defaultProps = {
  defaultCountry: null,
  onChange: null,
};

export default memo(CountrySelect);
