import {
  QUICK_ACCESS_IS_SURVEY_SUBMIT,
  QUICK_ACCESS_SET_PAD,
  QUICK_ACCESS_SET_REVIEWS,
  QUICK_ACCESS_TRIGGER_SURVEY_UPDATE,
} from '../actionTypes';

export const quickAccessSetPad = (data) => (dispatch) =>
  dispatch({ type: QUICK_ACCESS_SET_PAD, payload: data });

export const quickAccessSetReviews = (data) => (dispatch) =>
  dispatch({ type: QUICK_ACCESS_SET_REVIEWS, payload: data });

export const quickAccessIsSurveySubmit = (data) => (dispatch) =>
  dispatch({ type: QUICK_ACCESS_IS_SURVEY_SUBMIT, payload: data });

export const quickAccessTriggerSurveySubmit = (data) => (dispatch) =>
  dispatch({ type: QUICK_ACCESS_TRIGGER_SURVEY_UPDATE, payload: data });
