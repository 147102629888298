import { Button } from 'antd';
import { useContext } from 'react';

import { SurveyContext } from '../../Survey';
import { GENERAL_STEP } from '../../Survey.context';
import { SurveyStepsBar } from '../SurveyStepsBar';
import { SurveyGreetingHeader } from './SurveyGreetingHeader';

export function SurveyGreeting() {
  const { surveyData, changeSurveyStep } = useContext(SurveyContext);

  return (
    <div className="survey-layout greeting">
      <SurveyGreetingHeader padName={surveyData.general.name} />
      <SurveyStepsBar type="greeting" />
      <div className="survey-greeting__description">
        <p>
          The idea here is to give you as the site owner, the ability to pass on
          more in depth details about the landing area directly to pilots.
          It&apos;s a feature that&apos;s been highly requested, so pilots can
          feel as though they&apos;ve visited a site before landing there.
        </p>
        <p>
          We suggest putting aside 15 minutes to complete the survey. You
          don&apos;t have to be a pilot, a surveyor, or know any flying lingo to
          complete it. We&apos;ve broken it down into 7 simple sections for you
          and we&apos;ll guide you through each one. A completed survey will
          make your site far more appealing to pilots, as well as enabling a
          much safer operation from both sides.
        </p>
        <p>Any Questions, please don&apos;t hesitate to contact us.</p>
      </div>
      <Button
        type="primary"
        className="survey-btn"
        onClick={changeSurveyStep(GENERAL_STEP)}
      >
        Start Survey
      </Button>
    </div>
  );
}
