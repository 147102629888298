import { RedoOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import React, { useEffect, useState } from 'react';

import { ReactComponent as InfoCircleInfo } from '../../assets/icons/info-circle.svg';
import api from '../../utils/appApi';

const QRCodeRedirect = (props) => {

    const { id } = props;
    const [qrCode, setQrCode] = useState('');

    useEffect(() => {
        api.qrCode
            .getQRCode(id)
            .then((res) => {
                setQrCode(res?.data?.token);
                return res;
            })
            .catch((err) => {
                return err;
            });
    }, [id]);

    const handleRefreshQR = () => {
        api.qrCode
            .getQRCodeReset(id)
            .then((res) => {
                setQrCode(res?.data?.token);
                return res;
            })
            .catch((err) => {
                return err;
            });
    };

    return (
        <div
            className='d-flex justify-content-center'
        >
            <div
                className='mr-2 d-flex flex-column'
            >
                <Tooltip
                    title="The QR expires in 2 hours"
                    style={{ marginRight: '10px' }}
                >
                    <InfoCircleInfo className="ml-2" style={{ fontSize: '12px' }} />
                </Tooltip>
                <Button
                    size='small'
                    type='link'
                    style={{ width: "35px", height: "35px" }}
                    onClick={handleRefreshQR}
                >
                    <RedoOutlined />
                </Button>
            </div>
            <QRCodeSVG
                value={`${window.location.href}/${qrCode}`}
                size={145}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                includeMargin={false}
            />

        </div>
    );
};

QRCodeRedirect.propTypes = {
    id: PropTypes.string.isRequired,
};

export default QRCodeRedirect;
