import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import PadElement from '../../components/padElement';
import SearchPads from '../../components/searchPads';
import SectionTitle from '../../components/sectionTitle';
import {
  claimSiteEventOpts,
  createNewSiteEventOpts,
  manageSiteEventOpts,
} from '../../events';
import useGaEvent from '../../hooks/useGAEvent';
import { getBanner } from '../../store/actions/bannerAction';
import { getUserPads, pushPadToUserPads } from '../../store/actions/padsAction';
import { surveyCounterNew } from '../../store/actions/surveyAction';
import Button from '../../uiKitComponents/button';
import Empty from '../../uiKitComponents/empty';
import Modal from '../../uiKitComponents/modal';
import notification from '../../uiKitComponents/notification';
import Spin from '../../uiKitComponents/spin';
import api from '../../utils/appApi';
import getApiErrorMessages from '../../utils/getApiErrorMessages';

const MySitesPage = () => {
  const { type } = useParams();
  const [loading, setLoading] = useState(true);
  const [manageNewPadLoading, setManageNewPadLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showMoreLoading, setShowMoreLoading] = useState(false);
  const [showMoreBtnStatus, setShowMoreBtnStatus] = useState(true);

  const { userPads } = useSelector((store) => ({
    userPads: store?.padList?.payload,
  }));

  const [isUserCreatePad, setIsUserCreatePad] = useState(false);
  const [selectedPad, setSelectedPad] = useState(undefined);
  const history = useHistory();
  const dispatch = useDispatch();

  // GA events
  const manageSiteEvent = useGaEvent(manageSiteEventOpts);
  const claimSiteEvent = useGaEvent(claimSiteEventOpts);
  const createNewSiteEvent = useGaEvent(createNewSiteEventOpts);

  const { confirm } = Modal;

  const reviewsShowMoreHandler = () => {
    setCurrentPage((prevState) => prevState + 1);
    setShowMoreLoading(true);
  };

  useEffect(() => {
    if (type === 'manage-new-pad') setIsUserCreatePad(true);
  }, [type]);

  useEffect(() => {
    if (userPads.length && loading) setLoading(false);
  }, [userPads, loading]);

  useEffect(() => {
    getBanner()(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (userPads.length < 10) setShowMoreBtnStatus(false);
    getUserPads(currentPage)(dispatch).then((res) => {
      surveyCounterNew(res.survey_count)(dispatch);
      if (res.data.length < res?.meta?.per_page) setShowMoreBtnStatus(false);
      if (res.data.length >= res?.meta?.per_page) setShowMoreBtnStatus(true);
      if (res?.meta?.last_page <= 1) setShowMoreBtnStatus(false);
      setLoading(false);
      setShowMoreLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentPage]);

  const selectPadHandler = (item) => {
    if (Object.is(item)) {
      setSelectedPad(item.id);
    } else {
      setSelectedPad(item);
    }
  };

  const claimPadHandler = useCallback(async () => {
    setManageNewPadLoading(true);
    try {
      const res = await api.pad.claim(selectedPad?.id);
      await pushPadToUserPads(res?.data.data)(dispatch);
      setSelectedPad(undefined);
      setManageNewPadLoading(false);
      notification.success({
        message: 'Succes',
        description: `Your request to manage pad was sent successfully.`,
      });
      setIsUserCreatePad(false);
    } catch (err) {
      setManageNewPadLoading(false);
      notification.error({
        message: 'Error',
        description: getApiErrorMessages(err),
      });
    }
  }, [dispatch, selectedPad]);

  const showModal = useCallback(() => {
    confirm({
      title: `${selectedPad.name} will now be visible to all of our Pilots`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirm',
      onOk: claimSiteEvent(claimPadHandler),
      width: 500,
      className: 'ant-modal-confirm-body-centered',
    });
  }, [selectedPad, confirm, claimSiteEvent, claimPadHandler]);

  const getNextButton = useCallback(() => {
    if (
      selectedPad &&
      selectedPad?.is_landing_permitted &&
      !selectedPad?.is_unmoderated
    )
      return (
        <Button
          type="primary"
          onClick={claimSiteEvent(claimPadHandler)}
          disabled={manageNewPadLoading}
        >
          Next
        </Button>
      );
    if (
      (selectedPad && !selectedPad?.is_landing_permitted) ||
      selectedPad?.is_unmoderated
    )
      return (
        <Button 
          type="primary" 
          onClick={showModal} 
          disabled={manageNewPadLoading}
        >
          {manageNewPadLoading ? <Spin className='spin-white' /> : null}
          Next
        </Button>
      );
    if (selectedPad === 0)
      return (
        <Button type="primary" onClick={createNewSiteEvent()}>
          <Link to="/create-pad">Add New Pad</Link>
        </Button>
      );
    return (
      <Button type="primary" disabled>
        Next
      </Button>
    );
  }, [
    claimPadHandler,
    claimSiteEvent,
    createNewSiteEvent,
    manageNewPadLoading,
    selectedPad,
    showModal,
  ]);

  const displayUserPadList = useCallback(() => {
    if (loading) {
      return (
        <div className="d-flex align-item-center justify-content-center pt-5 pb-5">
          <Spin />
        </div>
      );
    }

    if (!userPads?.length && !isUserCreatePad) {
      return <Empty />;
      // return <CarouselBanner />
    }

    if (isUserCreatePad) {
      return (
        <div className="search-pad">
          <SearchPads
            label="Enter Pad Name"
            action={selectPadHandler}
            createPadAction={() => history.push('/create-pad')}
            value={selectedPad || {}}
          />
          <div className="search-pad_actions">
            <Button.Group>
              <Button
                className="secondary-btn"
                onClick={() => {
                  setIsUserCreatePad(false);
                  setSelectedPad(undefined);
                }}
              >
                Cancel
              </Button>
              {getNextButton()}
            </Button.Group>
          </div>
        </div>
      );
    }

    return userPads?.map((pad, index) => <PadElement key={`${pad?.id}${index}`} pad={pad} />);
  }, [getNextButton, history, isUserCreatePad, loading, selectedPad, userPads]);

  const manageSiteHandler = () =>
    isUserCreatePad ? setIsUserCreatePad(false) : setIsUserCreatePad(true);

  return (
    <div className="my-sites-page">
      <div className="large-container">
        <SectionTitle>
          <div>
            <h1 className="v1-h mb-0">My Sites</h1>
            {userPads.length === 0 && !loading && (
              <p className="subtitle">
                You don&lsquo;t currently manage any landing sites in Helipaddy,
                add one now!
              </p>
            )}
          </div>
          {!isUserCreatePad && (
            <Button
              type="primary"
              loading={manageNewPadLoading}
              onClick={manageSiteEvent(manageSiteHandler)}
            >
              Manage a New Site
            </Button>
          )}
        </SectionTitle>
        <div
        // className={
        //   isUserCreatePad
        //   // ? 'pad-list_container managing'
        //   // : 'pad-list_container'
        // }
        >
          {displayUserPadList()}
          {!loading &&
            showMoreBtnStatus &&
            !isUserCreatePad &&
            userPads.length !== 0 && (
              <Row>
                <Col span={24} className="text-center">
                  <Button
                    className="outline-btn"
                    onClick={reviewsShowMoreHandler}
                    loading={showMoreLoading}
                  >
                    Show More
                  </Button>
                </Col>
              </Row>
            )}
        </div>
      </div>
    </div>
  );
};

export default MySitesPage;
