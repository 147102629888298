import { notification } from 'antd';

import { setUserToAuthorizedWithSessionKeep } from '../../store/actions/userAction';
import api from '../../utils/appApi';
import getApiErrorMessages from '../../utils/getApiErrorMessages';

export const steps = {
  personal_details: {
    index: 0,
    url: '/registration-flow/',
  },
  role: {
    index: 1,
    url: '/registration-flow/role',
  },
  pad_details: {
    index: 2,
    url: '/registration-flow/pad-details',
  },
  terms: {
    index: 3,
    url: '/registration-flow/terms',
  },
};

export const getRegistrationFlowIntialURL = (user) => {
  if (user?.completed_at) return '/my-sites';

  if (!user?.phone || !user?.email_verified_at) {
    return steps.personal_details.url;
  }

  if (user?.registration_step_int) {
    const lastActivestep = Object.values(steps).find(
      (step) => step.index === user.registration_step_int,
    );
    if (lastActivestep) return lastActivestep.url;
  }

  return steps.role.url;
};

export const activateEmail = async (dispatch) => {
  const params = new URLSearchParams(window.location.search);

  if (params.has('token')) {
    const token = params.get('token');
    try {
      const response = await api.user.activateEmail({ token });
      await dispatch(setUserToAuthorizedWithSessionKeep(response.data));
    } catch (error) {
      notification.error({
        message: 'Error',
        description: getApiErrorMessages(error),
      });
      window.location.replace(window.location.pathname);
    }
  }
};
