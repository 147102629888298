import axios from 'axios';

import { setUserToNotAuthorized } from '../store/actions/userAction';
import store from '../store/store';

const token = localStorage.getItem('token');
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    Authorization: token ? `Bearer ${token}` : 'Bearer ',
  },
});

axiosInstance.defaults.withCredentials = true;

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const {
      response: { status },
    } = error;

    if (status === 401) {
      store.dispatch(setUserToNotAuthorized());
      window.location.reload();
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
