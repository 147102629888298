import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';

import Telephone from '../../assets/icons/telephone.svg';
import { logoutWithoutRedirect } from '../../store/actions/userAction';

const SoSorryPage = () => {
  const dispatch = useDispatch();

  const { isAuthorized } = useSelector((store) => ({
    isAuthorized: store?.user?.isAuthorized,
  }));

  useMount(() => {
    if (isAuthorized) {
      logoutWithoutRedirect(false)(dispatch);
    }
  });

  return (
    <div className="activation-page sorry">
      <div className="activation-page_content">
        <div className="content-image wave-eff">
          <img src={Telephone} alt="Success" className="wave" />
        </div>
        <p className="content-descrp">
          Please open this link from a device with the <b>Helipaddy App</b>{' '}
          installed, and you&apos;ll be able to view the destination!
        </p>
      </div>
    </div>
  );
};

export default SoSorryPage;
