import {
  ArrowRightOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

const PATHNAME_RESTRICTION = [
  '/survey-360/survey'
];

const Banner = (props) => {
  const { showBanner, setShowBanner } = props;
  const history = useHistory();
  const { part3 } = useParams();
  const [bannerPath, setBannerPath] = useState(true);

  const { banner, pad, nameSite } = useSelector((state) => ({
    payload: state.user.payload,
    banner: state.banner,
    pad: state.padList.payload,
    nameSite: state.padQuickAccess.payload.pad.name,
  }));

  const extractNumberFromString = (string) => parseInt(string.replace(/[^0-9]/g, ''), 10);
  const extractPathnameWithoutNumber = (string) => string.replace(/\/[0-9]+/g, '');
  const getPadItem = () => pad?.find((item) => item.id === extractNumberFromString(history.location.pathname));

  setShowBanner(JSON.parse(localStorage.getItem('banner')));

  useEffect(() => {
    if (PATHNAME_RESTRICTION.includes(extractPathnameWithoutNumber(history.location.pathname) )) {
      setBannerPath(false);
    } else { setBannerPath(true) }
  }, [history.location.pathname, bannerPath]);

  const localPathname = history.location.pathname.split('/').slice(0, -1).join('/');
  if (nameSite !== banner?.pad_name && localPathname === "/my-sites/site" && banner?.survey_count === 1) return null;
  const hiddenPaths = ["/my-sites/site", "/my-sites/reviews", "/my-sites/edit", "/my-sites/landing"]
  if (!banner?.pad_ids_with_uncompleted_surveys?.includes(parseInt(part3, 10)) && hiddenPaths?.includes(localPathname)) return null;

  if (banner?.is_banner_displayed && getPadItem()?.survey_status !== 3) {
    if (showBanner && bannerPath && !(banner?.survey_count === 1 && history.location.pathname === '/survey-360' && banner?.has_not_started_surveys)) {
      return (
        <div className='banner'>
          <div className='large-container d-flex align-items-center justify-content-between'>
            <div className='banner_inner d-flex align-items-center'>
              <div className='banner_inner'>
                {
                  banner?.survey_count === 1 && banner?.has_in_progress_surveys
                    ? `You've nearly completed your 360 Survey for ${banner?.pad_name}`
                    : `Help Pilots by sharing more detail about landing at your site by completing our 360 Survey!`
                }
                <Button
                  type='link'
                  onClick={() => {
                    if (banner?.has_in_progress_surveys) {
                      if (banner?.survey_id) {
                        history.push(`/survey-360/survey/${banner?.survey_id}`);
                      } else {
                        history.push(`/survey-360`);
                      }
                    } else {
                      history.push(`/survey-360`);
                    }
                  }}
                >
                  {banner?.has_in_progress_surveys && (
                    banner?.survey_count === 1 ? "Finish it here" : "Continue here"
                  )}
                  {!banner?.has_in_progress_surveys && "Start it immediately"}
                  <ArrowRightOutlined />
                </Button>
              </div>
            </div>
            <Button
              type="text"
              icon={<CloseOutlined />}
              size="small"
              className='banner__button-close'
              onClick={() => {
                setShowBanner(false);
                localStorage.removeItem('banner');
              }}
            />
          </div>
        </div>
      );
    };
  };
  return null;
};

Banner.propTypes = {
  showBanner: PropTypes.bool,
  setShowBanner: PropTypes.func,
};

export default Banner;
