import { Col, Form, Input, Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import usePrevious from '../../../hooks/usePrevious';
import PhoneInput from '../../phoneInput';

const formItemVerticalLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export default function PrimaryContactFields(props) {
  const { updateFormValues, primaryContact } = props;
  const previousPrimaryContact = usePrevious(primaryContact);

  React.useEffect(() => {
    if (primaryContact?.id !== previousPrimaryContact?.id) {
      updateFormValues([
        { name: ['primary_contact', 'id'], value: primaryContact?.id || '' },
        {
          name: ['primary_contact', 'contact_type_id'],
          value: primaryContact?.contact_type_id || 1,
        },
        {
          name: ['primary_contact', 'phone'],
          value: primaryContact.phone || '',
        },
        {
          name: ['primary_contact', 'email'],
          value: primaryContact.email || '',
        },
        { name: ['primary_contact', 'name'], value: primaryContact.name || '' },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryContact, updateFormValues]);

  return (
    <>
      <Row justify="space-between">
        <Form.Item name={['primary_contact', 'id']} noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name={['primary_contact', 'contact_type_id']} noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Col span={24} md={10}>
          <Form.Item
            {...formItemVerticalLayout}
            label="Phone Number"
            name={['primary_contact', 'phone']}
            rules={[{ required: true, message: 'Phone is required' }]}
          >
            <PhoneInput />
          </Form.Item>
        </Col>
        <Col span={24} md={10}>
          <Form.Item
            {...formItemVerticalLayout}
            label="Email Address"
            name={['primary_contact', 'email']}
            rules={[
              {
                required: true,
                message: 'Email is required',
              },
              {
                type: 'email',
                message: 'Is not a valid email',
              },
            ]}
          >
            <Input type="email" placeholder="john@example.com" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        {...formItemVerticalLayout}
        label="Contact Person"
        name={['primary_contact', 'name']}
        rules={[{ required: true, message: 'Name is required' }]}
      >
        <Input placeholder="John Doe" />
      </Form.Item>
    </>
  );
}

PrimaryContactFields.propTypes = {
  updateFormValues: PropTypes.func,
  primaryContact: PropTypes.object,
};
