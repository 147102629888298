import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useCallback, useState } from 'react';
import GoogleLogin from 'react-google-login';
import { useDispatch } from 'react-redux';

import iconGoogle from '../../assets/icons/Google_G_Logo.svg';
import useApiErrorsWithAntd from '../../hooks/useApiErrorsWithAntd';
import { login, loginViaGoogle } from '../../store/actions/userAction';
import Alert from '../../uiKitComponents/alert';
import Button from '../../uiKitComponents/button';
import Col from '../../uiKitComponents/col';
import Form from '../../uiKitComponents/form';
import Input from '../../uiKitComponents/input';
import notification from '../../uiKitComponents/notification';
import Row from '../../uiKitComponents/row';
import getApiErrorMessages from '../../utils/getApiErrorMessages';

const LoginPage = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [gooLoading, setGooLoading] = useState(false);
  const [form] = Form.useForm();
  const { setApiErrorsToAntdForm } = useApiErrorsWithAntd(form);

  const onSubmit = useCallback(
    (values) => {
      setLoading(true);
      login(values)(dispatch).catch((err) => {
        setLoading(false);
        setApiErrorsToAntdForm(err);
      });
    },
    [dispatch, setApiErrorsToAntdForm],
  );

  const responseGoogleSuccess = (response) => {
    setGooLoading(true);

    if (response.accessToken) {
      loginViaGoogle(response?.accessToken)(dispatch)
        .then((res) => {
          setGooLoading(false);
        })
        .catch((err) => {
          notification.error({
            message: 'Error',
            description: getApiErrorMessages(err),
          });
          setGooLoading(false);
        });
    }
  };

  const responseGoogleFailure = (response) => {
    console.log(response);
  };

  return (
    <div className="login-page">
      <div className="login-page-content">
        <Row>
          <Col md={12} span={24} className="left-col">
            <h2>Login into your account</h2>
            <Form form={form} onFinish={onSubmit}>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Email is required' },
                  { type: 'email', message: 'Invalid email' },
                ]}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined />}
                  placeholder="Email"
                  autoComplete="email"
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Password is required' }]}
              >
                <Input.Password
                  size="large"
                  prefix={<LockOutlined />}
                  type="password"
                  placeholder="Password"
                  autoComplete="current-password"
                />
              </Form.Item>

              <Form.Item hidden name="global">
                <Input />
              </Form.Item>

              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  style={{ maxWidth: 176 }}
                >
                  Log in
                </Button>
                <Button
                  type="link"
                  href="/forgot-password"
                  style={{ color: '#ffffff' }}
                >
                  Forgot Password?
                </Button>
              </Form.Item>
              <Form.Item shouldUpdate>
                {() => {
                  const errors = form.getFieldError('global');

                  return (
                    !!errors.length && (
                      <Alert
                        message="Error"
                        description={errors}
                        type="error"
                        showIcon
                      />
                    )
                  );
                }}
              </Form.Item>
            </Form>
          </Col>
          <Col md={12} span={24} className="right-col">
            <h2>Not registered? Create an account</h2>
            <Button
              type="primary"
              href="/registration"
              style={{
                maxWidth: 248,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => {
                localStorage.setItem('banner', 'true');
                localStorage.setItem('carousel', 'true');
              }}
            >
              Sign Up with Email
            </Button>
            <div className="orTxt">or</div>
            <GoogleLogin
              tag="div"
              className="google-login-button"
              clientId={process.env.REACT_APP_GOOGLE_CLINET_ID}
              onSuccess={responseGoogleSuccess}
              onFailure={responseGoogleFailure}
              buttonText={null}
              icon={false}
            >
              <Button
                type="primary"
                loading={gooLoading}
                icon={<img className="mr-3" src={iconGoogle} alt="google" />}
                onClick={() => {
                  localStorage.setItem('banner', 'true');
                  localStorage.setItem('carousel', 'true');
                }}
              >
                Sign Up with Google
              </Button>
            </GoogleLogin>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default LoginPage;
