import {
  APPROACH_STEP,
  FACILITIES_STEP,
  GENERAL_STEP,
  GREETING_STEP,
  HAZARDS_STEP,
  LANDING_STEP,
  MAP_STEP,
  PAD_VIEW_STEP,
} from '../Survey.context';

export const dynamicSelectValidation = (
  value = '',
  isActive = false,
  msg = 'This field is required',
) => {
  if (!value && isActive) {
    return Promise.reject(new Error(msg));
  }

  return Promise.resolve();
};

const availableSteps = [
  GENERAL_STEP,
  MAP_STEP,
  LANDING_STEP,
  PAD_VIEW_STEP,
  HAZARDS_STEP,
  APPROACH_STEP,
  FACILITIES_STEP,
];

export const getSurveyInitialStep = (surveyData) => {
  const steps = [];

  Object.keys(surveyData).forEach((key) => {
    if (availableSteps.includes(key))
      steps.push({ stepKey: key, stepData: surveyData[key] });
  });

  const firstUncompleted = steps.find(
    (step) => step.stepData.is_completed === false,
  );

  let initialStep = GREETING_STEP;

  if (firstUncompleted) initialStep = firstUncompleted.stepKey;
  else if (surveyData.progress_step) initialStep = surveyData.progress_step;

  if(localStorage.getItem(`active-step`)) {
    initialStep = localStorage.getItem(`active-step`);
    localStorage.removeItem(`active-step`);
  }

  return initialStep;
};

export const separatedArray = (arr) => {
  const middleElemnt = Math.round((arr.length - 1) / 2);

  const leftArray = [...arr];
  const rightArray = leftArray.splice(0, middleElemnt);

  return [
    { name: 'left', values: leftArray },
    { name: 'right', values: rightArray },
  ];
};
