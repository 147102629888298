import PropTypes from 'prop-types';

import Input from '../../uiKitComponents/input';

const NumericInput = (props) => {
  const { value: valueProp, onChange, onBlur, ...rest } = props;

  const handleOnChange = (e) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-')
      onChange(e);
  };

  // '.' at the end or only '-' in the input box.
  const handleOnBlur = (e) => {
    let valueTemp = e.target.value;
    if (valueTemp.charAt(valueTemp.length - 1) === '.' || valueTemp === '-') {
      valueTemp = valueTemp.slice(0, -1);
    }
    onChange({
      ...e,
      target: { ...e.target, value: valueTemp.replace(/0*(\d+)/, '$1') },
    });
    onBlur(e);
  };

  return (
    <Input
      {...rest}
      value={valueProp}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
    />
  );
};

NumericInput.defaultProps = {
  value: '',
  onChange: () => null,
  onBlur: () => null,
};

NumericInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default NumericInput;