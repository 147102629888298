import { Col, Form, Input, Row, Select } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectCategoryOptions } from '../../../../store/selectors/bootstrapSelector';
import useStepUpdater from '../../hooks/useStepUpdater';
import { SurveyContext } from '../../Survey';
import { GENERAL_STEP, GREETING_STEP, MAP_STEP } from '../../Survey.context';
import { SurveyStepActions } from '../SurveyStepActions';
import GeneralStepContant from './GeneralStepContact';

export function SurveyGeneralStep() {
  const { surveyData, changeSurveyStep } = useContext(SurveyContext);

  const [onStepValuesChange] = useStepUpdater(GENERAL_STEP);

  const [isOtherSelected, setIsOtherSelected] = useState(null);

  const { categoriesOptions } = useSelector((store) => ({
    categoriesOptions: selectCategoryOptions(store),
  }));

  const [form] = Form.useForm();

  const valuesChangeWithValidation = useCallback(
    (changedValues) => {
      const formErrorsCount = form
        .getFieldsError()
        .filter(({ errors }) => errors.length).length;

      if (formErrorsCount === 0) {
        onStepValuesChange(changedValues);
      }
    },
    [form, onStepValuesChange],
  );

  // Check if PPR is other on first page load
  useEffect(() => {
    if (surveyData.general?.general_notice === 99 && isOtherSelected === null) {
      setIsOtherSelected(99);
    }
  }, [isOtherSelected, surveyData]);

  return (
    <Form
      layout="vertical"
      form={form}
      onValuesChange={valuesChangeWithValidation}
      initialValues={surveyData.general}
    >
      <Row gutter={32}>
        <Col 
          span={12}
          md={12}
          sm={24}
          xs={24}
        >
          <Form.Item
            name="name"
            label="Site name"
            required
            rules={[
              {
                min: 3,
                max: 100,
                message: 'Must be between 3 and 100 characters',
              },
            ]}
          >
            <Input placeholder="Tattingstone Helipad" />
          </Form.Item>
        </Col>
        <Col 
          span={12}
          md={12}
          sm={24}
          xs={24}
        >
          <Form.Item name="category_id" label="Category" required>
            <Select placeholder="Choose" options={categoriesOptions}/>
          </Form.Item>
        </Col>
      </Row>

      <GeneralStepContant
        __unsafe_contentAlongsideEmail={
          [3, 7].includes(surveyData?.general?.category_id) && (
            <Form.Item
              name="icao"
              label="ICAO"
              rules={[
                {
                  min: 1,
                  max: 4,
                  message: 'Must be between 1 and 4 characters',
                },
              ]}
            >
              <Input placeholder="EGTT" />
            </Form.Item>
          )
        }
      />

      <Row gutter={32}>
        <Col 
          span={12}
          md={12}
          sm={24}
          xs={24}
        >
          <Form.Item
            name="general_notice"
            label="Landing Notice Required (PPR)"
            required
          >
            <Select
              placeholder="Please select"
              options={[
                { value: 1, label: 'Short Notice' },
                { value: 2, label: '24 Hours' },
                { value: 3, label: '48 Hours' },
                { value: 99, label: 'Other' },
              ]}
              onChange={(value) => setIsOtherSelected(value)}
            />
          </Form.Item>
        </Col>
        <Col 
          span={12}
          md={12}
          sm={24}
          xs={24}
        >
          <Form.Item
            name="general_notice_comment"
            className={isOtherSelected !== 99 ? 'transparent-label' : ''}
            label="PPR Details"
            required={isOtherSelected === 99}
            rules={[
              {
                min: 3,
                max: 200,
                message: 'Must be between 3 and 200 characters',
              },
            ]}
          >
            <Input.TextArea
              className="survey-textarea max-100 scrollbar-thin"
              autoSize
              showCount
              maxLength={200}
              placeholder="Please give details"
            />
          </Form.Item>
        </Col>
      </Row>
      <SurveyStepActions
        backClick={changeSurveyStep(GREETING_STEP)}
        skipClick={changeSurveyStep(MAP_STEP)}
        nextClick={changeSurveyStep(MAP_STEP)}
      />
    </Form>
  );
}
