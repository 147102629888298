import { Button, notification } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import api from '../../utils/appApi';
import getApiErrorMessages from '../../utils/getApiErrorMessages';
import { quickAccessSetPad } from '../../store/actions/padQuickAccessAction';
import { useDispatch } from 'react-redux';

export default function QuickActions(props) {
  const { pad, fetchPad } = props;
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const alertRef = React.useRef();
  const dispatch = useDispatch();

  React.useEffect(() => {
    setVisible(!pad?.is_owned_by_current_user);
  }, [pad]);

  const dismissHandler = () => setVisible(false);

  const requestHanlder = async () => {
    try {
      setLoading(true);

      await api.pad.claim(pad?.id);

      if (fetchPad) {
        api.pad.get(pad?.id)
          .then((res) => {
            console.log(res.data.data);
            quickAccessSetPad(res.data.data)(dispatch);
          })
      }

      history.replace('/my-sites');
    } catch (error) {
      notification.error({
        message: 'Error',
        description: getApiErrorMessages(error),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <CSSTransition in={visible} timeout={200} nodeRef={alertRef} unmountOnExit>
      <div className="pad-quick-actions" ref={alertRef}>
        <p className="mb-0">To edit this pad you must first claim it</p>
        <div className="d-flex mt-2">
          <Button
            size="small"
            type="primary"
            onClick={requestHanlder}
            loading={loading}
          >
            Claim Pad
          </Button>
          <Button
            size="small"
            className="ml-2 secondary-btn"
            onClick={dismissHandler}
          >
            Dismiss
          </Button>
        </div>
      </div>
    </CSSTransition>
  );
}

QuickActions.propTypes = {
  pad: PropTypes.object,
  fetchPad: PropTypes.bool,
};

QuickActions.defaultProps = {
  pad: {},
  fetchPad: false
};
