import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const UnfinishedRegistration = (props) => {
  const { nextRule: NextRule, ...rest } = props;
  return (
    <Route
      render={() =>
        (!props.completedAt && <Redirect to="/registration-flow" />) ||
        (NextRule && <NextRule {...rest} />) || <props.component {...props} />
      }
    />
  );
};

UnfinishedRegistration.defaultProps = {
  completedAt: null,
  nextRule: null,
};

UnfinishedRegistration.propTypes = {
  completedAt: PropTypes.string,
  nextRule: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.func,
  ]),
};

const mapStateToProps = (store) => ({
  completedAt: store.user.payload.completed_at,
});

export default connect(mapStateToProps)(UnfinishedRegistration);
