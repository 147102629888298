import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { Button, Carousel } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as SurveyLogo } from '../../assets/icons/survey/logo.svg';
import FeatureList from './FeatureList';

const CarouselBanner = (props) => {

  const { type, showCarousel, setShowCarousel } = props;

  // responsive hidden banner
  const [width, setWidth] = useState(window.innerWidth);
  const [bannerVisible, setBannerVisible] = useState(true);


  const { banner } = useSelector((state) => ({
    banner: state.banner,
  }));

  if (type === 'carousel')
    setShowCarousel(JSON.parse(localStorage.getItem('carousel')));


  // get width window size
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // hidden CarouselBanner width < 576px
  useEffect(() => {
    if (width <= 576) {
      setBannerVisible(false);
    } else {
      setBannerVisible(true);
    }
  }, [width]);

  const content = {
    contentItem1: (
      <div className="carousel-banner__content">
        {type === 'carousel' && (
          <div className="text-center">
            <SurveyLogo className="carousel-banner__icon" />
            <h2 className="carousel-banner__text m-0">
              {' '}
              Helipaddy 360 Survey{' '}
            </h2>
          </div>
        )}
        <div
          className={`carousel-banner__item-carousel carousel-banner__text-info ${type === 'carousel' ? 'm-0' : ''
            }`}
        >
          <p className={`p-main ${type === 'carousel' ? 'm-0' : ''}`}>
            The idea here is to give you as the site owner, the ability to pass
            on more in depth details about the landing area directly to pilots.
            It&#39;s a feature that&#39;s been highly requested, so pilots can
            feel as though they&#39;ve visited a site before landing there.
          </p>
          <p className={`${type === 'carousel' ? 'm-0' : ''}`}>
            We suggest putting aside <b>15 minutes</b> to complete the survey.
            You don&#39;t have to be a pilot, a surveyor, or know any flying
            lingo to complete it. We&#39;ve broken it down into 7 simple
            sections for you and we&#39;ll guide you through each one. A
            completed survey will make your site far more appealing to pilots,
            as well as enabling a much safer operation from both sides.
          </p>
          <p className={`${type === 'carousel' ? 'm-0' : ''}`}>
            Any Questions, please don&#39;t hesitate to
            <a href="mailto:support@helipaddy.com" className="mr-4 contact-us">
              contact us.
            </a>
          </p>
        </div>
      </div>
    ),
    contentItem2: (
      <div className="carousel-banner__content">
        <SurveyLogo className="carousel-banner__icon" />
        <div className="carousel-banner__sub-text">
          {type === 'carousel'
            ? 'The seven steps to a professional-level survey'
            : 'Get to know our feature, that’s been highly requested '}
        </div>
        <h2 className="carousel-banner__text">Helipaddy 360 Survey</h2>
        <FeatureList />
      </div>
    ),
  };

  return type === 'carousel' ? (
    <>
      {banner.is_banner_displayed && (
        <div className="carousel-banner">
          <Button
            type="text"
            icon={<CloseOutlined />}
            size="small"
            className="carousel-banner__btn-close"
            onClick={() => {
              setShowCarousel(false);
              localStorage.removeItem('carousel');
            }}
          />
          <Carousel
            arrows
            nextArrow={<CaretLeftOutlined />}
            prevArrow={<CaretRightOutlined />}
          >
            <div>{content.contentItem1}</div>
            <div>{content.contentItem2}</div>
          </Carousel>
        </div>
      )}
    </>
  ) : (
    <div className="carousel-banner">
      {content.contentItem1}
      {content.contentItem2}
    </div>
  );
};

CarouselBanner.propTypes = {
  type: PropTypes.string,
  showCarousel: PropTypes.bool,
  setShowCarousel: PropTypes.func,
};

export default CarouselBanner;
