import {
  REGISTRATION_FLOW_CLEAN,
  REGISTRATION_FLOW_PUSH,
} from '../actionTypes';

const initialState = {};

const registrationFlow = (state = initialState, action = {}) => {
  switch (action.type) {
    case REGISTRATION_FLOW_PUSH:
      return {
        ...state,
        ...action.payload,
      };
    case REGISTRATION_FLOW_CLEAN:
      return initialState;
    default:
      return state;
  }
};

export default registrationFlow;
