export const validateFormValues = (formValues) => {
  const { hazards_relevant_categories, hazards_comment } = formValues;

  if (hazards_relevant_categories?.length) {
    if (hazards_relevant_categories.includes(99) && !hazards_comment) {
      return false;
    }

    return true;
  }

  return false;
};
