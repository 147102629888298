import {
    BANNER_SHOW
  } from '../actionTypes';
  
  const initialState = {
    has_uncompleted_surveys: false,
    is_banner_displayed: false,
    survey_count: 0
  };
  
  const banner = (state = initialState, action = {}) => {
    switch (action.type) {
      case BANNER_SHOW:
        return {
            ...state,
            ...action.payload,        
        };
      default:
        return state;
    }
  };
  
  export default banner;