import PropTypes from 'prop-types';

const SectionTitle = ({ children }) => (
  <div className="section-title"> {children} </div>
);

SectionTitle.defaultProps = {
  children: null,
};

SectionTitle.propTypes = {
  children: PropTypes.any,
};

export default SectionTitle;
