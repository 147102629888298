import { Button } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

export function SurveyStepActions(props) {
  const {
    backClick,
    skipClick,
    nextClick,
    backText,
    skipText,
    nextText,
  } = props;

  // get width window size
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="survey-step__actions w-100 d-flex justify-content-between">
      <Button
        className="secondary-btn"
        onClick={backClick}
        // add margin Bottom for mobile dynamically
        style={{ marginBottom: !skipClick && width <= 480 ? '20px' : '0px' }}
      >
        {backText}
      </Button>
      {skipClick && (
        <Button type="text" onClick={skipClick}>
          {skipText}
        </Button>
      )}
      <Button type="primary" onClick={nextClick}>
        {nextText}
      </Button>
    </div>
  );
}

SurveyStepActions.propTypes = {
  backClick: PropTypes.func,
  skipClick: PropTypes.func,
  nextClick: PropTypes.func,
  backText: PropTypes.string,
  skipText: PropTypes.string,
  nextText: PropTypes.string,
};

SurveyStepActions.defaultProps = {
  backText: 'Previous',
  skipText: 'Skip',
  nextText: 'Save & Continue',
};
