import { LoadingOutlined } from '@ant-design/icons';
import { Button, notification, Spin } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';

import AppleStore from '../../assets/images/apple-store-badge.svg';
import QrCode from '../../assets/images/dynamic_app_qr.png';
import GooglePlay from '../../assets/images/google-play-badge.svg';
import useQuery from '../../hooks/useQuery';
import { logoutWithoutRedirect } from '../../store/actions/userAction';
import api from '../../utils/appApi';
import getApiErrorMessages from '../../utils/getApiErrorMessages';
import isValidEmail from '../../utils/isValidEmail';

const content = {
  mobileText:
    'All done! We&apos;ve added 3 months of free premium access to your account! Click below to open the Helipaddy app!',
  desktopText: (
    <>
      All done! We&apos;ve added <b>3 months</b> of free premium access to your
      account!
      <br />
      Scan the QR code or tap below to start exploring!
    </>
  ),
};

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: '#b51452' }} spin />
);

const Free3Premium = () => {
  const { isAuthorized } = useSelector((store) => ({
    isAuthorized: store?.user?.isAuthorized,
  }));
  const [activating, setActivating] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const dispatch = useDispatch();
  const query = useQuery();

  useEffect(() => {});

  useMount(() => {
    if (isAuthorized) {
      logoutWithoutRedirect(false)(dispatch);
    }
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      )
    ) {
      setIsMobile(true);
    }
  });

  const addUserPremium = useCallback(async () => {
    const email = query.get('email');

    if (isValidEmail(email)) {
      setActivating(true);

      try {
        await api.user.addFreePremium(encodeURIComponent(email));
      } catch (error) {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(error),
        });
      } finally {
        setActivating(false);
      }
    }
  }, [query]);

  useMount(addUserPremium);

  return (
    <div className="activation-page free-premium position-relative">
      <div className="activation-page_content">
        {activating && (
          <Spin className="activation-loader" indicator={antIcon} />
        )}
        <p className="content-descrp">
          {isMobile ? content.mobileText : content.desktopText}
        </p>
        {!isMobile && (
          <img src={QrCode} alt="Helipaddy Apps" width={140} height={140} />
        )}
        <div className="choose-role_warning">
          {!isMobile ? (
            <div className="warnings justify-content-center">
              <a
                href="https://apps.apple.com/gb/app/helipaddy-heli-landing-sites/id894316463"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="App Store" className="app-store" src={AppleStore} />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.helipaddy.android&hl=en_GB&gl=US"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="Google Play" src={GooglePlay} />
              </a>
            </div>
          ) : (
            <Button
              type="primary"
              className="mt-2 d-inline-flex justify-content-center align-items-center"
              href="http://onelink.to/j6jka5"
            >
              Open Helipaddy
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Free3Premium;
