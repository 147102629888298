import PropTypes from 'prop-types';

import { ReactComponent as SurveyLogo } from '../../../../assets/icons/survey/logo.svg';

export function SurveyGreetingHeader(props) {
  const { padName } = props;

  return (
    <div className="survey-greeting__header">
      <div className="survey-greeting__logo">
        <SurveyLogo />
      </div>

      <h4 className="survey-greeting__subtitle">
        Welcome to Helipaddy 360 Survey for
      </h4>

      {padName && <h1 className="survey-greeting__title">{padName}</h1>}
    </div>
  );
}

SurveyGreetingHeader.propTypes = {
  padName: PropTypes.string,
};
