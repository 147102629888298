import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Form, Input, Modal, notification, Row, Select } from 'antd';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ApproachIcon } from '../../../../assets/icons/survey/approach.svg';
import { ReactComponent as FacilitiesIcon } from '../../../../assets/icons/survey/facilities.svg';
import { ReactComponent as GeneralIcon } from '../../../../assets/icons/survey/general.svg';
import { ReactComponent as HazardsIcon } from '../../../../assets/icons/survey/hazards.svg';
import { ReactComponent as LandingIcon } from '../../../../assets/icons/survey/landing.svg';
import { ReactComponent as MapIcon } from '../../../../assets/icons/survey/map.svg';
import { ReactComponent as PadViewIcon } from '../../../../assets/icons/survey/pad-view.svg';
import { quickAccessIsSurveySubmit } from '../../../../store/actions/padQuickAccessAction';
import useStepUpdater from '../../hooks/useStepUpdater';
import { SurveyContext } from '../../Survey';
import { APPROACH_STEP, FACILITIES_STEP } from '../../Survey.context';
import { SurveyStepActions } from '../SurveyStepActions';
import FacilitiesStepCategories from './FacilitiesStepCategories';

const getIsFeesApplyChecked = (padCategoryId, categories = []) => {
  if (padCategoryId === 7) {
    return categories?.includes(13); // id Landing fees may apply (facilities)
  }

  return categories?.includes(6); // id Landing fees may apply (warnings)
};

const getIsOtherSelected = (categories = []) => {
  return categories.includes(99); // Other category id is 99
};

export function SurveyFacilitiesStep() {
  const { surveyData, changeSurveyStep } = React.useContext(SurveyContext);

  const [isOtherSelected, setIsOtherSelected] = useState(
    getIsOtherSelected(surveyData?.facilities?.facilities_categories),
  );

  const [onStepValuesChange] = useStepUpdater(FACILITIES_STEP);

  const [form] = Form.useForm();

  const stepValuesChangeHandler = useCallback(
    (changedValues) => {
      const parsedChangedValues = { ...changedValues };

      const shouldUpdateImmediately = Object.prototype.hasOwnProperty.call(
        changedValues,
        'facilities_categories',
      );

      if (shouldUpdateImmediately) {
        setIsOtherSelected(
          getIsOtherSelected(changedValues?.facilities_categories),
        );
      }

      if (
        shouldUpdateImmediately &&
        !getIsFeesApplyChecked(
          surveyData?.general?.category_id,
          changedValues?.facilities_categories,
        )
      ) {
        parsedChangedValues.facilities_landing_range = null;
        parsedChangedValues.facilities_landing_fee_comment = '';

        form.setFieldsValue({
          facilities_landing_range: null,
          facilities_landing_fee_comment: '',
        });
      }

      onStepValuesChange(parsedChangedValues, {
        immediately: shouldUpdateImmediately,
      });
    },
    [form, onStepValuesChange, surveyData.general.category_id],
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const confirmPadSubmit = async () => {

    const modalContent = () => (
      <>
        {!surveyData?.general?.is_completed &&
          <div className="confirm-modal">
            <GeneralIcon />
            <span className="bar-step-title">General</span>
          </div>}
        {!surveyData?.map?.is_completed &&
          <div className="confirm-modal">
            <MapIcon />
            <span className="bar-step-title">Map</span>
          </div>}
        {!surveyData?.landing?.is_completed &&
          <div className="confirm-modal">
            <LandingIcon />
            <span className="bar-step-title">Landing</span>
          </div>}
        {!surveyData?.view?.is_completed &&
          <div className="confirm-modal">
            <PadViewIcon />
            <span className="bar-step-title">360 Pad View</span>
          </div>}
        {!surveyData?.hazards?.is_completed &&
          <div className="confirm-modal">
            <HazardsIcon />
            <span className="bar-step-title">Hazards</span>
          </div>}
        {!surveyData?.approach?.is_completed &&
          <div className="confirm-modal">
            <ApproachIcon />
            <span className="bar-step-title">Procedures</span>
          </div>}
        {!surveyData?.facilities?.is_completed &&
          <div className="confirm-modal">
            <FacilitiesIcon />
            <span className="bar-step-title">Features</span>
          </div>}
      </>
    );

    return Modal.confirm({
      className: 'confirm-modal_modal-survey',
      title: `Thank you for submitting your 360 Survey. This won't be marked as 100% complete because you still have some unanswered questions in:`,
      icon: <ExclamationCircleOutlined />,
      width: 700,
      content: modalContent(),
      okText: 'Submit',
      cancelText: 'Go Back',
      onOk: () => {
        notification.success({
          message: 'Success',
          description: 'Thank You!',
        });
        quickAccessIsSurveySubmit(true)(dispatch);
        history.push('/survey-360');
      },
    });
  };

  const onSubmitSurvey = () => {
    if (surveyData?.general?.is_completed &&
      surveyData?.map?.is_completed &&
      surveyData?.landing?.is_completed &&
      surveyData?.view?.is_completed &&
      surveyData?.hazards?.is_completed &&
      surveyData?.approach?.is_completed &&
      surveyData?.facilities?.is_completed) {
      notification.success({
        message: 'Success',
        description: 'Thank You!',
      });
      quickAccessIsSurveySubmit(true)(dispatch);
      history.push('/survey-360');
    } else {
      confirmPadSubmit()
    }
  };

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={stepValuesChangeHandler}
        initialValues={{
          ...surveyData?.facilities,
          facilities_landing_range:  typeof surveyData?.facilities?.facilities_landing_range === "number" ? surveyData?.facilities?.facilities_landing_range : null,
        }}
        >
        <FacilitiesStepCategories />
        <Form.Item
          name="facilities_comment"
          label="Features details"
          required={isOtherSelected}
          className="mt-4"
          rules={[
            {
              min: 3,
              max: 500,
              message: 'Must be between 3 and 500 characters',
            },
          ]}
        >
          <Input.TextArea
            className="survey-textarea max-100 scrollbar-thin"
            autoSize
            showCount
            maxLength={500}
            placeholder="Please provide any further details here"
          />
        </Form.Item>
        {getIsFeesApplyChecked(
          surveyData?.general?.category_id,
          surveyData?.facilities?.facilities_categories,
        ) && (
            <Row gutter={32}>
              <Col
                span={8}
                sm={8}
                xs={24}
              >
                <Form.Item
                  name="facilities_landing_range"
                  label="Range of fee"
                  required
                >
                  <Select placeholder="Select range">
                    <Select.Option value={1}>Cheap (£1 to £49)</Select.Option>
                    <Select.Option value={2}>Expensive (£49+)</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col
                span={16}
                sm={16}
                xs={24}
              >
                <Form.Item
                  name="facilities_landing_fee_comment"
                  className="transparent-label block-hidden-sm"
                  label="PPR Details"
                  rules={[
                    {
                      min: 1,
                      max: 200,
                      message: 'Must be between 1 and 200 characters',
                    },
                  ]}
                >
                  <Input.TextArea
                    className="survey-textarea max-100 scrollbar-thin"
                    autoSize
                    showCount
                    placeholder="Please provide details"
                    maxLength={200}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        <SurveyStepActions
          backClick={changeSurveyStep(APPROACH_STEP)}
          nextClick={onSubmitSurvey}
          nextText="Submit"
        />
      </Form>
    </>
  );
}
