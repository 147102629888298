import { Form, Input, Modal, Typography } from 'antd';
import React, { useCallback } from 'react';
import { useMount } from 'react-use';

import { ReactComponent as ExclamationIcon } from '../../../../assets/icons/exclamation.svg';
import MapWithSinglePad from '../../../../components/mapWithSinglePad';
import SearchLocation from '../../../../components/searchLocation';
import getAddressComponentByName from '../../../../utils/getAddressComponentByName';
import useStepUpdater from '../../hooks/useStepUpdater';
import { SurveyContext } from '../../Survey';
import { GENERAL_STEP, LANDING_STEP, MAP_STEP } from '../../Survey.context';
import { SurveyStepActions } from '../SurveyStepActions';

let geocoderService;

const getGeoCodeByLatLng = (lat, lng) =>
  new Promise((resolve, reject) =>
    geocoderService.geocode(
      {
        location: { lat: parseFloat(lat), lng: parseFloat(lng) },
      },
      (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK)
          return resolve(results);
        return reject();
      },
    ),
  );

export function SurveyMapStep() {
  const { surveyData, changeSurveyStep } = React.useContext(SurveyContext);
  const [onStepValuesChange] = useStepUpdater(MAP_STEP);

  const [map, setMap] = React.useState();
  const [location, setLocation] = React.useState(null); //LOCATION ON MAP
  const [locationFromSelect, setLocationFromSelect] = React.useState(null); //LOCATION FROM SEARCH INPUT

  const [isModalDisplayed, setIsModalDisplayed] = React.useState(false);
  const [isDisplayWarning, setIsDisplayWarning] = React.useState(false); // DISPLAY WARNING is_address_split
  const [clickInModal, setClickInModal] = React.useState(null);

  const [form] = Form.useForm();

  const mapOnReadyHandle = React.useCallback((_, _map) => setMap(_map), []);

  useMount(() => {
    if (surveyData.map?.location?.latitude) {
      setLocation({
        lat: surveyData.map?.location?.latitude,
        lng: surveyData.map?.location?.longitude,
      });
      setIsDisplayWarning(surveyData.map?.location?.is_address_split);
      setLocationFromSelect({
        ...surveyData.map?.location,
      });
      
      if (!surveyData.map.is_completed) {
        onStepValuesChange({ map_is_completed: true }, { immediately: true });
      };
    }
  });

  const onLocationChange = React.useCallback(
    (_, pin) => {
      const [lat, lng] = [pin.position.lat(), pin.position.lng()];

      getGeoCodeByLatLng(lat, lng).then((results) => {
        if (results.length) {
          const value = {
            name: results[0].formatted_address,
            latitude: lat,
            longitude: lng,
            country: getAddressComponentByName(
              results[0].address_components,
              'country',
            ).long_name,
            country_short: getAddressComponentByName(
              results[0].address_components,
              'country',
            ).short_name,
            county: getAddressComponentByName(
              results[0].address_components,
              'administrative_area_level_2',
            ).long_name,
          };

          setLocation({ lat, lng });

          onStepValuesChange({
            location: {
              ...value,
              latitude: lat,
              longitude: lng,
              name: form.getFieldValue('location')?.name,
              is_address_split: false,
            },
          });

          setIsModalDisplayed(true);
        }
      });
    },
    [onStepValuesChange, form],
  );

  // Initialize Google Maps Geocoder service
  React.useEffect(() => {
    if (map || locationFromSelect) {
      geocoderService = new window.google.maps.Geocoder();
    }
  }, [map, locationFromSelect]);

  const searchLocationAndGetLocationByName = useCallback((name) => {
    if (name) {
      return new Promise((resolve, reject) =>
        geocoderService.geocode(
          {
            address: name,
          },
          (results, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK)
              return resolve({
                address: results[0].formatted_address,
                latitude: results[0].geometry.location.lat(),
                longitude: results[0].geometry.location.lng(),
                country: getAddressComponentByName(
                  results[0].address_components,
                  'country',
                ).long_name,
                country_short: getAddressComponentByName(
                  results[0].address_components,
                  'country',
                ).short_name,
                county: getAddressComponentByName(
                  results[0].address_components,
                  'administrative_area_level_2',
                ).long_name,
              });
            return reject();
          },
        ),
      );
    }

    return Promise.resolve({
      address: '',
      latitude: 0,
      longitude: 0,
      country: '',
      country_short: '',
      county: '',
      name: '',
    });
  }, []);

  React.useEffect(() => {
    if (clickInModal === 'ok') {
      searchLocationAndGetLocationByName(
        form.getFieldValue('location')?.name || surveyData.map?.location?.name,
      ).then((res) => {
        setLocation((prev) => ({
          ...prev,
          lat: res.latitude,
          lng: res.longitude,
        }));

        onStepValuesChange({
          location: {
            ...res,
            latitude: res.latitude,
            longitude: res.longitude,
            name: res.address,
            is_address_split: false,
          },
        });
      });

      setIsModalDisplayed(false);
      setIsDisplayWarning(false);
    }

    if (clickInModal === 'cancel') {
      setIsDisplayWarning(true);
      setIsModalDisplayed(false);

      onStepValuesChange({
        location: {
          ...location,
          latitude: location.lat,
          longitude: location.lng,
          name: form.getFieldValue('location')?.name,
          address: form.getFieldValue('location')?.name,
          is_address_split: true,
        },
      });
    }

    setClickInModal(null);
  }, [
    clickInModal,
    locationFromSelect,
    onStepValuesChange,
    location,
    searchLocationAndGetLocationByName,
    form,
    surveyData,
  ]);

  const onClickModalOk = useCallback(() => {
    setIsModalDisplayed(false);
    setClickInModal('ok');
  }, []);

  const onClickModalCancel = useCallback(() => {
    setIsModalDisplayed(false);
    setClickInModal('cancel');
  }, []);

  // change oldLocation if search input is changed
  const onSelectValue = (e) => {
    if (e?.target?.value?.latitude && e?.target?.value?.longitude) {
      setLocationFromSelect((prev) => ({
        ...prev,
        ...e.target.value,
      }));
      setIsModalDisplayed(true);
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onValuesChange={onStepValuesChange}
        initialValues={surveyData.map}
      >
        <Form.Item
          name="location"
          label="Address"
          trigger="onSelect"
          validateTrigger="onSelect"
          required
        >
          <SearchLocation autoComplete="new-password" onSelect={onSelectValue}>
            <Input.TextArea autoSize />
          </SearchLocation>
        </Form.Item>
      </Form>
      {isDisplayWarning && (
        <div className="survey-step__text-info">
          <ExclamationIcon className="icon" />
          <Typography.Text>
            The address does not match the coordinates
          </Typography.Text>
        </div>
      )}
      <h3 className="survey-step__title">
        The image will be displayed on the survey.{' '}
        <strong>Please move the pin to the precise landing spot.</strong>
      </h3>
      <MapWithSinglePad
        onReady={mapOnReadyHandle}
        map={map}
        location={location}
        locationOnDragend={onLocationChange}
        height={510}
        zoom={10}
        mapTypeId="satellite"
      />
      <SurveyStepActions
        backClick={changeSurveyStep(GENERAL_STEP)}
        skipClick={changeSurveyStep(LANDING_STEP)}
        nextClick={changeSurveyStep(LANDING_STEP)}
      />

      <Modal
        closeIcon={false}
        maskClosable={false}
        centered
        visible={isModalDisplayed}
        onOk={onClickModalOk}
        onCancel={onClickModalCancel}
        className="survey-confirmation-modal default-footer"
        cancelText="No"
        okText="Yes"
        cancelButtonProps={{
          className: 'outline-btn survey-outline',
        }}
      >
        <div className="survey-confirmation-content font-size-small">
          <h3>
            The calculated address of the pin is slightly different to the
            address you entered. Do you want us to move the pin? The pin
            position should always be the exact landing location so choose No if
            you are happy with it.
          </h3>
        </div>
      </Modal>
    </>
  );
}
