import { Button, Form, Input, notification, Popover } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateUser } from '../../../../store/actions/userAction';
import api from '../../../../utils/appApi';
import getApiErrorMessages from '../../../../utils/getApiErrorMessages';

export default function EmailVerification() {
  const { user } = useSelector((state) => ({
    user: state?.user?.payload,
  }));
  const [reSendEmailLoading, setReSendEmailLoading] = React.useState(false);
  const [changeEmailLoading, setChangeEmailLoading] = React.useState(false);
  const dispatch = useDispatch();

  const reSendEmailHandler = React.useCallback(async () => {
    setReSendEmailLoading(true);

    try {
      await api.user.notify();

      notification.success({
        message: 'Success',
        description: 'Email was sent successfully!',
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: getApiErrorMessages(error),
      });
    } finally {
      setReSendEmailLoading(false);
    }
  }, []);

  const changeEmailHandler = React.useCallback(
    async ({ new_email }) => {
      setChangeEmailLoading(true);

      try {
        await dispatch(updateUser({ email: new_email }));
        notification.success({
          message: 'Success',
          description: 'Email was successfully changed!',
        });
      } catch (error) {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(error),
        });
      } finally {
        setChangeEmailLoading(false);
      }
    },
    [dispatch],
  );

  const ChangeEmailForm = (
    <Form onFinish={changeEmailHandler} autoComplete="off">
      <Form.Item
        name="new_email"
        rules={[{ type: 'email', message: 'Invalid email' }]}
      >
        <Input placeholder="Type new email" />
      </Form.Item>
      <Button type="text" htmlType="submit" className="auto-size-btn">
        Submit
      </Button>
    </Form>
  );

  return (
    <div className="personal-details">
      <div className="personal-details_inf">
        <p>
          We have sent you the activation link to{' '}
          <span className="mark">{user?.email}</span> <br /> Please check your
          inbox in order to proceed to the next step
        </p>
      </div>
      <div className="personal-details_actions">
        <Button.Group>
          <Popover trigger="click" content={ChangeEmailForm}>
            <Button className="secondary-btn" loading={changeEmailLoading}>
              Change Email
            </Button>
          </Popover>
          <Button
            type="primary"
            loading={reSendEmailLoading}
            onClick={reSendEmailHandler}
          >
            Re-Send Email
          </Button>
        </Button.Group>
      </div>
    </div>
  );
}
