import isValidEmail from '../../../../utils/isValidEmail';

export const contactTypes = {
  1: 'Pad Contact',
  4: 'Owner',
};

export const getUniqueContacts = (contacts = []) => {
  let uniqueContacts = [];

  uniqueContacts = contacts
    .filter((contact) => [1, 4].includes(contact.contact_type_id))
    .filter((contact) => !!contact.email);

  return uniqueContacts;
};

export const isValidContact = (contact) => {
  if (!contact.email || !contact.name || !contact.phone) return false;
  if (!isValidEmail(contact.email)) return false;

  return true;
};
