// USER
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_LOCAL = 'UPDATE_USER_LOCAL';

// REGISTRATION FLOW
export const REGISTRATION_FLOW_PUSH = 'PUSH_DATA';
export const REGISTRATION_FLOW_CLEAN = 'CLEAN_DATA';
export const REGISTRATION_FLOW_STORE = 'STORE_DATA';

// BOOTSTRAP
export const BOOTSTRAP = 'BOOTSTRAP';
export const BOOTSTRAP_PUSH = 'BOOTSTRAP_PUSH';

// LOADING
export const LOADING_BAR_ENABLE = 'LOADING_BAR_ENABLE';
export const LOADING_BAR_DISABLE = 'LOADING_BAR_DISABLE';

// PADS
export const USER_PADS_GET = 'USER_PADS_GET';
export const USER_PADS_CLEAN = 'USER_PADS_CLEAN';
export const USER_PADS_PUSH = 'USER_PADS_PUSH';
export const UPDATE_SINGLE_PAD = 'UPDATE_SINGLE_PAD';
export const REMOVE_SINGLE_PAD = 'REMOVE_SINGLE_PAD';
export const USER_PADS_PUSH_LIST = 'USER_PADS_PUSH_LIST';

// SINGLE PAD
export const QUICK_ACCESS_SET_PAD = 'QUICK_ACCESS_SET_PAD';
export const QUICK_ACCESS_SET_REVIEWS = 'QUICK_ACCESS_SET_REVIEWS';
export const QUICK_ACCESS_IS_SURVEY_SUBMIT = 'QUICK_ACCESS_IS_SURVEY_SUBMIT';
export const QUICK_ACCESS_TRIGGER_SURVEY_UPDATE = 'QUICK_ACCESS_TRIGGER_SURVEY_UPDATE';

// 360 SURVEY
export const SURVEY_SET = 'SURVEY_SET';
export const SURVEY_SUBMIT_STEP = 'SURVEY_SUBMIT_STEP';
export const SURVEY_CLEAN = 'SURVEY_CLEAN';
export const SURVEY_UPDATE = 'SURVEY_UPDATE';
export const SURVEY_UPDATE_STEP = 'SURVEY_UPDATE_STEP';
export const SURVEY_COUNTER_NEW = 'SURVEY_COUNTER_NEW';

// BANNER
export const BANNER_SHOW = 'BANNER_SHOW';

// pad landing tab
export const PAD_LANDINGS_COUNTER = 'PAD_LANDINGS_COUNTER';
export const PAD_LANDINGS_TOGGLE = 'PAD_LANDING_TOGGLE';
export const IS_UPDATE_DATA = 'IS_UPDATE_DATA';
export const PAD_LANDINGS_LIST = 'PAD_LANDINGS_LIST';
export const UPLOAD_PAD_LANDINGS_LIST = 'UPLOAD_PAD_LANDINGS_LIST';
export const UPDATE_PAD_ELEMENT_LIST = 'UPDATE_PAD_ELEMENT_LIST';
export const SURVEY_STATUS = 'SURVEY_STATUS';