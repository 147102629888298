import './styles/index.scss';

import {
  CaretDownOutlined,
  CaretUpOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { message, Popover, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useMount } from 'react-use';

import { ReactComponent as InfoCircleInfo } from '../../assets/icons/info-circle.svg';
import { ReactComponent as ExclamationIcon } from '../../assets/icons/exclamation.svg';
import Mail from '../../assets/icons/mail.svg';
import Phone from '../../assets/icons/phone.svg';
import Pin from '../../assets/icons/pin.svg';
import Twitter from '../../assets/icons/twitter-solid.svg';
import User from '../../assets/icons/user.svg';
import Website from '../../assets/icons/website.svg';
import Placeholder from '../../assets/images/placeholder-image.png';
import CardinalDirectionIcon from '../../components/CardinalDirectionIcon';
import FormattedText from '../../components/formattedText';
import HeaderL2 from '../../components/header-l2';
import MapWithSinglePad from '../../components/mapWithSinglePad';
import SliderModal from '../../components/sliderModal';
import { editPadEventOpts } from '../../events';
import useGaEvent from '../../hooks/useGAEvent';
import { getBanner } from '../../store/actions/bannerAction';
import { setIsUpdateData, setPadLandingsCounter, setPadLandingsToggle, setSurveyStatus } from '../../store/actions/padLandingsActions';
import {
  quickAccessSetPad,
  quickAccessSetReviews,
  quickAccessTriggerSurveySubmit,
} from '../../store/actions/padQuickAccessAction';
import Button from '../../uiKitComponents/button';
import Carousel from '../../uiKitComponents/carousel';
import Checkbox from '../../uiKitComponents/checkbox';
import Col from '../../uiKitComponents/col';
import Menu from '../../uiKitComponents/menu';
import notification from '../../uiKitComponents/notification';
import Rate from '../../uiKitComponents/rate';
import Row from '../../uiKitComponents/row';
import Spin from '../../uiKitComponents/spin';
import Tag from '../../uiKitComponents/tag';
import api from '../../utils/appApi';
import convertDMS from '../../utils/convertDMS';
import getApiErrorMessages from '../../utils/getApiErrorMessages';
import ExtraActions, { RequestHiscCode } from './extraActions';
import QuickActions from './quickActions';

const SinglePadPage = () => {
  const { pad_id } = useParams();
  const [map, setMap] = useState();
  const [location, setLocation] = useState({});
  const [loading, setLoading] = useState(false);
  const [pad, setPad] = useState(null);
  const [sliderModalStatus, setSliderModalStatus] = useState({
    active: 0,
    status: false,
  });
  const [padReviews, setPadReviews] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { padQuickAccess, loginUser, isUpdatedPadQuickAccess } = useSelector(
    (store) => ({
      padQuickAccess: store.padQuickAccess?.payload,
      isUpdatedPadQuickAccess: store.padQuickAccess?.payload?.isUpdate,
      loginUser: store.user?.payload?.id,
    }),
  );

  const [coOwners, setCoOwners] = useState([]);
  const [coOwnersShowMore, setCoOwnersShowMore] = useState(false);
  // filter co-owners from contacts list and set to state without current user
  useEffect(() => {
    setCoOwners(
      pad?.contacts?.filter(
        (contact) =>
          contact.contact_type_id === 4 && contact.user_id !== loginUser,
      ) || [],
    );
  }, [pad, loginUser]);

  const CoOwnerRow = ({ item }) => (
    <div
      className="data"
      key={`CoOwner-${item.id}`}
      style={{ marginBottom: '10px' }}
    >
      <span className="icon">
        <Popover
          title={item?.name}
          content={
            <div>
              <div>
                <span>Phone: </span>
                {item?.phone ? <span>{`${item?.phone}`}</span> : <span>-</span>}
              </div>
              <div>
                <span>Email: </span>
                {item?.email ? <span>{`${item?.email}`}</span> : <span>-</span>}
              </div>
            </div>
          }
        >
          <img src={User} alt="User" />
        </Popover>
      </span>
      <span className="text">
        <div>{`${item?.name || '-'}`}</div>
        <div>{`${item?.email || '-'}`}</div>
      </span>
    </div>
  );

  CoOwnerRow.propTypes = {
    item: PropTypes.shape({
      id: PropTypes.number,
      user_id: PropTypes.number,
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      contact_type_id: PropTypes.number,
      created_at: PropTypes.string,
      is_premium: PropTypes.bool,
      is_primary: PropTypes.bool,
    }),
  };

  // GA events
  const editPadEvent = useGaEvent(editPadEventOpts);

  const fetchErrorsHandler = (data) => {
    notification.error({
      message: 'Error',
      description: getApiErrorMessages(data.reason),
    });

    setTimeout(() => {
      history.push('/my-sites');
    }, 500);
  };

  const fetchPad = () => {
    Promise.allSettled([
      api.pad.get(pad_id),
      api.reviews.get(pad_id, { direction: 'desc', page: 1 }),
    ]).then(([fetchedPad, fetchedReviews]) => {
      if (fetchedPad.status === 'fulfilled') {
        setPad(fetchedPad.value.data.data);
        quickAccessSetPad(fetchedPad.value.data.data)(dispatch);
        setPadLandingsCounter(fetchedPad.value.data.data.landings_count)(dispatch);
        setPadLandingsToggle(fetchedPad.value.data.data.enabled_landings)(dispatch);
        setSurveyStatus({
          completedSurevyStep: fetchedPad.value.data.data.survey_completed_steps_count,
          surveyStatus: fetchedPad.value.data.data.survey_status,
        })(
          dispatch,
        );
        setIsUpdateData(true)(dispatch);
      } else {
        fetchErrorsHandler(fetchedPad);
      }

      if (fetchedReviews.status === 'fulfilled') {
        setPadReviews(fetchedReviews.value.data);
        quickAccessSetReviews(fetchedReviews.value.data)(dispatch);
      } else {
        setPadReviews(null);
      }
      setLoading(false);
    });
  };

  useMount(() => {
    if (pad_id) {
      setLoading(true);
      if (
        parseInt(pad_id, 10) === padQuickAccess.pad?.id &&
        padQuickAccess.reviews?.stars
      ) {
        setPad(padQuickAccess.pad);
        setPadReviews(padQuickAccess.reviews);
        setLoading(false);
      } else {
        fetchPad();
      }
    } else {
      history.push('/my-sites');
    }
  });

  useMount(() => {
    if (isUpdatedPadQuickAccess) {
      api.pad
        .get(pad_id)
        .then((res) => {
          quickAccessSetPad(res.data.data)(dispatch);
          setPadLandingsCounter(res.data.data.landings_count)(dispatch);
          setPadLandingsToggle(res.data.data.enabled_landings)(dispatch);
          setSurveyStatus({
            completedSurevyStep: res.data.data.survey_completed_steps_count,
            surveyStatus: res.data.data.survey_status,
          })(
            dispatch,
          );
          setIsUpdateData(true)(dispatch);
          setPad(res.data.data);
        })
        .finally(() => {
          quickAccessTriggerSurveySubmit(false)(dispatch);
        });
    }
  }, [isUpdatedPadQuickAccess]);

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      message.success('Link copied to clipboard');
    } catch (err) {
      message.error('Failed to copy text');
    }
  };

  const handleButtonClickCopyLink = () => {
    if (pad?.redirect_app_link) {
      copyToClipboard(pad.redirect_app_link);
    }
  };

  useEffect(() => {
    getBanner()(dispatch);
  }, [dispatch]);

  const getSiteEditButton = () => {
    if (!pad?.is_owned_by_current_user) {
      return null;
    }
    if (pad?.status_id === 2 || !pad?.is_owned_by_current_user) {
      const denyAccessToEdit = (e) => {
        e.preventDefault();
        notification.error({
          duration: 10,
          message: 'Error',
          description:
            "You cannot edit this pad currently as it's pending moderation",
        });
      };
      return (
        <Button className="secondary-btn" onClick={denyAccessToEdit}>
          Edit Pad
        </Button>
      );
    }
    if (!pad?.is_claim_approved) {
      const denyAccessToEdit = (e) => {
        e.preventDefault();
        notification.error({
          duration: 10,
          message: 'Error',
          description:
            "You cannot edit this pad currently as it's awaiting approval",
        });
      };
      return (
        <Button className="secondary-btn" onClick={denyAccessToEdit}>
          Edit Pad
        </Button>
      );
    }

    return (
      <Button type="primary" onClick={editPadEvent()}>
        <Link to={`/my-sites/edit/${pad_id}`}>Edit Pad</Link>
      </Button>
    );
  };

  const hideModalSlider = () =>
    setSliderModalStatus((prevState) => ({ ...prevState, status: false }));

  const getPadImagesForCarousel = useCallback(() => {
    if (pad && !loading) {
      if (pad?.uploads?.length) {
        return pad.uploads.map((img, idx) => (
          <div
            className="slider-slide direction-relative"
            key={img.id}
            aria-hidden="true"
            onClick={() =>
              setSliderModalStatus((prevState) => ({
                ...prevState,
                status: true,
                active: idx,
              }))
            }
          >
            <div className="image">
              <img src={img.file_url} alt={img.title} />
              {img.direction && (
                <CardinalDirectionIcon direction={img?.direction} width={45} />
              )}
            </div>
          </div>
        ));
      }
    }
    return (
      <div className="slider-placeholder slider-slide">
        <div className="image">
          <img alt="Placeholder" src={Placeholder} />
        </div>
      </div>
    );
  }, [loading, pad]);

  const getSiteEstablishments = () => {
    const establishments = [];

    if (pad) {
      if (pad?.warnings) {
        pad?.warnings.forEach((warn) => establishments.push(warn.display_name));
      }
      if (pad?.facilities) {
        pad?.facilities.forEach((facility) =>
          establishments.push(facility.display_name),
        );
      }
    }

    return establishments.map((establishment, idx) => (
      <li key={idx}>
        <Checkbox checked>{establishment}</Checkbox>
      </li>
    ));
  };

  const getSiteTags = () => {
    const tags = [];

    if (pad) {
      pad?.tags?.forEach((tag) => tags.push(tag.name));
    }

    return tags.map((tag, idx) => (
      <Tag color="orange" key={idx}>
        {tag}
      </Tag>
    ));
  };

  useEffect(() => {
    if (pad?.location) {
      setLocation({
        ...pad.location,
        address: pad.location.name,
        DMS: convertDMS(pad?.location.latitude, pad?.location.longitude),
      });
    }

    return () => setLocation({});
  }, [pad]);

  const mapOnReadyHandle = useCallback((mapProps, _map) => setMap(_map), []);

  const newPadLocation = useMemo(
    () =>
      !isNaN(location.latitude) && !isNaN(location.longitude)
        ? {
          lat: parseFloat(location.latitude),
          lng: parseFloat(location.longitude),
          withoutZoom: !!location.withoutZoom,
        }
        : null,
    [location.latitude, location.longitude, location.withoutZoom],
  );

  const updatePadUploads = (file) => {
    const fileIndex = pad.uploads.indexOf(file);

    setPad((prevState) => ({
      ...prevState,
      uploads: prevState.uploads.map((padFile, idx) =>
        idx === fileIndex ? { ...padFile, is_upload_reported: true } : padFile,
      ),
    }));

    setSliderModalStatus((prevState) => ({ ...prevState, active: fileIndex }));
  };

  if (loading || isUpdatedPadQuickAccess) {
    return (
      <div className="site-view d-flex align-items-center justify-content-center">
        <div className="d-flex align-items-center justify-content-center">
          <Spin />
        </div>
      </div>
    );
  }

  return (
    <div className="site-view">
      <HeaderL2 backPath="/my-sites">
        <div className="site-view_header-nav">
          <Menu mode="horizontal" defaultSelectedKeys={['single-site_view']}>
            <Menu.Item key="single-site_view">Site View</Menu.Item>
            <Menu.Item key="single-site_reviews">
              <Link to={`/my-sites/reviews/${pad_id}`}>Reviews & Ratings</Link>
            </Menu.Item>
            <Menu.Item key="single-site_landing">
              <Link to={`/my-sites/landing/${pad_id}`}>Landings</Link>
            </Menu.Item>
          </Menu>
        </div>
        <div className="site-view_header-actions">{getSiteEditButton()}</div>
      </HeaderL2>
      <div className="site-view_content">
        <div className="large-container">
          {!pad?.is_owned_by_current_user && <QuickActions pad={pad} fetchPad />}
          <Row>
            <Col>
              <h1 className="site-view_title v1-h">{pad?.name}</h1>
            </Col>
          </Row>
          <Row gutter={[{ xs: 0, sm: 0, md: 24, lg: 24 }, 24]}>
            <Col span={10} md={10} sm={24} xs={24}>
              <div className="site-view_slider">
                <Carousel autoplaySpeed={5000} arrows="true" autoplay>
                  {getPadImagesForCarousel(true)}
                </Carousel>
                <SliderModal
                  isModalVisible={sliderModalStatus.status}
                  onCancelHandler={hideModalSlider}
                  slides={pad?.uploads}
                  updatePadUploads={updatePadUploads}
                  activeIndex={sliderModalStatus.active}
                />
              </div>
              <div className="site-view_rating">
                <div className="rate-block-container">
                  <div className="rate-and-title-element d-flex justify-content-between align-items-center">
                    <div className="rate-label">Helifriendliness</div>
                    <div className="d-flex align-items-center">
                      <Rate
                        value={padReviews?.stars?.helifriendliness}
                        disabled
                        allowHalf
                      />
                      <div className="rate-value">
                        {padReviews?.stars?.helifriendliness} / 5
                      </div>
                    </div>
                  </div>
                  <div className="rate-and-title-element d-flex justify-content-between align-items-center">
                    <div className="rate-label">Landing</div>
                    <div className="d-flex align-items-center">
                      <Rate
                        value={padReviews?.stars?.landing}
                        disabled
                        allowHalf
                      />
                      <div className="rate-value">
                        {padReviews?.stars?.landing} / 5
                      </div>
                    </div>
                  </div>
                  <div className="rate-and-title-element d-flex justify-content-between align-items-center">
                    <div className="rate-label">Food</div>
                    <div className="d-flex align-items-center">
                      <Rate
                        value={padReviews?.stars?.food}
                        disabled
                        allowHalf
                      />
                      <div className="rate-value">
                        {padReviews?.stars?.food} / 5
                      </div>
                    </div>
                  </div>
                  <div className="rate-and-title-element d-flex justify-content-between align-items-center">
                    <div className="rate-label">Service</div>
                    <div className="d-flex align-items-center">
                      <Rate
                        value={padReviews?.stars?.service}
                        disabled
                        allowHalf
                      />
                      <div className="rate-value">
                        {padReviews?.stars?.service} / 5
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={13} md={13} sm={24} xs={24}>
              <div className="site-view_information mb-4">
                {pad?.is_claim_approved && (
                  <>
                    <Tooltip
                      title={pad?.redirect_app_link
                        ? 'Use this link on your website or in emails to share these site details via the Helipaddy pilot app'
                        : 'Pad is not displaying in the app - please review details'
                      }
                    >
                      <Button
                        className="mb-3 p-1 button-link"
                        icon={<LinkOutlined />}
                        disabled={!pad?.redirect_app_link}
                        onClick={handleButtonClickCopyLink}
                      >
                        Copy link to site
                      </Button>
                    </Tooltip>
                    <RequestHiscCode
                      hiscSlice={pad?.hisc?.substring(2, 8)}
                      icao={pad?.icao}
                    />
                  </>
                )}
                <h2 className="v1-h3 mb-3 h-25">Site Description</h2>
                <p>{pad?.site_information}</p>
                <Row gutter={14} justify="space-between">
                  <Col span={12} md={12} sm={12} xs={24}>
                    <div className="contact-inf mb-4">
                      <h2 className="v1-h3 mb-3 label">Phone Number</h2>
                      <div className="data">
                        <span className="icon">
                          <img src={Phone} alt="Phone" />
                        </span>
                        <span className="text">
                          {(pad?.contact?.phone && `${pad?.contact?.phone}`) ||
                            '-'}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col span={12} md={12} sm={12} xs={24}>
                    <div className="contact-inf mb-4">
                      <h2 className="v1-h3 mb-3 label">Email Address</h2>
                      <div className="data">
                        <span className="icon">
                          <img src={Mail} alt="Mail" />
                        </span>
                        <span className="text">
                          {pad?.contact?.email || '-'}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col span={12} md={12} sm={12} xs={24}>
                    <div className="contact-inf mb-4">
                      <h2 className="v1-h3 mb-3 label">Contact Person</h2>
                      <div className="data">
                        <span className="icon">
                          <img src={User} alt="User" />
                        </span>
                        <span className="text">
                          {pad?.contact?.name || '-'}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col span={12} md={12} sm={12} xs={24}>
                    <div className="contact-inf mb-4">
                      <h2 className="v1-h3 mb-3 label">Website</h2>
                      <div className="data">
                        <span className="icon">
                          <img src={Website} alt="Webiste" />
                        </span>
                        <span className="text">
                          {pad?.pad_social?.website || '-'}
                        </span>
                      </div>
                    </div>
                  </Col>
                  {coOwners.length > 0 && (
                    <Col span={24} md={24} sm={24} xs={24}>
                      <div className="contact-inf">
                        <h2 className="v1-h3 mb-3 label">Co-owner(s)</h2>

                        {coOwners?.map(
                          (item, index) =>
                            (coOwnersShowMore || index < 3) && (
                              <CoOwnerRow item={item} />
                            ),
                        )}
                        {coOwners?.length > 3 && (
                          <Button
                            type="link"
                            className="show-more-btn"
                            onClick={() =>
                              setCoOwnersShowMore(!coOwnersShowMore)
                            }
                            icon={
                              coOwnersShowMore ? (
                                <CaretUpOutlined />
                              ) : (
                                <CaretDownOutlined />
                              )
                            }
                          >
                            {coOwnersShowMore ? 'Show less' : 'Show more'}
                          </Button>
                        )}
                      </div>
                    </Col>
                  )}
                  <Col span={24} md={24} sm={24} xs={24}>
                    <div className="contact-inf">
                      <h2 className="v1-h3 mb-3 label">Twitter handle</h2>
                      <div className="data">
                        <span className="icon">
                          <img src={Twitter} alt="Twitter" />
                        </span>
                        <span className="text">
                          {pad?.pad_social?.twitter_handle
                            ? pad?.pad_social?.twitter_handle
                            : '-'}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              {pad?.category_id !== 1 && pad?.landing_advice && (
                <div className="site-view_notes">
                  <h2 className="v1-h3 mb-3">
                    {pad?.is_landing_advice_displayed
                      ? 'Landing Instructions'
                      : 'Landing Advice'}
                  </h2>
                  {pad?.landing_advice.split('\n').map((line, idx) => (
                    <p key={`${pad?.id}_l_${idx}`}>
                      <FormattedText text={line} />
                    </p>
                  ))}
                </div>
              )}
            </Col>
          </Row>
          <Row className="mb-4 mt-4" gutter={14}>
            {pad?.hisc && (
              <Col span={5} lg={5} md={10} sm={10} xs={10}>
                <div className="address-line">
                  <div className="d-flex align-items-center mb-3">
                    <h2 className="m-0 mr-1">HISC</h2>
                    <Tooltip
                      title="Helipaddy International Site Code"
                      className="tooltip-responsive"
                      overlayStyle={{ maxWidth: '500px' }}
                    >
                      <InfoCircleInfo style={{ fontSize: '5px' }} />
                    </Tooltip>
                  </div>
                  <div className="data">
                    <span className="text">{pad?.hisc}</span>
                  </div>
                </div>
              </Col>
            )}
            {pad?.icao && (
              <Col span={5} lg={5} md={10} sm={10} xs={10}>
                <div className="address-line">
                  <h2 className="v1-h3 mb-3">ICAO</h2>
                  <div className="data">
                    <span className="text">{pad?.icao}</span>
                  </div>
                </div>
              </Col>
            )}
          </Row>
          <div className="site-view_address">
            <Row justify="space-between" gutter={14}>
              <Col span={12} lg={12} md={24} sm={24} xs={24}>
                <div className="address-line">
                  <h2 className="v1-h3 mb-3">Address</h2>
                  <div className="data">
                    <span className="icon">
                      <img src={Pin} alt="Map Pin" />
                    </span>
                    <span className="text">{location.address}</span>
                  </div>
                </div>
                {!location || location?.is_address_split && (
                  <div className="contact-inf">
                    <div className="data">
                      <div className="survey-step__text-info start ml-1 mb-0">
                        <ExclamationIcon className="icon" />
                        <Typography.Text>
                          The address does not match the coordinates
                        </Typography.Text>
                      </div>
                    </div>
                  </div>
                )}
              </Col>
              <Col>
                <div className="address-line">
                  <h2 className="v1-h3 mb-3">Latitude</h2>
                  <div className="data">
                    <span className="text">{location.latitude}</span>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="address-line">
                  <h2 className="v1-h3 mb-3">Longitude</h2>
                  <div className="data">
                    <span className="text">{location.longitude}</span>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="address-line">
                  <h2 className="v1-h3 mb-3">DMS Format</h2>
                  <div className="data">
                    <span className="text">{location.DMS}</span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="site-view_map">
            <Row>
              <Col span={24}>
                <MapWithSinglePad
                  containerStyle={{ marginBottom: 24 }}
                  onReady={mapOnReadyHandle}
                  map={map}
                  location={newPadLocation}
                  height={372}
                />
              </Col>
            </Row>
          </div>
          {!!getSiteEstablishments().length && (
            <div className="site-view_establishments">
              <Row>
                <Col span={13} offset={11}>
                  <h2 className="v1-h3 mb-3">For Establishments Only</h2>
                  <ul className="establishments-list">
                    {getSiteEstablishments()}
                  </ul>
                </Col>
              </Row>
            </div>
          )}
          {!!getSiteTags().length && (
            <div className="site-view_tags">
              <Row>
                <Col span={13} offset={11}>
                  <h2 className="v1-h3 mb-3">Tags</h2>
                  <ul className="tags-list">{getSiteTags()}</ul>
                </Col>
              </Row>
            </div>
          )}
          <ExtraActions pad={pad} pad_id={pad_id} />
        </div>
      </div>
    </div>
  );
};

export default SinglePadPage;
