import { Button, Form, notification, Radio } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';

import AppleStore from '../../../../assets/images/apple-store-badge.svg';
import GooglePlay from '../../../../assets/images/google-play-badge.svg';
import { updateUser } from '../../../../store/actions/userAction';
import api from '../../../../utils/appApi';
import getApiErrorMessages from '../../../../utils/getApiErrorMessages';
import RegistrationFlowContext from '../../RegistrationFlow.context';

export function ChooseRoleStep() {
  const { redirectToStep, updateFlowActiveIndex } = React.useContext(
    RegistrationFlowContext,
  );
  const { user } = useSelector((state) => ({
    user: state.user?.payload,
  }));
  const [loading, setLoading] = React.useState(false);
  const [selectedRole, setSelectedRole] = React.useState(user?.role);
  const dispatch = useDispatch();

  useMount(() => {
    if (user.id) {
      api.user.storeRegistrationProgress(user.id, 1);
    }
    updateFlowActiveIndex('role');
  });

  const onValuesChange = React.useCallback(
    ({ role }) => setSelectedRole(role),
    [],
  );

  const onSubmit = React.useCallback(
    async (values) => {
      setLoading(true);

      try {
        await dispatch(updateUser(values));
        setLoading(false);
        redirectToStep('pad_details');
      } catch (error) {
        setLoading(false);
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(error),
        });
      }
    },
    [dispatch, redirectToStep],
  );

  return (
    <div className="choose-role">
      <Form
        onFinish={onSubmit}
        onValuesChange={onValuesChange}
        initialValues={{ role: user?.role }}
      >
        <Form.Item name="role" className="mb-0">
          <Radio.Group className="custom-radio-wrapper">
            <Radio
              value={2}
              className="custom-radio-field"
              disabled={user?.role}
            >
              I&apos;m a pilot
            </Radio>
            {selectedRole === 2 && (
              <div className="choose-role_warning">
                <p>
                  Hi there! As of our relaunch, we only support Site Owners via
                  web. As a pilot, you can download the Helipaddy app via the
                  links below!
                </p>
                <div className="warnings">
                  <a
                    href="https://apps.apple.com/gb/app/helipaddy-heli-landing-sites/id894316463"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="App Store"
                      className="app-store"
                      src={AppleStore}
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.helipaddy.android&hl=en_GB&gl=US"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img alt="Google Play" src={GooglePlay} />
                  </a>
                </div>
              </div>
            )}
            <Radio value={3} className="custom-radio-field">
              I&apos;m a helifriendly site owner
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Button.Group>
          <Button
            className="secondary-btn"
            onClick={() => redirectToStep('personal_details')}
          >
            Previous
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            disabled={!selectedRole || selectedRole === 2}
            loading={loading}
          >
            Next
          </Button>
        </Button.Group>
      </Form>
    </div>
  );
}
