import { notification } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { getBanner, setBanner } from '../../store/actions/bannerAction';
import api from '../../utils/appApi';
import getApiErrorMessages from '../../utils/getApiErrorMessages';
import {
  SurveyApproachStep,
  SurveyFacilitiesStep,
  SurveyGeneralStep,
  SurveyGreeting,
  SurveyHazardStep,
  SurveyLandingStep,
  SurveyLoading,
  SurveyMapStep,
  SurveyPadViewStep,
  SurveyStepLayout,
} from './components';
import {
  APPROACH_STEP,
  FACILITIES_STEP,
  GENERAL_STEP,
  GREETING_STEP,
  HAZARDS_STEP,
  LANDING_STEP,
  MAP_STEP,
  PAD_VIEW_STEP,
} from './Survey.context';
import { getSurveyInitialStep } from './utils';

export const SurveyContext = React.createContext({});

export function Survey() {
  const { surveyId } = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [surveyData, setSurveyData] = React.useState({});
  const [activeSurveyStep, setActiveSurveyStep] = React.useState(null);

  const history = useHistory();

  React.useEffect(() => {
    const fetchSurvey = async () => {
      setLoading(true);

      try {
        const response = await api.survey.get(surveyId);

        setSurveyData(response.data);
        setActiveSurveyStep(getSurveyInitialStep(response.data));
        setLoading(false);
      } catch (err) {
        notification.error({
          message: 'Error',
          description: getApiErrorMessages(err),
        });
        setError(true);
      }
    };

    fetchSurvey();
  }, [history, surveyId]);

  const changeSurveyStep = React.useCallback(
    (step) => () => {
      setActiveSurveyStep(step);
    },
    [],
  );

  const updateSurveyDataLocally = React.useCallback((payload) => {
    const { step, values } = payload;

    const changedValuesKeys = Object.keys(values);

    setSurveyData((prev) => {
      const stepToUpdate = prev[step];

      changedValuesKeys.forEach((key) => {
        if (typeof values[key] === 'object' && !Array.isArray(values[key])) {
          stepToUpdate[key] = { ...stepToUpdate[key], ...values[key] };
        } else {
          stepToUpdate[key] = values[key];
        }
      });

      return { ...prev, [step]: stepToUpdate };
    });
  }, []);

  const updateSurveyData = React.useCallback(
    async (payload) => {
      try {
        const response = await api.survey.update(surveyId, payload);
        updateSurveyDataLocally({ step: payload.step, values: response.data });
        setBanner(response.data)(dispatch);
        return Promise.resolve();
      } catch (err) {
        return Promise.reject(err);
      }
    },
    [surveyId, updateSurveyDataLocally, dispatch],
  );

  if (!surveyId || error) return <Redirect to="/my-sites" />;

  if (loading || !activeSurveyStep) return <SurveyLoading />;

  return (
    <SurveyContext.Provider
      value={{
        surveyData,
        changeSurveyStep,
        activeSurveyStep,
        updateSurveyData,
        updateSurveyDataLocally,
      }}
    >
      <div className="survey-layout">
        {activeSurveyStep === GREETING_STEP && <SurveyGreeting />}

        {activeSurveyStep && activeSurveyStep !== GREETING_STEP && (
          <SurveyStepLayout>
            {activeSurveyStep === GENERAL_STEP && <SurveyGeneralStep />}
            {activeSurveyStep === MAP_STEP && <SurveyMapStep />}
            {activeSurveyStep === LANDING_STEP && <SurveyLandingStep />}
            {activeSurveyStep === PAD_VIEW_STEP && <SurveyPadViewStep />}
            {activeSurveyStep === HAZARDS_STEP && <SurveyHazardStep />}
            {activeSurveyStep === APPROACH_STEP && <SurveyApproachStep />}
            {activeSurveyStep === FACILITIES_STEP && <SurveyFacilitiesStep />}
          </SurveyStepLayout>
        )}
      </div>
    </SurveyContext.Provider>
  );
}
