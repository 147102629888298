import api from '../../utils/appApi';
import { BANNER_SHOW,BOOTSTRAP_PUSH } from '../actionTypes';

export const setGlobalRequisites = () => (dispatch) =>
  Promise.allSettled([
    api.warning.getList(),
    api.facility.getList(),
    api.category.getList(),
    api.tag.getList(),
    api.pad.getLandingFees(),
    api.surveyBanner.getBanner(),
  ])
    .then((settledPromises) => {
      const [
        warnings,
        facilities,
        categories,
        tags,
        landingFees,
        banner
      ] = settledPromises;

      const payload = {
        warnings: warnings.value?.data || [],
        facilities: facilities.value?.data || [],
        categories: categories.value?.data || [],
        tags: tags.value?.data || [],
        landingFees: landingFees.value?.data || {},
      };

      dispatch({
        type: BOOTSTRAP_PUSH,
        payload: {
          loaded: true,
          payload,
        },
      });

      dispatch({
        type: BANNER_SHOW,
        payload: banner.value?.data || {},
      });

      return settledPromises;
    })
    .catch((err) => Promise.reject(err));

export const setCountriesData = () => (dispatch) =>
  Promise.allSettled([api.region.getCountries(), api.region.getUSStates()])
    .then((settledPromises) => {
      const [countries, USStates] = settledPromises;

      const payload = {
        countries: countries.value?.data || {},
        USStates: USStates.value?.data || {},
      };

      dispatch({
        type: BOOTSTRAP_PUSH,
        payload: {
          loaded: true,
          payload,
        },
      });

      return settledPromises;
    })
    .catch((err) => Promise.reject(err));
