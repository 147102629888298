import { Col, Row } from 'antd';
import React from 'react';

import { ReactComponent as ApproachIcon } from '../../assets/icons/survey/approach.svg';
import { ReactComponent as FacilitiesIcon } from '../../assets/icons/survey/facilities.svg';
import { ReactComponent as GeneralIcon } from '../../assets/icons/survey/general.svg';
import { ReactComponent as HazardsIcon } from '../../assets/icons/survey/hazards.svg';
import { ReactComponent as LandingIcon } from '../../assets/icons/survey/landing.svg';
import { ReactComponent as MapIcon } from '../../assets/icons/survey/map.svg';
import { ReactComponent as PadViewIcon } from '../../assets/icons/survey/pad-view.svg';

const FeatureList = () => {
    const list = [
        {
            key: 1,
            icon: <GeneralIcon />,
            title: 'General',
        },
        {
            key: 2,
            icon: <MapIcon />,
            title: 'Map',
        },
        {
            key: 3,
            icon: <LandingIcon />,
            title: 'Landing',
        },
        {
            key: 4,
            icon: <PadViewIcon />,
            title: '360 Pad View',
        },
        {
            key: 5,
            icon: <HazardsIcon />,
            title: 'Hazards',
        },
        {
            key: 6,
            icon: <ApproachIcon />,
            title: 'Procedures',
        },
        {
            key: 7,
            icon: <FacilitiesIcon />,
            title: 'Facilities',
        },
    ];

    return (
        <>
            <Row
                justify="space-between"
                gutter={[16, 16]}
                className="feature-list"
            >
                {list.map((item) => (
                    <Col 
                        key={item.key}
                        className={'feature-list__item'}
                        xs={24}
                        sm={6}
                        md={3}
                    >
                        <span className="feature-list__icon"> {item.icon} </span>
                        <span className="feature-list__title">{item.title}</span>
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default FeatureList;