import axiosInstance from '../../utils/apiConfig';
import api from '../../utils/appApi';
import history from '../../utils/history';
import {
  GET_USER,
  LOGIN,
  LOGOUT,
  UPDATE_USER,
  UPDATE_USER_LOCAL,
  USER_PADS_CLEAN,
} from '../actionTypes';

export const setUserToNotAuthorized = () => (dispatch) => {
  localStorage.removeItem('token');
  localStorage.removeItem('isAuthorized');
  localStorage.removeItem('banner');
  localStorage.removeItem('carousel');
  axiosInstance.defaults.headers.Authorization = 'Bearer ';
  dispatch({
    type: LOGOUT,
    payload: {},
  });
  dispatch({ type: USER_PADS_CLEAN });
  history.push('/login');
};

export const setUserToAuthorized = () => (dispatch) => {
  const token = localStorage.getItem('token');
  if (token) axiosInstance.defaults.headers.Authorization = `Bearer ${token}`;
  localStorage.setItem('isAuthorized', 'true');
  dispatch({
    type: LOGIN,
    payload: token ? { token } : {},
  });
};

export const setUserToAuthorizedWithSessionKeep = (data) => (dispatch) => {
  axiosInstance.defaults.headers.Authorization = `Bearer ${data.token}`;
  localStorage.setItem('token', data.token);
  localStorage.setItem('isAuthorized', 'true');
  dispatch({
    type: LOGIN,
    payload: data,
  });
};

export const login = (data) => (dispatch) =>
  api.user
    .login(data)
    .then((res) => {
      localStorage.setItem('isAuthorized', 'true');
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('banner', 'true');
      localStorage.setItem('carousel', 'true');
      axiosInstance.defaults.headers.Authorization = `Bearer ${res.data.token}`;
      dispatch({
        type: LOGIN,
        payload: res.data,
      });

      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const loginViaGoogle = (data) => (dispatch) =>
  api.user
    .loginViaDriver(data)
    .then((res) => {
      localStorage.setItem('isAuthorized', 'true');
      localStorage.setItem('token', res.data.token);
      axiosInstance.defaults.headers.Authorization = `Bearer ${res.data.token}`;

      dispatch({
        type: LOGIN,
        payload: res.data,
      });

      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const logout = () => (dispatch) =>
  api.user
    .logout()
    .then((res) => {
      setUserToNotAuthorized()(dispatch);
      localStorage.removeItem('banner');
      localStorage.removeItem('carousel');
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const logoutWithoutRedirect = () => (dispatch) =>
  api.user
    .logout()
    .then(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('isAuthorized');
      axiosInstance.defaults.headers.Authorization = 'Bearer ';
      dispatch({
        type: LOGOUT,
        payload: {},
      });
      dispatch({ type: USER_PADS_CLEAN });
    })
    .catch((err) => console.error(err));

export const getUser = () => (dispatch) =>
  api.user
    .get()
    .then((res) => {
      dispatch({
        type: GET_USER,
        payload: res.data,
      });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const updateUser = (data) => (dispatch) =>
  api.user
    .update(data)
    .then((res) => {
      dispatch({
        type: UPDATE_USER,
        payload: res.data,
      });
      return Promise.resolve(res);
    })
    .catch((err) => Promise.reject(err));

export const updateLocalUser = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      dispatch({ type: UPDATE_USER_LOCAL, payload: data });
      return resolve();
    } catch (err) {
      return reject(err);
    }
  });
