import { Form, Input, Modal } from 'antd';
import React from 'react';

import useStepUpdater from '../../hooks/useStepUpdater';
import { SurveyContext } from '../../Survey';
import {
  APPROACH_STEP,
  FACILITIES_STEP,
  HAZARDS_STEP,
} from '../../Survey.context';
import { SurveyStepActions } from '../SurveyStepActions';
import { validateFormValues } from './SurveyApproachStep.utils';

export function SurveyApproachStep() {
  const { surveyData, changeSurveyStep } = React.useContext(SurveyContext);

  const [
    confirmationModalVisible,
    setConfirmationModalVisible,
  ] = React.useState(false);
  const [
    nextStepKeyAfterConfirmation,
    setNextStepKeyAfterConfirmation,
  ] = React.useState();

  const [onStepValuesChange] = useStepUpdater(APPROACH_STEP);

  const onValuesChange = React.useCallback(
    (changedValues, formValues) => {
      const approach_is_completed = validateFormValues(formValues);

      onStepValuesChange({
        ...changedValues,
        approach_is_completed,
      });
    },
    [onStepValuesChange],
  );

  const handleLeaveStep = (nextStepKey) => () => {
    const {
      approach_procedures_comment,
      approach_reference_points_comment,
      is_completed,
    } = surveyData.approach;

    if (
      !approach_procedures_comment &&
      !approach_reference_points_comment &&
      !is_completed
    ) {
      setConfirmationModalVisible(true);
      setNextStepKeyAfterConfirmation(nextStepKey);
    } else {
      changeSurveyStep(nextStepKey)();
    }
  };

  const onClickModalConfirm = () => {
    onStepValuesChange({ approach_is_completed: true }, { immediately: true });

    if (nextStepKeyAfterConfirmation) {
      changeSurveyStep(nextStepKeyAfterConfirmation)();
    }
  };

  const onClickModalCancel = () => {
    setConfirmationModalVisible(false);
  };

  return (
    <>
      <Form
        layout="vertical"
        onValuesChange={onValuesChange}
        initialValues={surveyData.approach}
      >
        <Form.Item
          name="approach_procedures_comment"
          label="Approach"
          rules={[
            {
              min: 3,
              max: 200,
              message: 'Must be between 3 and 200 characters',
            },
          ]}
        >
          <Input.TextArea
            className="survey-textarea max-100 scrollbar-thin"
            autoSize
            showCount
            maxLength={200}
            placeholder="Please provide a description of any known approach procedures, areas to not overfly or local noise sensitive areas"
          />
        </Form.Item>
        <Form.Item
          name="approach_departures_comment"
          label="Departure"
          rules={[
            {
              min: 3,
              max: 200,
              message: 'Must be between 3 and 200 characters',
            },
          ]}
        >
          <Input.TextArea
            className="survey-textarea max-100 scrollbar-thin"
            autoSize
            showCount
            maxLength={200}
            placeholder="Please provide a description of any known departure procedures"
          />
        </Form.Item>
        <Form.Item
          name="approach_reference_points_comment"
          label="Nearby Visual Reference Points"
          rules={[
            {
              min: 3,
              max: 200,
              message: 'Must be between 3 and 200 characters',
            },
          ]}
        >
          <Input.TextArea
            className="survey-textarea max-100 scrollbar-thin"
            autoSize
            showCount
            maxLength={200}
            placeholder="Please provide details and rough bearing/distance of any landmarks or obvious nearby visual cues that could help the pilot locate the site. Road junctions, rivers, lakes and tall buildings etc"
          />
        </Form.Item>
        <SurveyStepActions
          backClick={changeSurveyStep(HAZARDS_STEP)}
          skipClick={changeSurveyStep(FACILITIES_STEP)}
          nextClick={handleLeaveStep(FACILITIES_STEP)}
        />
      </Form>
      <Modal
        closeIcon={false}
        maskClosable={false}
        centered
        visible={confirmationModalVisible}
        onOk={onClickModalConfirm}
        onCancel={onClickModalCancel}
        className="survey-confirmation-modal default-footer"
        cancelText="Cancel"
        okText="Continue"
        okButtonProps={{
          className: 'secondary-btn',
        }}
        cancelButtonProps={{
          className: 'outline-btn survey-outline',
        }}
      >
        <div className="survey-confirmation-content">
          <h3>
            Please confirm that there are no relevant Approach Departure Procedures or
            Visual Reference Points
          </h3>
        </div>
      </Modal>
    </>
  );
}
