/* eslint-disable jsx-a11y/label-has-associated-control */
import { Col, Dropdown, Input, Menu, Row, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useMount, useUnmount } from 'react-use';

import { ReactComponent as InfoCircleInfo } from '../../../../assets/icons/info-circle.svg';
import PhoneInput from '../../../../components/phoneInput';
import useStepUpdater from '../../hooks/useStepUpdater';
import { SurveyContext } from '../../Survey';
import { GENERAL_STEP } from '../../Survey.context';
import {
  contactTypes,
  getUniqueContacts,
  isValidContact,
} from './SurveyGeneralStep.utils';

export default function GeneralStepContant({
  __unsafe_contentAlongsideEmail = null,
}) {
  const { user } = useSelector((store) => ({
    user: store.user.payload,
  }));
  const { surveyData } = React.useContext(SurveyContext);

  const [selectedContact, setSelectedContact] = React.useState({
    id: null,
    email: '',
    name: '',
    phone: '',
    contact_type_id: null,
    is_primary: true,
  });
  const [contacts, setContacts] = React.useState([]);

  const [onStepValuesChange] = useStepUpdater(GENERAL_STEP);

  useMount(() => {
    if (surveyData?.general?.contacts) {
      const uniqueContacts = getUniqueContacts(surveyData.general.contacts);
      const defaultSelectedContact = uniqueContacts.find((c) => c.is_primary);

      setContacts(uniqueContacts);

      if (defaultSelectedContact) {
        const newSelectedContact = surveyData?.general?.contacts.find(
          (contact) => contact.id === defaultSelectedContact.id,
        );

        setSelectedContact(newSelectedContact);
      }
    }
  });

  const updateSelectedContact = React.useCallback(
    (fieldName) => (value) => {
      setSelectedContact((prev) => ({
        ...prev,
        [fieldName]: value.target.value,
      }));
    },
    [],
  );

  useUnmount(() => {
    if (isValidContact(selectedContact)) {
      onStepValuesChange(
        {
          contact: selectedContact,
          contacts: surveyData.general.contacts.map((contact) => {
            if (contact.id === selectedContact.id) {
              return { ...contact, ...selectedContact, is_primary: true };
            }
            return { ...contact, is_primary: false };
          }),
        },
        { immediately: true },
      );
    }
  });

  return (
    <>
      <Row gutter={32}>
        <Col 
          md={12}
          sm={24}
          xs={24}
        >
          <div className="ant-row ant-form-item ant-form-item-with-help mb-3">
            <div className="ant-col ant-form-item-label">
              <label
                htmlFor="contact_email"
                className="ant-form-item-required"
                title="Email Address"
              >
                Email Address
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content">
                  <Dropdown
                    trigger={['click']}
                    overlay={
                      <Menu>
                        {contacts.map((contact) => (
                          <Menu.Item
                            key={contact.id}
                            style={{
                              backgroundColor:
                                contact.id === selectedContact.id
                                  ? '#f5dfe4'
                                  : '',
                            }}
                            onClick={() => {
                              setSelectedContact(contact);
                            }}
                          >
                            {`${contact.email} (${
                              contactTypes[contact.contact_type_id]
                            })`}
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                  >
                    <Input
                      placeholder="email@example"
                      value={selectedContact.email}
                      onChange={updateSelectedContact('email')}
                    />
                  </Dropdown>
                </div>
              </div>
              <div className="ant-form-item-explain">
                <div role="alert">Select from list or create a new one</div>
              </div>
            </div>
          </div>
        </Col>
        <Col 
          md={12}
          sm={24}
          xs={24}
        >
          {__unsafe_contentAlongsideEmail}
        </Col>
      </Row>
      <Row gutter={[32]}>
        <Col 
          md={12}
          sm={24}
          xs={24}
        >
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label
                htmlFor="contact_phone"
                className="ant-form-item-required"
                title="Contact Name"
              >
                Contact Name
                <Tooltip 
                  title="Who is the person with the authority to grant permission to land?" 
                  className='tooltip-responsive'
                >
                  <InfoCircleInfo className="ml-2" style={{ fontSize: '12px'} }/>
                </Tooltip>
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content">
                  <Input
                    placeholder="John Doe"
                    value={selectedContact.name}
                    onChange={updateSelectedContact('name')}
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col 
          md={12}
          sm={24}
          xs={24}
        >
          <div className="ant-row ant-form-item">
            <div className="ant-col ant-form-item-label">
              <label
                htmlFor="contact_phone"
                className="ant-form-item-required"
                title="Phone Number"
              >
                Phone Number
              </label>
            </div>
            <div className="ant-col ant-form-item-control">
              <div className="ant-form-item-control-input">
                <div className="ant-form-item-control-input-content">
                  <PhoneInput
                    defaultCountry={user.location?.country_code}
                    value={selectedContact.phone}
                    onChange={updateSelectedContact('phone')}
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

GeneralStepContant.propTypes = {
  __unsafe_contentAlongsideEmail: PropTypes.node,
};
