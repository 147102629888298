import { LOADING_BAR_DISABLE, LOADING_BAR_ENABLE } from '../actionTypes';

const initialState = {
  enabled: false,
};

const loadingBar = (state = initialState, action = {}) => {
  switch (action.type) {
    case LOADING_BAR_ENABLE:
      return {
        ...state,
        enabled: true,
      };
    case LOADING_BAR_DISABLE:
      return {
        ...state,
        enabled: false,
      };
    default:
      return state;
  }
};

export default loadingBar;
