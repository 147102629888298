import Spin from '../../uiKitComponents/spin';

const FullPageLoading = () => (
  <div
    style={{ height: '100vh' }}
    className="loading d-flex justify-content-center align-items-center px-3"
  >
    <Spin size="large" tip="Loading..." />
  </div>
);

export default FullPageLoading;
