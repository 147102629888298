import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import PropTypes from 'prop-types';

import { SurveyGreetingHeader } from '../SurveyGreeting';

export function SurveyLoading(props) {
  const { padName } = props;

  return (
    <div className="survey-layout">
      <div className="survey-loading">
        <SurveyGreetingHeader padName={padName} />
        <div className="survey-loading__spin">
          <span className="survey-loading__text">Loading pad data...</span>
          <Spin
            spinning
            indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />}
          />
        </div>
      </div>
    </div>
  );
}

SurveyLoading.propTypes = {
  padName: PropTypes.string,
};
