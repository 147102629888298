import PropTypes from 'prop-types';
import React from 'react';

import East from '../../assets/icons/cardinal/east.svg';
import North from '../../assets/icons/cardinal/north.svg';
import South from '../../assets/icons/cardinal/south.svg';
import West from '../../assets/icons/cardinal/west.svg';

const CardinalDirectionIcon = (props) => {

    const {
        direction,
        width,
    } = props;

    const dir = {
        north: {
            icon: North,
            alt: 'North',
        },
        south: {
            icon: South,
            alt: 'South',
        },
        east: {
            icon: East,
            alt: 'East',
        },
        west: {
            icon: West,
            alt: 'West',
        },
    }

    return (
        <div className='direction'>
            <img
                src={dir[direction].icon}
                alt={dir[direction].alt}
                width={width}
                height={"auto"}
            />
        </div>
    );
};

CardinalDirectionIcon.defaultProps = {
    direction: 'north',
    width: 45,
};

CardinalDirectionIcon.propTypes = {
    direction: PropTypes.oneOf(['north', 'south', 'east', 'west']),
    width: PropTypes.number,
};

export default CardinalDirectionIcon;
