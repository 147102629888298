import PropTypes from 'prop-types';

import condStrings from '../../utils/condStrings';

const LoadingBar = (props) => {
  const { className, ...rest } = props;
  return (
    <div {...rest} className={condStrings('loadingBar-root', className)}>
      <div className="loadingBar-root_line" />
      <div className="loadingBar-root_inc" />
      <div className="loadingBar-root_dec" />
    </div>
  );
};

LoadingBar.defaultProps = {
  className: '',
};

LoadingBar.propTypes = {
  className: PropTypes.string,
};

export default LoadingBar;
