import {
  REMOVE_SINGLE_PAD,
  UPDATE_SINGLE_PAD,
  USER_PADS_CLEAN,
  USER_PADS_GET,
  USER_PADS_PUSH,
  USER_PADS_PUSH_LIST,
} from '../actionTypes';

const initialState = {
  payload: [],
};

const padList = (state = initialState, action = {}) => {
  switch (action.type) {
    case USER_PADS_GET:
      return {
        ...state,
        payload: action.payload,
      };
    case USER_PADS_PUSH:
      return {
        ...state,
        payload: [action.payload, ...state.payload],
      };
      case USER_PADS_PUSH_LIST:
      return {
        ...state,
        payload: [...state.payload, ...action.payload],
      };
    case UPDATE_SINGLE_PAD: {
      const updatePadsList = state.payload.map((pad) => {
        if (pad.id === action.payload.id) {
          return { ...pad, ...action.payload };
        }
        return pad;
      });

      return { ...state, payload: updatePadsList };
    }
    case REMOVE_SINGLE_PAD:
      return {
        ...state,
        payload: state.payload.filter(({ id }) => id !== action.payload.id),
      };
    case USER_PADS_CLEAN:
      return initialState;
    default:
      return state;
  }
};

export default padList;
