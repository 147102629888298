import api from '../../utils/appApi';
import {
  REMOVE_SINGLE_PAD,
  UPDATE_SINGLE_PAD,
  USER_PADS_CLEAN,
  USER_PADS_GET,
  USER_PADS_PUSH,
  USER_PADS_PUSH_LIST
} from '../actionTypes';

export const getUserPads = (page) => (dispatch) =>
  api.user
    .getPads(page)
    .then((res) => {
      if (page === 1) {
        dispatch({ type: USER_PADS_GET, payload: res.data.data });
      } else if (page > 1) {
        dispatch({ type: USER_PADS_PUSH_LIST, payload: res.data.data });

      }
      return Promise.resolve(res.data);
    })
    .catch((err) => Promise.reject(err));

export const pushPadToUserPads = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      dispatch({ type: USER_PADS_PUSH, payload: data });
      return resolve();
    } catch (err) {
      return reject(err);
    }
  });

export const removeSinglePad = (padId) => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      dispatch({ type: REMOVE_SINGLE_PAD, payload: { id: padId } });
      return resolve();
    } catch (err) {
      return reject(err);
    }
  });

export const updateSinglePad = (data) => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      dispatch({ type: UPDATE_SINGLE_PAD, payload: data });
      return resolve();
    } catch (err) {
      return reject(err);
    }
  });

export const cleanUserPads = () => (dispatch) =>
  new Promise((resolve, reject) => {
    try {
      dispatch({ type: USER_PADS_CLEAN });
      return resolve();
    } catch (err) {
      return reject(err);
    }
  });
