import { Button, Checkbox, Form, Modal, notification } from 'antd';
// import { CloseOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import api from '../../utils/appApi';
import PropTypes from 'prop-types';
import getApiErrorMessages from '../../utils/getApiErrorMessages';
import { updatePadElementList } from '../../store/actions/padLandingsActions';
import { useDispatch, useSelector } from 'react-redux';

const ApproveButton = (props) => {
  const { landingId } = props;
  
  const dispatch = useDispatch();
  const surveyStatus = useSelector((store) => store.padLandings?.payload.surveyStatus);

  // const [isVisible, setIsVisible] = useState(false);
  const [loadingActionType, setLoadingActionType] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [form] = Form.useForm();

  const handleAction = (type) => () => {
    const payload = {
      landing: landingId,
      type,
    };

    // if (type === 'reject') {
    //   api.landings
    //     .landingsAprove(payload)
    //     .then((res) => {
    //       updatePadElementList(res.data)(dispatch);
    //     })
    //     .catch((err) => {
    //       notification.error({
    //         message: 'Error',
    //         description:
    //           getApiErrorMessages(err) ||
    //           'Something went wrong. Please try again later.',
    //       });
    //     })
    //     .finally(() => {
    //       // setIsVisible(false);
    //       setLoadingActionType(null);
    //     });
    // }

    // if (type === 'approve') {
    form.validateFields().then((value) => {
      setLoadingActionType(type);

      payload.data = value;

      api.landings
        .landingsAprove(payload)
        .then((res) => {
          updatePadElementList(res.data)(dispatch);
        })
        .catch((err) => {
          notification.error({
            message: 'Error',
            description:
              getApiErrorMessages(err) ||
              'Something went wrong. Please try again later.',
          });
        })
        .finally(() => {
          // setIsVisible(false);
          setLoadingActionType(null);
        });
    });
    // }
  };

  // const handleCancel = () => {
  //   setIsVisible(false);
  // };

  const displayModal = () => {
    setIsModalVisible(true);
    // setIsVisible(false);
  };

  const onCancelModal = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  // const popConfirmContent = (
  //   <div>
  //     <p>Do you want to approve or reject this landing?</p>
  //     <div className="d-flex modal-action-btns">
  //       <Button
  //         onClick={handleCancel}
  //         className="secondary-btn"
  //         icon={<CloseOutlined />}
  //       >
  //         Cancel
  //       </Button>
  //       <Button
  //         type="secondary"
  //         onClick={handleAction('reject')}
  //         className="reject-btn"
  //         loading={loadingActionType === 'reject'}
  //       >
  //         Reject
  //       </Button>
  //       <Button type="primary" onClick={displayModal} className="approve-btn">
  //         Approve
  //       </Button>
  //     </div>
  //   </div>
  // );

  return (
    <>
      {/* <Popconfirm
        title={popConfirmContent}
        visible={isVisible}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onCancel={() => setIsVisible(false)}
        onVisibleChange={(visible) => setIsVisible(visible)}
      > */}
      <Button type="primary" className="approve-btn" onClick={displayModal}>
        Approve
      </Button>
      {/* </Popconfirm> */}

      <Modal
        closeIcon={false}
        maskClosable={false}
        centered
        visible={isModalVisible}
        onOk={handleAction('approve')}
        onCancel={onCancelModal}
        className="modal-box-approve"
        cancelText="Cancel"
        okText="Approve"
        okButtonProps={{
          loading: loadingActionType === 'approve',
        }}
      >
        <div>
          <h3>Please check the following conditions before approving: </h3>
          <Form
            layout="vertical"
            name="approveForm"
            form={form}
            initialValues={{ conditions: [2] }}
          >
            <Form.Item
              name="conditions"
              rules={[
                {
                  required: true,
                  message: 'Please select at least one option!',
                },
              ]}
              className="m-0"
            >
              <Checkbox.Group className="m-0">
                <Checkbox value={0} disabled={!(surveyStatus?.surveyStatus === 3 || surveyStatus?.completedSurevyStep === 7)}>
                  A. Pilot must download 360 survey
                </Checkbox>
                <Checkbox value={1}>B. Pilot must pay a landing fee</Checkbox>
                <Checkbox value={2} disabled>
                  C. Pilot must observe Landing instructions in Helipaddy
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Form>
          <h4 className="mt-3">
            If you wish to update the landing instructions, please do so from
            your dashboard before approving.
          </h4>
          <h4>
            If you do not accept the flight click Cancel below. Helipaddy pilots
            are instructed that they may not land without receiving an explicit
            Approval (&ldquo;Prior Permission Required&ldquo;).
          </h4>
          <h4>
            If you approve the landing, we will send this message and copy you.
          </h4>
        </div>
      </Modal>
    </>
  );
};

ApproveButton.propTypes = {
  landingId: PropTypes.number.isRequired,
};

export default ApproveButton;
