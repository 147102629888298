import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMount } from 'react-use';

import { setUserToAuthorized } from '../../store/actions/userAction';
import FullPageLoading from '../FullPageLoading';

export default function ExternalAuthorization() {
  const history = useHistory();
  const dispatch = useDispatch();

  const authorization = () => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    if (token) {
      localStorage.setItem('token', token);
      dispatch(setUserToAuthorized());
    } else {
      history.push('/login');
    }
  };

  useMount(authorization);

  return <FullPageLoading />;
}
