import api from '../../utils/appApi';
import {
  SURVEY_CLEAN,
  SURVEY_COUNTER_NEW,
  SURVEY_SET,
  SURVEY_SUBMIT_STEP,
  SURVEY_UPDATE,
  SURVEY_UPDATE_STEP,
} from '../actionTypes';

export const surveySet = (payload) => (dispatch) => {
  dispatch({ type: SURVEY_SET, payload });
};

export const surveySubmitStep = (key, value, padId) => async (dispatch) => {
  try {
    let completed = true;

    if (Object.hasOwn(value, 'completed')) {
      completed = value.completed;
    }

    await api.survey.update(padId, key, { values: value, completed });
    dispatch({ type: SURVEY_SUBMIT_STEP, payload: { step: key, completed } });
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
};

export const surveyUpdateStep = (step, values) => async (dispatch) => {
  dispatch({ type: SURVEY_UPDATE_STEP, payload: { step, values } });
};

export const surveyUpdate = (key, value) => (dispatch) =>
  dispatch({ type: SURVEY_UPDATE, payload: { key, value } });

export const surveyClean = () => (dispatch) => {
  dispatch({ type: SURVEY_CLEAN });
};

export const surveyCounterNew = (counter) => (dispatch) => 
  new Promise((resolve, reject) => {
    try {
      dispatch({ type: SURVEY_COUNTER_NEW, payload: { counter } });
      return resolve();
    } catch (err) {
      return reject(err);
    }
  });