export const selectCategoryOptions = (store) =>
  store.bootstrap.payload?.categories?.map((itm) => ({
    value: itm.id,
    label: itm.display_name,
  }));

export const selectTagOptions = (store) =>
  store.bootstrap.payload?.tags?.map((itm) => ({
    value: itm.name,
    label: itm.name,
  }));

export const selectLandingFeeOptions = (store) =>
  store.bootstrap.payload?.landingFees
    ? Object.entries(store.bootstrap.payload?.landingFees).map(
        ([value, label]) => ({
          value,
          label,
        }),
      )
    : [];

export const selectWarningOptions = (store) =>
  store.bootstrap.payload?.warnings?.map((itm) => ({
    value: itm.id,
    label: itm.display_name,
  }));

export const selectFacilityOptions = (store) =>
  store.bootstrap.payload?.facilities?.map((itm) => ({
    value: itm.id,
    label: itm.display_name,
  }));

export const selectCountriesOptions = (store) => {
  if (store.bootstrap?.payload?.countries) {
    return Object.entries(store.bootstrap?.payload?.countries)
      .map(([k, v]) => ({
        value: k,
        label: v,
      }))
      .sort((a) => (a.value === 'GB' ? -1 : 1));
  }
  return [];
};

export const selectUSStatesOptions = (store) => {
  if (store.bootstrap?.payload?.USStates) {
    return Object.entries(store.bootstrap?.payload?.USStates).map(([k, v]) => ({
      value: k,
      label: v,
    }));
  }
  return [];
};
