import { LoadingOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';

import Check from '../../assets/icons/check-circle-white.svg';
import Close from '../../assets/icons/close.svg';
import useQuery from '../../hooks/useQuery';
import { logoutWithoutRedirect } from '../../store/actions/userAction';
import Spin from '../../uiKitComponents/spin';
import api from '../../utils/appApi';

const antIcon = (
  <LoadingOutlined style={{ fontSize: 36, color: '#fff' }} spin />
);

const ActivationEmailPage = () => {
  const queryParams = useQuery();
  const dispatch = useDispatch();
  const queryToken = queryParams.get('token');
  const [activationLoading, setActivationLoading] = useState(false);
  const [verificationError, setVerificationError] = useState(false);
  const { isAuthorized } = useSelector((store) => ({
    isAuthorized: store?.user?.isAuthorized,
  }));

  const accountActivateHandler = () => {
    setActivationLoading(true);
    api.user
      .activateEmail({ token: queryToken })
      .then(() => {
        setActivationLoading(false);
        setVerificationError(false);
      })
      .catch(() => {
        setActivationLoading(false);
        setVerificationError(true);
      });
  };

  useMount(() => {
    if (isAuthorized) {
      logoutWithoutRedirect()(dispatch);
    }
  });

  useEffect(() => {
    if (queryToken) {
      accountActivateHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryToken]);

  const content = useCallback(() => {
    if (queryToken && !verificationError) {
      return (
        <div className="activation-page_content">
          <div className="content-image">
            <img src={Check} alt="Success" />
          </div>
          <h1 className="v1-h1 form-title">Congratulations</h1>
          <p className="content-descrp">
            Thank you - your email address has been verified. Please continue
            the registration process in the mobile app
          </p>
        </div>
      );
    }

    return (
      <div className="activation-page_content">
        <div className="content-image error">
          <img src={Close} alt="Error" />
        </div>
        <h1 className="v1-h1 form-title">
          No user found(invalid activation link)
        </h1>
        <p className="content-descrp">
          The activation link you followed is no longer valid. This is probably
          because link expiried.
        </p>
      </div>
    );
  }, [queryToken, verificationError]);

  return (
    <div className="activation-page">
      {activationLoading ? (
        <div className="activation-page_content">
          <div className="content-image">
            <Spin indicator={antIcon} />
          </div>
          <h1 className="v1-h1 form-title">Account activation...</h1>
        </div>
      ) : (
        content()
      )}
    </div>
  );
};

export default ActivationEmailPage;
