import { LockOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CountrySelect from '../../components/CountrySelect';
import PhoneInput from '../../components/phoneInput';
import useApiErrorsWithAntd from '../../hooks/useApiErrorsWithAntd';
import { setUserToAuthorizedWithSessionKeep } from '../../store/actions/userAction';
import Alert from '../../uiKitComponents/alert';
import Button from '../../uiKitComponents/button';
import Col from '../../uiKitComponents/col';
import Form from '../../uiKitComponents/form';
import Input from '../../uiKitComponents/input';
import notification from '../../uiKitComponents/notification';
import Row from '../../uiKitComponents/row';
import api from '../../utils/appApi';
import getApiErrorMessages from '../../utils/getApiErrorMessages';
import phoneWithPlus from '../../utils/phoneWithPlus';

const RegistrationPage = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [userData, setUserData] = useState();
  const [selectedCountry, setSelectedCountry] = useState('gb');
  const [form] = Form.useForm();
  const { setApiErrorsToAntdForm } = useApiErrorsWithAntd(form);
  const [userNotifyLoading, setUserNotifyLoading] = useState(false);
  const globalErrors = form.getFieldError('global');
  const { isAuthorized, emailVerified, completedAt, userEmail } = useSelector(
    (store) => ({
      isAuthorized: store.user.isAuthorized,
      emailVerified: store.user?.payload?.email_verified_at,
      completedAt: store.user?.payload?.completed_at,
      userEmail: store.user?.payload?.email,
    }),
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const reRef = useRef();

  useEffect(() => {
    if (userEmail) {
      setUserData({ email: userEmail });
    }

    if (isAuthorized) {
      setSuccess(true);
    }

    if (emailVerified) {
      history.push('/');
    }

    if (completedAt) {
      history.push('/my-sites');
    }
  }, [completedAt, emailVerified, history, isAuthorized, userEmail]);

  const onSubmit = useCallback(
    (values) => {
      setLoading(true);

      api.user
        .register({ ...values, phone: phoneWithPlus(values.phone) })
        .then((res) => {
          setSuccess(true);
          setUserData(res.data);
          setUserToAuthorizedWithSessionKeep(res.data)(dispatch);
          api.user.storeRegistrationProgress(res.data.user.id, 0);
        })
        .catch((err) => setApiErrorsToAntdForm(err))
        .finally(() => {
          setLoading(false);
          reRef.current.reset();
        });
    },
    [dispatch, setApiErrorsToAntdForm],
  );

  const notifyUserHandler = () => {
    setUserNotifyLoading(true);

    api.user
      .notify()
      .then(() => {
        setUserNotifyLoading(false);

        notification.success({
          message: 'Success',
          description: 'Email was sent successfully!',
        });
      })
      .catch((err) => {
        setUserNotifyLoading(false);

        notification.error({
          message: 'Error',
          description: getApiErrorMessages(err),
        });
      });
  };

  const onReChange = (token) => {
    if (token) {
      form.setFields([{ name: 'g-recaptcha-response', value: token }]);
    }
  };

  if (success)
    return (
      <div className="registration-page success">
        <div className="registration-page-content d-flex flex-column align-items-center">
          <i className="custom-icon-white-logo-small" />
          <p style={{ marginTop: 55, marginBottom: 55 }}>SUCCESS!</p>
          <p>We have sent you a verification email.</p>
          <p style={{ marginBottom: 40 }}>
            Please check your email and verify your emall address
          </p>
          <i
            style={{ marginBottom: 20 }}
            className="custom-icon-check-circle-white"
          />
          <p style={{ marginBottom: 60 }}>{userData?.email}</p>
          <p style={{ marginBottom: 25 }}>
            Didn&apos;t get any email message from us?
          </p>
          <Button
            type="primary"
            loading={userNotifyLoading}
            onClick={notifyUserHandler}
          >
            Re-Send Email
          </Button>
          <Button
            type="link"
            href="/"
            style={{
              maxWidth: 80,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#ffffff',
            }}
          >
            Back
          </Button>
        </div>
      </div>
    );

  return (
    <div className="registration-page">
      <div className="registration-page-content">
        <Form form={form} onFinish={onSubmit}>
          <Row gutter="12">
            <Col span="12">
              <Form.Item
                name="first_name"
                rules={[
                  {
                    required: true,
                    message: 'Name is required',
                  },
                  { min: 3, message: 'Min 3 characters' },
                ]}
              >
                <Input
                  size="large"
                  placeholder="First name"
                  autoComplete="given-name"
                />
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: 'Surname is required',
                  },
                  { min: 3, message: 'Min 3 characters' },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Last name"
                  autoComplete="family-name"
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Email is required' },
              { type: 'email', message: 'Is not a valid email' },
            ]}
          >
            <Input
              size="large"
              prefix={<UserOutlined />}
              placeholder="Email"
              autoComplete="email"
            />
          </Form.Item>
          <CountrySelect onChange={setSelectedCountry} />
          <Form.Item
            name="phone"
            fieldKey="phone"
            rules={[{ required: true, message: 'Phone is required' }]}
          >
            <PhoneInput
              placeholder="Phone number"
              country={selectedCountry}
              icon={<PhoneOutlined />}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Password is required' },
              {
                min: 8,
                message: 'Min 8 characters',
              },
            ]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder="Password"
              autoComplete="new-password"
            />
          </Form.Item>

          <Form.Item
            name="password_confirmation"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'This field is required',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!',
                    ),
                  );
                },
              }),
            ]}
          >
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder="Confirm Password"
              autoComplete="new-password"
            />
          </Form.Item>

          <Form.Item hidden name="global">
            <Input />
          </Form.Item>
          <Form.Item
            name="g-recaptcha-response"
            rules={[
              {
                required: true,
                message: 'Please check the box above to proceed',
              },
            ]}
          >
            <ReCAPTCHA
              ref={reRef}
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
              onChange={onReChange}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={globalErrors.length}
              style={{ minWidth: '100%' }}
            >
              Register
            </Button>
          </Form.Item>
          {!!globalErrors.length && (
            <Alert
              message="Error"
              description={globalErrors}
              type="error"
              showIcon
            />
          )}
        </Form>
      </div>
    </div>
  );
};

export default RegistrationPage;
