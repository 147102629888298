import 'react-phone-input-2/lib/style.css';
import './phoneInput.scss';

import PropTypes from 'prop-types';
import PhoneInput2 from 'react-phone-input-2';

import condStrings from '../../utils/condStrings';

const inputSizes = {
  small: 'ant-input-sm',
  middle: '',
  large: 'ant-input-lg',
};

const PhoneInput = (props) => {
  const {
    containerClass,
    inputClass,
    name,
    onChange,
    size,
    icon,
    country,
    ...rest
  } = props;

  const handleOnChange = (value) => {
    return onChange({
      target: {
        name,
        value,
      },
    });
  };

  return (
    <div className="phone-input_wrapper">
      <PhoneInput2
        {...rest}
        containerClass={condStrings('phone-input-container', containerClass)}
        inputClass={condStrings('ant-input', inputClass, inputSizes[size])}
        country={country.toLowerCase()}
        onChange={handleOnChange}
        specialLabel={null}
        enableAreaCodes={false}
        countryCodeEditable
        enableSearch
        searchPlaceholder="Search..."
        disableSearchIcon
      />
    </div>
  );
};

PhoneInput.defaultProps = {
  containerClass: '',
  inputClass: '',
  name: '',
  onChange: () => null,
  size: 'middle',
  icon: null,
  country: 'gb',
};

PhoneInput.propTypes = {
  containerClass: PropTypes.string,
  inputClass: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['small', 'middle', 'large']),
  icon: PropTypes.object,
  country: PropTypes.string,
};

export default PhoneInput;
